import { EnjicalcWorkspace, TSymbolId, EnjcWorkspaceDTO } from '../../enjc-workspace';
import { WorkspaceEditHistoryEntry } from '../model';
import { mkWorkspaceEditHistoryEntryDeprecated, changeMoveSymbolBeforeAfter } from '../utils';

export const editMoveSymbolBeforeAfter = (
  workspace: EnjcWorkspaceDTO,
  symbolToMoveId: TSymbolId,
  symbolBeforeAfterId: TSymbolId,
  addAfter: boolean,
): WorkspaceEditHistoryEntry => {
  const sectionDeltas = changeMoveSymbolBeforeAfter(workspace, symbolToMoveId, symbolBeforeAfterId, addAfter);
  return mkWorkspaceEditHistoryEntryDeprecated('editMoveSymbolBeforeAfter', {
    section: sectionDeltas.length > 0 ? sectionDeltas : undefined,
  });
};

import { IEnjcWorkspaceLocalHistory } from '../interfaces';
import { TSymbolId } from '../../../generated/graphql/types';
import { EnjcWorkspaceSectionDelta, EnjcWorkspaceSymbolDelta, ItemCreateEntry } from '../model';
import { findWorkspaceItemContainer } from '../../enjc-workspace';
import { mkAtomDelta } from '../../enjc-delta';
import { mkLiteralNumber, mkLiteralVoidNull, UEnjcValueLiteral } from '../../enjc-literal';
import { hCreateSymbol } from './hCreateSymbol';

export const hCreateSymbolBeforeAfter = (
  wlh: IEnjcWorkspaceLocalHistory,
  symbolBeforeAfterId: TSymbolId,
  addAfter: boolean,
): ItemCreateEntry => {
  const containerDstInfo = findWorkspaceItemContainer(wlh.currentWorkspace, symbolBeforeAfterId);
  if (!containerDstInfo) {
    // TODO: report error
    throw new Error('Dst container not found');
  }

  const hcr = hCreateSymbol(wlh);

  const symbolDelta: EnjcWorkspaceSymbolDelta = {
    id: hcr.itemId,
    valueTree: {
      nodes: [
        {
          key: 'ab',
          // This literal works as a more recognizable (compared to square of
          // null literal) placeholder for initial empty draft
          literal: mkAtomDelta<UEnjcValueLiteral>(mkLiteralVoidNull(), mkLiteralNumber(0)),
        },
      ],
    },
  };

  const sectionDelta: EnjcWorkspaceSectionDelta = {
    id: containerDstInfo.container.id,
    items: [{ slicePosition: containerDstInfo.index + (addAfter ? 1 : 0), sliceAfter: [hcr.itemId] }],
  };

  return {
    ...hcr,
    historyEntry: {
      ...hcr.historyEntry,
      delta: {
        ...hcr.historyEntry.delta,
        section: [...(hcr.historyEntry.delta.section ?? []), sectionDelta],
        symbol: [...(hcr.historyEntry.delta.symbol ?? []), symbolDelta],
      },
    },
  };
};

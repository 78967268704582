import { EnjicalcWorkspace, TSymbolId, EnjcWorkspaceDTO } from '../../enjc-workspace';
import { WorkspaceEditHistoryEntry } from '../model';
import { changeMoveSymbolVertical, mkWorkspaceEditHistoryEntryDeprecated } from '../utils';

export const editMoveSymbolVertical = (
  workspace: EnjcWorkspaceDTO,
  symbolId: TSymbolId,
  moveStart: boolean,
): WorkspaceEditHistoryEntry => {
  return mkWorkspaceEditHistoryEntryDeprecated('editMoveSymbolVertical', {
    section: changeMoveSymbolVertical(workspace, symbolId, moveStart),
  });
};

import { assertNever } from '../../../utils';
import { EnjcWorkspaceSectionFragment, EnjcWorkspaceSymbolFragment } from '../../../libenjc/enjicalc-graphql';
import { EnjcWorkspaceDTO } from '../model';
import { isEnjcSectionRef } from './isEnjcSectionRef';
import { isEnjcSymbolRef } from './isEnjcSymbolRef';
import { getEnjcSection } from './getEnjcSection';
import { getEnjcSymbol } from './getEnjcSymbol';

export const collectSectionSymbols = (
  workspace: EnjcWorkspaceDTO,
  section: EnjcWorkspaceSectionFragment,
): ReadonlyArray<EnjcWorkspaceSymbolFragment> =>
  section.items.flatMap((sectionItem) => {
    if (isEnjcSectionRef(sectionItem)) {
      const currentSection = getEnjcSection(workspace, sectionItem.id);
      if (!currentSection) throw Error(`EnjcSection with id ${sectionItem.id} not found`);
      return collectSectionSymbols(workspace, currentSection);
    } else if (isEnjcSymbolRef(sectionItem)) {
      const currentSymbol = getEnjcSymbol(workspace, sectionItem.id);
      if (!currentSymbol) throw Error(`EnjcSymbol with id ${sectionItem.id} not found`);
      return [currentSymbol];
    } else {
      return assertNever(sectionItem);
    }
  });

import { EnjcWorkspaceSectionFragment } from '../../enjicalc-graphql';

export const findSheetSections = (
  sections: ReadonlyArray<EnjcWorkspaceSectionFragment>,
): ReadonlyArray<EnjcWorkspaceSectionFragment> => {
  const usedSections = new Set(
    sections
      .flatMap((section) => section.items)
      .filter((item) => item.__typename === 'EnjcWorkspaceSection')
      .map((item) => item.id),
  );
  return sections.filter((section) => !usedSections.has(section.id));
};

import { mkLiteralNumber, mkLiteralVoid, mkLiteralVoidNull, EnjcValueLiteralVoidCause } from '../../../enjc-literal';
import { isTreeNodeFunction, isTreeNodeLiteral, mkTreeLiteral, EnjcValueTreeNode } from '../../tree-node';
import { mkValueFunctionRef } from '../../../enjc-workspace';

/**
 * Updates the draft of a given EnjcValueTreeNode.
 * @param node - The EnjcValueTreeNode to update.
 * @param nextDraft - The new draft value.
 * @returns - The updated EnjcValueTreeNode.
 */
export const updateNodeDraft = (node: EnjcValueTreeNode, nextDraft: string): EnjcValueTreeNode => {
  const draftNumber = nextDraft.length > 0 ? Number(nextDraft) : NaN;

  if (isTreeNodeLiteral(node)) {
    return mkTreeLiteral(node.key, nextDraft, !isNaN(draftNumber) ? mkLiteralNumber(draftNumber) : mkLiteralVoidNull());
  } else if (isTreeNodeFunction(node)) {
    return { ...node, draft: nextDraft, funcSpec: mkValueFunctionRef(node.draft.length === 0 ? 'fg.noop' : 'fg.stub') };
  } else {
    return {
      ...node,
      draft: nextDraft,
    };
  }
};

import { EnjcValueTreeNode } from '../../tree-node';
import { EnjcValueTreeNodeDelta } from '../../../enjc-workspace-editing/model';
import { calculateNodeDelta } from '../../tree-navigation/actions';
import { EnjcValueTreeNodeMode } from '../../../../generated/graphql/types';
import { mkLiteralVoidNull } from '../../../enjc-literal';

export const mkNodeRemovalDelta = (node: EnjcValueTreeNode): EnjcValueTreeNodeDelta => {
  return {
    ...calculateNodeDelta(node, {
      __typename: 'EnjcValueTreeNode',
      key: node.key,
      draft: '',
      mode: EnjcValueTreeNodeMode.Literal,
      literal: mkLiteralVoidNull(),
      funcArgs: [],
      funcSpec: undefined,
      result: mkLiteralVoidNull(),
      symbol: undefined,
      tags: [],
    }),
    removeAdd: 'remove',
  };
};

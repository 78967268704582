import React, { ReactElement } from 'react';
import { MdClear } from 'react-icons/md';
import { Button, Input } from 'src/shadcn';

interface IProps {
  readonly searchTerm: string;
  readonly onSearchTermChange: (text: string) => void;
}

const SearchInputF = ({ searchTerm, onSearchTermChange }: IProps): ReactElement => {
  return (
    <div className="relative">
      <Input
        value={searchTerm}
        placeholder="Search..."
        className="pr-10 text-[15px] text-gray-500"
        onChange={(e) => onSearchTermChange(e.target.value)}
      />
      {searchTerm.length > 0 && (
        <Button
          size="sm"
          variant="ghost"
          className="absolute right-0 top-0 h-full"
          onClick={() => onSearchTermChange('')}
        >
          <MdClear />
        </Button>
      )}
    </div>
  );
};

export const SearchInput = React.memo(SearchInputF);

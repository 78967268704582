import {
  EnjicalcWorkspace,
  findWorkspaceItemContainer,
  getEnjcSection,
  isEnjcSectionRef,
  TSymbolId,
  EnjcWorkspaceDTO,
} from '../../enjc-workspace';
import { EnjcWorkspaceSectionDelta } from '../model';

export const changeMoveSymbolVertical = (
  workspace: EnjcWorkspaceDTO,
  symbolId: TSymbolId,
  moveStart: boolean,
): ReadonlyArray<EnjcWorkspaceSectionDelta> => {
  const containerInfo = findWorkspaceItemContainer(workspace, symbolId);
  if (!containerInfo) {
    // TODO: review
    return [];
  }

  if (moveStart) {
    if (containerInfo.index > 0) {
      return [
        {
          id: containerInfo.container.id,
          items: [
            {
              slicePosition: containerInfo.index - 1,
              sliceBefore: containerInfo.container.items
                .slice(containerInfo.index - 1, containerInfo.index - 1 + 2)
                .map((it) => it.id),
              sliceAfter: [
                containerInfo.container.items[containerInfo.index].id,
                containerInfo.container.items[containerInfo.index - 1].id,
              ],
            },
          ],
        },
      ];
    } else {
      const cci = findWorkspaceItemContainer(workspace, containerInfo.container.id);
      if (cci && cci.index > 0 && isEnjcSectionRef(cci.container.items[cci.index - 1])) {
        const nextSection = getEnjcSection(workspace, cci.container.items[cci.index - 1].id);
        return [
          {
            id: containerInfo.container.id,
            items: [
              {
                slicePosition: containerInfo.index,
                sliceBefore: [containerInfo.container.items[containerInfo.index].id],
              },
            ],
          },
          {
            id: nextSection.id,
            items: [
              {
                slicePosition: nextSection.items.length,
                sliceAfter: [containerInfo.container.items[containerInfo.index].id],
              },
            ],
          },
        ];
      } else {
        // TODO: review
        return [];
      }
    }
  } else {
    // Move end
    if (containerInfo.index < containerInfo.container.items.length - 1) {
      return [
        {
          id: containerInfo.container.id,
          items: [
            {
              slicePosition: containerInfo.index,
              sliceBefore: containerInfo.container.items
                .slice(containerInfo.index, containerInfo.index + 2)
                .map((it) => it.id),
              sliceAfter: [
                containerInfo.container.items[containerInfo.index + 1].id,
                containerInfo.container.items[containerInfo.index].id,
              ],
            },
          ],
        },
      ];
    } else {
      const cci = findWorkspaceItemContainer(workspace, containerInfo.container.id);
      if (cci && cci.index < cci.container.items.length - 1 && isEnjcSectionRef(cci.container.items[cci.index + 1])) {
        const nextSection = getEnjcSection(workspace, cci.container.items[cci.index + 1].id);
        return [
          {
            id: containerInfo.container.id,
            items: [
              {
                slicePosition: containerInfo.index,
                sliceBefore: [containerInfo.container.items[containerInfo.index].id],
              },
            ],
          },
          {
            id: nextSection.id,
            items: [
              {
                slicePosition: 0,
                sliceAfter: [containerInfo.container.items[containerInfo.index].id],
              },
            ],
          },
        ];
      } else {
        // TODO: review
        return [];
      }
    }
  }
};

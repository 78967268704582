import { invertAtomDelta, invertAuxMetaDelta, invertKeyListDelta } from '../../enjc-delta';
import { EnjcWorkspaceSectionDelta } from '../model';

export const invertSectionDelta = (delta: EnjcWorkspaceSectionDelta): EnjcWorkspaceSectionDelta => {
  return {
    id: delta.id,
    visibility: delta.visibility && invertAtomDelta(delta.visibility),
    auxMeta: delta.auxMeta && invertAuxMetaDelta(delta.auxMeta),
    items: delta.items && invertKeyListDelta(delta.items),
  };
};

import { TSectionId, EnjcWorkspaceDTO } from '../../enjc-workspace';
import { WorkspaceEditHistoryEntry } from '../model';
import { AUX_META_WORKSPACE_ITEM_TITLE } from '../../enjc-workspace/constants';
import { editSetSectionAuxMetaStrEntry } from './editSetSectionAuxMetaStrEntry';

export const editSetSectionTitle = (
  workspace: EnjcWorkspaceDTO,
  sectionId: TSectionId,
  title: string,
): WorkspaceEditHistoryEntry => {
  return editSetSectionAuxMetaStrEntry(workspace, sectionId, AUX_META_WORKSPACE_ITEM_TITLE, title);
};

import { EnjcValueTreeNode } from '../../enjc-symbol-value-tree';
import { IValueTreeViewContext } from '../model';
import { getNodeByKeyNVTV } from './getNodeByKeyNVTV';

export const getRootNodeNVTV = (vtvCtx: IValueTreeViewContext): EnjcValueTreeNode | undefined => {
  return (
    (vtvCtx.symbol.valueTree.rootNode?.key && getNodeByKeyNVTV(vtvCtx, vtvCtx.symbol.valueTree.rootNode.key)) ||
    undefined
  );
};

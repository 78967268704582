import React, { ReactElement } from 'react';
import { EnjicalcWorkspaceItemsRefs, TWorkspaceId } from '../../../libenjc/enjc-workspace';
import { EnjicalcWorkspaceUpdateMessage, WorkspaceEditHistoryEntry } from '../../../libenjc/enjc-workspace-editing';
import { WorkspaceCard } from './WorkspaceCard';

interface IProps {
  readonly workspaces: ReadonlyArray<EnjicalcWorkspaceItemsRefs>;
  readonly performWorkspaceEdit: (
    workspace: EnjicalcWorkspaceItemsRefs,
    editEntry: WorkspaceEditHistoryEntry,
  ) => Promise<EnjicalcWorkspaceUpdateMessage>;
  readonly onWorkspaceFork: (workspaceId: TWorkspaceId) => void;
}

export const WorkspacesListView = ({ workspaces, performWorkspaceEdit, onWorkspaceFork }: IProps): ReactElement => {
  if (!workspaces.length) {
    return (
      <div className="flex size-full flex-col items-start pt-[150px] opacity-60">
        <h2 className="text-2xl font-bold text-gray-600">No workspaces found.</h2>
        <p className="text-base text-gray-600">It looks like you don't have any workspaces yet.</p>
      </div>
    );
  }

  return (
    <div className="flex flex-wrap">
      {workspaces.map((workspace) => (
        <div key={workspace.id}>
          <WorkspaceCard
            workspace={workspace}
            performWorkspaceEdit={(editEntry: WorkspaceEditHistoryEntry) => performWorkspaceEdit(workspace, editEntry)}
            onWorkspaceFork={onWorkspaceFork}
          />
        </div>
      ))}
    </div>
  );
};

import React, { ReactElement } from 'react';
import { MathMathMLAttributes } from '../../../libenjc/enjc-react-mathml/attributes';
import { defaultMathBlockProps } from '../../../config/mathml-props';
import { EnjicalcSymbol } from '../../../libenjc/enjc-workspace';
import { EnjcSymbolValueMathmlView } from '../../../libenjc/enjc-react/enjc-react-components/enjc-symbol-value-mathml-view';
import { EnjcGlyphMathmlAdapterView } from '../../../libenjc/enjc-react/enjc-react-components/enjc-glyph-mathml-view';
import { SymbolResultMathView } from './SymbolResultMathView';

interface IProps {
  readonly symbol: EnjicalcSymbol;
  // TODO: review type
  readonly mathProps?: MathMathMLAttributes;
  readonly showGlyph?: boolean;
  readonly showSymbolic?: boolean;
  readonly showNumeric?: boolean;
  readonly showResult?: boolean;
}

export const SymbolValueMathBlockViewF = ({
  symbol,
  mathProps,
  showGlyph,
  showSymbolic,
  showNumeric,
  showResult,
}: IProps): ReactElement => {
  return (
    <math {...defaultMathBlockProps} {...mathProps}>
      <mrow style={{ display: 'flex', alignItems: 'center' }}>
        {showGlyph && <EnjcGlyphMathmlAdapterView glyphAsciiMathStr={symbol.glyph} />}
        {showSymbolic && showGlyph && <mo className="px-1 py-2">=</mo>}
      </mrow>

      <mrow style={{ display: 'flex', alignItems: 'center' }}>
        {showSymbolic && <EnjcSymbolValueMathmlView noRootContainer viewOptions={{ literalsAsDrafts: true }} />}
        {showNumeric && (showGlyph || showSymbolic) && <mo className="px-1 py-2">=</mo>}
      </mrow>

      <mrow style={{ display: 'flex', alignItems: 'center' }}>
        {showNumeric && (
          <EnjcSymbolValueMathmlView noRootContainer viewOptions={{ numerical: true, literalsAsDrafts: true }} />
        )}
        {showResult && (showGlyph || showSymbolic || showNumeric) && <mo className="px-1 py-2">=</mo>}
      </mrow>

      {showResult && <SymbolResultMathView literal={symbol.valueTree.result} />}
    </math>
  );
};

export const SymbolValueMathBlockView = React.memo(SymbolValueMathBlockViewF);

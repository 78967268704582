import { assertNever, formatNumerical } from '../../../utils';
import { UEnjcValueLiteral } from '../model';

export const valueLiteralToString = (literal: UEnjcValueLiteral | undefined | null): string => {
  switch (literal?.__typename) {
    case null:
    case undefined:
    case 'EnjcValueLiteralVoid':
      return 'NaN';
    case 'EnjcValueLiteralBoolean':
      return literal.bool ? 'true' : 'false';
    case 'EnjcValueLiteralNumber':
      return formatNumerical(literal.numb);
    case 'EnjcValueLiteralString':
      return `"${literal.str}"`;
    default:
      return assertNever(literal);
  }
};

import { EnjicalcSheet, EnjicalcSymbol, getSymbolUnitDescriptionComment } from '../../enjc-workspace';
import { isTreeNodeFunction } from '../../enjc-symbol-value-tree/tree-node';
import { ValueHintEntry } from '../../enjc-value-hints';
import { getFunctionBinaryOperatorById } from '../../enjc-value-operators';
import { IValueTreeViewContext } from '../model';
import { getCursorPositionNode } from './getCursorPositionNode';
import { getValueFunctionsListNVTV } from './getValueFunctionsListNVTV';
import { isHintEntrySymbol } from '../../enjc-react/enjc-react-components/enjc-symbol-value-plain-view/components/SymbolValueHintsView';

export const getValueHintEntries = (
  vtvCtx: IValueTreeViewContext,
  symbol: EnjicalcSymbol,
  symbolSheet: EnjicalcSheet,
): ReadonlyArray<ValueHintEntry> => {
  const valueCpn = getCursorPositionNode(vtvCtx);

  const symbolSheetIndex = symbolSheet.symbols.findIndex((sy) => sy.id === symbol.id);
  // !valueCpn.positionNode.draft ||
  const symbolsSheetAbove = symbolSheet.symbols
    .slice(0, symbolSheetIndex >= 0 ? symbolSheetIndex : undefined)
    .filter(
      (sy) =>
        !valueCpn ||
        valueCpn.positionNode.draft.length === 0 ||
        [sy.glyph, ...sy.aliases, getSymbolUnitDescriptionComment(sy).description].filter((v) =>
          v.toLowerCase().includes(valueCpn.positionNode.draft.toLowerCase()),
        ).length > 0,
    );
  // const symbolsSheetBelow = symbolSheetIndex >= 0 ? symbolSheet.symbols.slice(symbolSheetIndex + 1) : [];
  const workspaceFunctions = getValueFunctionsListNVTV(vtvCtx).filter(
    (woFu) =>
      !woFu.flagInternal &&
      !getFunctionBinaryOperatorById(woFu.id) &&
      (!valueCpn ||
        valueCpn.positionNode.draft.length === 0 ||
        [woFu.glyph, ...woFu.aliases].filter((v) => v.toLowerCase().includes(valueCpn.positionNode.draft.toLowerCase()))
          .length > 0),
  );

  const workspaceSymbols = vtvCtx.workspace.symbols;
  const showOnlySheetAboveSymbolsHints = true;
  const hintSymbols = showOnlySheetAboveSymbolsHints ? symbolsSheetAbove : workspaceSymbols;

  const hintSections =
    valueCpn && isTreeNodeFunction(valueCpn.positionNode)
      ? [workspaceFunctions, hintSymbols]
      : [hintSymbols, workspaceFunctions];

  return [
    ...hintSections.flatMap((valueHintSection) =>
      valueHintSection.map((sy, index) => ({
        sheetIndex: isHintEntrySymbol(sy) ? symbolSheet.symbols.findIndex((shSy) => shSy.id === sy.id) : -1,
        sectionStart: index === 0 ? true : undefined,
        item: sy,
      })),
    ),
  ];
};

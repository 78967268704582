import React from 'react';
import {
  TWorkspaceId,
  EnjcWorkspaceUpdateMessageFragment,
  EnjcWorkspaceInput,
  useCreateWorkspaceMutation,
  useUpdateWorkspaceMutation,
  useForkWorkspaceMutation,
} from '../../../../libenjc/enjicalc-graphql';

export interface IHook {
  readonly createWorkspace: (diff?: EnjcWorkspaceInput) => Promise<EnjcWorkspaceUpdateMessageFragment>;
  readonly updateWorkspace: (
    workspaceId: TWorkspaceId,
    generation: number,
    diff: EnjcWorkspaceInput,
  ) => Promise<EnjcWorkspaceUpdateMessageFragment>;
  readonly forkWorkspace: (
    workspaceId: TWorkspaceId,
    generation: number,
    diff?: EnjcWorkspaceInput,
  ) => Promise<EnjcWorkspaceUpdateMessageFragment>;
}

export const useEnjcRawWorkspaceMutations = (): IHook => {
  const [createWorkspaceM] = useCreateWorkspaceMutation();
  const [updateWorkspaceM] = useUpdateWorkspaceMutation();
  const [forkWorkspaceM] = useForkWorkspaceMutation();

  return React.useMemo(
    () => ({
      createWorkspace: (diff?: EnjcWorkspaceInput) =>
        createWorkspaceM({ variables: { diff } }).then(
          (mutationResult) => mutationResult.data?.createWorkspace || Promise.reject(),
        ),
      updateWorkspace: (workspaceId: TWorkspaceId, generation: number, diff: EnjcWorkspaceInput) =>
        updateWorkspaceM({ variables: { workspace: workspaceId, generation, diff } }).then(
          (mutationResult) => mutationResult.data?.updateWorkspace || Promise.reject(),
        ),
      forkWorkspace: (workspaceId: TWorkspaceId, generation: number, diff?: EnjcWorkspaceInput) =>
        forkWorkspaceM({ variables: { workspace: workspaceId, generation, diff } }).then(
          (mutationResult) => mutationResult.data?.forkWorkspace || Promise.reject(),
        ),
    }),
    [createWorkspaceM, forkWorkspaceM, updateWorkspaceM],
  );
};

import { TValueFunctionId } from '../../../enjc-value-math/model/TValueFunctionId';
import { mkLiteralVoidNull } from '../../../enjc-literal';
import { EnjcValueTreeNodeMode } from '../../../enjicalc-graphql';
import { mkValueFunctionRef } from '../../../enjc-workspace';
import { TValueTreeNodeKey } from '../types';
import { EnjcValueTreeNode } from '../model';
import { mkValueTreeNodeRef } from './mkValueTreeNodeRef';

export const mkTreeFunction = (
  key: string,
  draft: string,
  functionId: TValueFunctionId | undefined,
  args: ReadonlyArray<TValueTreeNodeKey>,
): EnjcValueTreeNode => ({
  __typename: 'EnjcValueTreeNode',
  key,
  tags: [],
  draft,
  mode: EnjcValueTreeNodeMode.Function,
  literal: mkLiteralVoidNull(),
  funcSpec: mkValueFunctionRef(functionId),
  funcArgs: args.map((k) => mkValueTreeNodeRef(k)),
  result: mkLiteralVoidNull(),
});

import { useSearchParams } from 'react-router-dom';
import React from 'react';
import { TSymbolId } from 'src/generated/graphql/types';
import { ESections } from 'src/components/editors/symbol-editor/SymbolEditor';

export const useSearchParamsSheetDocumentModalSymbolId = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [section, setSection] = React.useState<ESections>(ESections.calculations);

  const modalSymbolId = React.useMemo(() => searchParams.get('modalSymbol') || '', [searchParams]);

  const setModalSymbolId = React.useCallback(
    (symbolId: TSymbolId, sectionValue = ESections.calculations) => {
      if (sectionValue) {
        setSection(sectionValue);
      }

      if (symbolId) {
        setSearchParams(
          (prev) =>
            new URLSearchParams([
              ...[...prev.entries()].filter((v) => v[0] !== 'modalSymbol'),
              ['modalSymbol', symbolId],
            ]),
        );
      } else {
        setSearchParams((prev) => new URLSearchParams([...prev.entries()].filter((v) => v[0] !== 'modalSymbol')));
      }
    },
    [setSearchParams],
  );

  return {
    modalSymbolId,
    setModalSymbolId,
    section,
  };
};

import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { RxDashboard } from 'react-icons/rx';
import { GoSidebarCollapse, GoSidebarExpand } from 'react-icons/go';
import { MenuSettings } from './menus';
import { SideBarContext } from '../../context/SideBarContext';

interface IProps {
  onSave: () => void;
}

const AppActivityBarF = ({ onSave }: IProps): ReactElement => {
  const navigate = useNavigate();
  const { isShow, onToggle } = React.useContext(SideBarContext);

  const handleNavigateToDashboard = React.useCallback(() => {
    onSave();
    navigate('/dashboard');
  }, [navigate, onSave]);

  return (
    <div className="flex h-screen w-[50px] min-w-[50px] flex-col items-center gap-3 border-r border-r-gray-200 bg-white">
      <div className="flex h-[99%] flex-col items-center justify-between">
        <div className="flex flex-col items-center">
          <button className="mt-1 rounded-md p-2 hover:bg-gray-100" onClick={handleNavigateToDashboard}>
            <RxDashboard size={25} className="text-gray-500" />
          </button>

          <button className="rounded-md p-2 hover:bg-gray-100" onClick={() => onToggle()}>
            {isShow ? (
              <GoSidebarExpand size={25} className="text-gray-500" />
            ) : (
              <GoSidebarCollapse size={25} className="text-gray-500" />
            )}
          </button>
        </div>

        <div className="mb-4 flex flex-col items-center">{false && <MenuSettings />}</div>
      </div>
    </div>
  );
};

export const AppActivityBar = React.memo(AppActivityBarF);

import { IEnjcWorkspaceLocalHistory } from '../interfaces';
import { TSectionId } from '../../../generated/graphql/types';
import { EnjcWorkspaceSectionDelta, EnjcWorkspaceSymbolDelta, ItemCreateEntry } from '../model';
import { getEnjcSection } from '../../enjc-workspace';
import { mkAtomDelta } from '../../enjc-delta';
import { mkLiteralNumber, mkLiteralVoidNull, UEnjcValueLiteral } from '../../enjc-literal';
import { hCreateSymbol } from './hCreateSymbol';

export const hCreateSymbolIn = (wlh: IEnjcWorkspaceLocalHistory, sectionId: TSectionId): ItemCreateEntry => {
  const existingSection = getEnjcSection(wlh.currentWorkspace, sectionId);

  const hcr = hCreateSymbol(wlh);

  const sectionDelta: EnjcWorkspaceSectionDelta = {
    id: existingSection.id,
    items: [{ slicePosition: existingSection.items.length, sliceAfter: [hcr.itemId] }],
  };

  const symbolDelta: EnjcWorkspaceSymbolDelta = {
    id: hcr.itemId,
    valueTree: {
      nodes: [
        {
          key: 'ab',
          // This literal works as a more recognizable (compared to square of
          // null literal) placeholder for initial empty draft
          literal: mkAtomDelta<UEnjcValueLiteral>(mkLiteralVoidNull(), mkLiteralNumber(0)),
        },
      ],
    },
  };

  return {
    ...hcr,
    historyEntry: {
      ...hcr.historyEntry,
      delta: {
        ...hcr.historyEntry.delta,
        section: [...(hcr.historyEntry.delta.section ?? []), sectionDelta],
        symbol: [...(hcr.historyEntry.delta.symbol ?? []), symbolDelta],
      },
    },
  };
};

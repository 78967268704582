import { WorkspaceEditHistoryEntry } from '../../enjc-workspace-editing';
import { EnjcWorkspaceUpdateInput } from '../../enjicalc-graphql';
import { tmpPatchEnjcWorkspaceUpdateInput } from './tmpPatchEnjcWorkspaceUpdateInput';
import { convertWorkspaceMetaDeltaToDiffInput } from './convertWorkspaceMetaDeltaToDiffInput';
import { convertWorkspaceSectionDeltaToDiffInput } from './convertWorkspaceSectionDeltaToDiffInput';
import { convertWorkspaceSymbolDeltaToDiffInput } from './convertWorkspaceSymbolDeltaToDiffInput';

export const convertWorkspaceEditHistoryEntryToDiffInput = (
  entry: WorkspaceEditHistoryEntry,
): EnjcWorkspaceUpdateInput => {
  return tmpPatchEnjcWorkspaceUpdateInput({
    title: entry.actionMeta.title,
    timestamp: entry.timestamp,
    workspaceSelf:
      entry.delta.workspaceSelf && entry.delta.workspaceSelf.map((d) => convertWorkspaceMetaDeltaToDiffInput(d)),
    section: entry.delta.section && entry.delta.section.map((d) => convertWorkspaceSectionDeltaToDiffInput(d)),
    symbol: entry.delta.symbol && entry.delta.symbol.map((d) => convertWorkspaceSymbolDeltaToDiffInput(d)),
  });
};

import { EnjicalcSection, EnjicalcSymbol, EnjcWorkspaceDTO } from '../../enjc-workspace';
import { EnjcWorkspaceDelta } from '../model';
import { applySectionDelta } from './applySectionDelta';
import { applySymbolDelta } from './applySymbolDelta';
import { applyWorkspaceMetaDelta } from './applyWorkspaceMetaDelta';

const getSectionItemTypename = (
  sections: ReadonlyArray<EnjicalcSection>,
  symbols: ReadonlyArray<EnjicalcSymbol>,
  itemId: string,
): 'EnjcWorkspaceSymbol' | 'EnjcWorkspaceSection' => {
  if (sections.find((s) => s.id === itemId)) return 'EnjcWorkspaceSection';
  if (symbols.find((s) => s.id === itemId)) return 'EnjcWorkspaceSymbol';
  throw new Error('Unknown section item type');
};

export const applyWorkspaceDelta = (workspace: EnjcWorkspaceDTO, delta: EnjcWorkspaceDelta): EnjcWorkspaceDTO => {
  console.debug('Start of applyWorkspaceDelta');
  // FIXME: implement new section / symbol creation
  const nextWorkspaceSymbols: ReadonlyArray<EnjicalcSymbol> = !delta.symbol
    ? workspace.symbols
    : delta.symbol.reduce(
        (acc, d) =>
          acc.find((accSy) => accSy.id === d.id)
            ? acc.map((s) => (s.id !== d.id ? s : applySymbolDelta(s, d)))
            : [...acc, applySymbolDelta(undefined, d)],
        workspace.symbols,
      );
  const nextWorkspaceSections: ReadonlyArray<EnjicalcSection> = !delta.section
    ? workspace.sections
    : delta.section.reduce((acc, d) => {
        const getItemTypename = (itemId: string) => {
          return getSectionItemTypename(acc, nextWorkspaceSymbols, itemId);
        };
        return acc.find((accSe) => accSe.id === d.id)
          ? acc.map((s) => (s.id !== d.id ? s : applySectionDelta(s, d, getItemTypename)))
          : [...acc, applySectionDelta(undefined, d, getItemTypename)];
      }, workspace.sections);
  console.debug('nextWorkspaceSections', nextWorkspaceSections);

  const workspaceStage1 = { ...workspace, sections: nextWorkspaceSections, symbols: nextWorkspaceSymbols };
  console.debug('End of applyWorkspaceDelta', workspaceStage1);

  // FIXME: problem here
  return workspaceStage1;
  // return workspace;
  // return (delta.workspaceSelf ?? []).reduce((acc, d) => applyWorkspaceMetaDelta(acc, d), workspaceStage1);
};

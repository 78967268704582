import { MathMathMLAttributes } from '../libenjc/enjc-react-mathml/attributes';

export const defaultMathBlockProps: MathMathMLAttributes = {
  style: {
    // fontFamily: 'Latin Modern Math',
    fontSize: '15px',
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '5px',
  },
};

import React from 'react';
import { EnjicalcWorkspaceContext } from '../context';
import { useCtxEnjicalcWorkspaceEditHistory } from './useCtxEnjicalcWorkspaceEditHistory';

export const useCtxEnjicalcWorkspace = () => {
  const workspace = useCtxEnjicalcWorkspaceEditHistory().currentWorkspace;
  const result = React.useMemo(() => ({ workspace }), [workspace]);
  return result;
  // return React.useContext(EnjicalcWorkspaceContext);
};

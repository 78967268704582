import { ReactElement, ReactNode } from 'react';

interface IProps {
  readonly children: ReadonlyArray<ReactNode>;
  readonly onClick?: () => void;
}

export const SymbolValuePlainViewContainer = ({ children, onClick }: IProps): ReactElement => {
  return (
    <div id="symbol-value-plain-view-container" className="relative inline" onClick={onClick}>
      {children}
    </div>
  );
};

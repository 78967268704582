import { TValueFunctionId } from '../../enjc-value-math/model/TValueFunctionId';
import { EnjcValueFunctionSpecFragment } from '../../../libenjc/enjicalc-graphql';
import { EnjcWorkspaceWithMath, EnjicalcWorkspace } from '../model';

export const getEnjcFunction = (
  enjicalcWorkspace: EnjcWorkspaceWithMath,
  valueFunctionId: TValueFunctionId,
): EnjcValueFunctionSpecFragment => {
  const result = enjicalcWorkspace.functions.find((item) => item.id === valueFunctionId);
  if (result === undefined) {
    throw Error(`Function with id ${valueFunctionId} not found`);
  }
  return result;
};

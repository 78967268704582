import { stringDeltaToInput } from '../../enjc-delta';
import { EnjcWorkspaceSymbolDelta } from '../../enjc-workspace-editing';
import { EnjcWorkspaceSymbolInput } from '../../enjicalc-graphql';
import { convertValueTreeNodeDeltaToDiffInput } from './convertValueTreeNodeDeltaToDiffInput';
import { convertAuxMetaEntryDeltaToDiffInput } from './convertAuxMetaEntryDeltaToDiffInput';

export const convertWorkspaceSymbolDeltaToDiffInput = (delta: EnjcWorkspaceSymbolDelta): EnjcWorkspaceSymbolInput => {
  return {
    id: delta.id,
    visibility: delta.visibility?.atomAfter,
    auxMeta: delta.auxMeta && delta.auxMeta.map((d) => convertAuxMetaEntryDeltaToDiffInput(d, false)),
    glyph: delta.glyph && delta.glyph.map((d) => stringDeltaToInput(d, false)),
    glyphPlaintext: delta.glyphPlaintext && delta.glyphPlaintext.map((d) => stringDeltaToInput(d, false)),
    valueTree: delta.valueTree?.nodes && {
      nodes: delta.valueTree.nodes.map((d) => convertValueTreeNodeDeltaToDiffInput(d)),
    },
  };
};

import { EnjcWorkspaceSectionFragment, TSectionId } from '../../../libenjc/enjicalc-graphql';
import { EnjcWorkspaceDTO } from '../model';
import { getEnjcSectionOrUndefined } from './getEnjcSectionOrUndefined';

export const getEnjcSection = (
  enjicalcWorkspace: EnjcWorkspaceDTO,
  sectionId: TSectionId,
): EnjcWorkspaceSectionFragment => {
  const result = getEnjcSectionOrUndefined(enjicalcWorkspace, sectionId);
  if (result === undefined) {
    throw Error(`Section with id ${sectionId} not found`);
  }
  return result;
};

import React, { ReactElement } from 'react';
import { LiaRedoSolid, LiaUndoSolid } from 'react-icons/lia';
import { MathMathMLAttributes } from '../../../libenjc/enjc-react-mathml/attributes';
import { EnjcStringDeltaEntry } from '../../../libenjc/enjc-delta';
import { EnjicalcSymbol, getSymbolUnitDescriptionComment } from '../../../libenjc/enjc-workspace';
import { EnjcValueTreeDelta } from '../../../libenjc/enjc-workspace-editing';
import {
  useCtxEnjicalcWorkspaceEditHistory,
  useCtxEnjicalcWorkspace,
  useCtxEnjicalcWorkspaceWithMath,
} from '../../../libenjc/enjc-react/enjc-react-context';
import { SymbolValueSplitEditor } from '../symbol-value-split-editor';
import { SymbolTextAreaFieldEditor } from './SymbolTextAreaFieldEditor';
import { SymbolTextLineFieldEditor } from './SymbolTextLineFieldEditor';
import {
  AccordionItem,
  AccordionTrigger,
  Accordion,
  AccordionContent,
  DialogHeader,
  DialogTitle,
  DialogClose,
  Button,
} from 'src/shadcn';
import {
  CONFETTI_DURATION,
  DELTA_BETA_C,
  GAMMA,
  QUICK_START_TOUR_STEP_COUNT,
  QUICK_START_TOUR_STEP_NAMES,
  useQuickStartTour,
  XI_PH_THETA,
} from 'src/hooks';
import { checkCanRedo } from '../../../libenjc/enjc-workspace-editing/utils';
import { PrintButtonTooltipContext } from 'src/context/PrintButtonTooltipContext';
import { valueLiteralToString } from 'src/libenjc/enjc-literal';

export enum ESections {
  calculations = 'calculations',
  comment = 'comment',
}

interface IProps {
  readonly symbol: EnjicalcSymbol;

  readonly isOpen?: boolean;
  readonly onClose?: (isSaved: boolean) => void;
  readonly onConfirm?: () => void;
  readonly onSaveButtonStateChange?: (isEnabled: boolean) => void;
  readonly onCloseModal?: () => void;
  readonly section?: ESections;
}

export const SymbolEditor = ({
  symbol,
  onClose,
  onConfirm,
  isOpen,
  onSaveButtonStateChange,
  onCloseModal,
  section,
}: IProps): ReactElement => {
  const mathProps: MathMathMLAttributes = {
    style: {
      // fontFamily: 'Latin Modern Math',
      fontSize: '22px',
    },
  };

  const {
    workspaceWithMath: { functions: workspaceFunctions },
  } = useCtxEnjicalcWorkspaceWithMath();
  const { workspace } = useCtxEnjicalcWorkspace();
  const { workspaceEditHistory, performUndo, performRedo, performEditAction, performUndoMany } =
    useCtxEnjicalcWorkspaceEditHistory();
  // workspaceEditHistory.currentState;

  const [initialHistoryPosition] = React.useState(workspaceEditHistory.historyPosition);

  const {
    setCurrentStep,
    isAlpha,
    isBeta,
    currentStep,
    isOpen: isOpenQuickStart,
    isFinished,
    setIsOpen,
  } = useQuickStartTour();

  const { onToggle } = React.useContext(PrintButtonTooltipContext);

  // const currentSymbol = getEnjcSymbol(workspace, symbol.id);
  const currentSymbol = symbol;
  const {
    unit: symbolUnit,
    description: symbolDescription,
    comment: symbolComment,
  } = getSymbolUnitDescriptionComment(currentSymbol);

  const isDisabledSaveButton = workspaceEditHistory.historyPosition === 0;

  const [activeSection, setActiveSection] = React.useState<ESections>(section || ESections.calculations);

  const handleAccordionSectionChange = (sectionValue: ESections) => setActiveSection(sectionValue);

  const groupTag = React.useMemo(() => `symbol-${symbol.id}`, [symbol.id]);

  const performModalUndo = React.useCallback(() => {
    performUndo(groupTag);
  }, [groupTag, performUndo]);

  const performModalRedo = React.useCallback(() => {
    performRedo(groupTag);
  }, [groupTag, performRedo]);

  const onRevertChange = React.useCallback(() => {
    const undoSteps = workspaceEditHistory.historyPosition - initialHistoryPosition;
    performUndoMany(undoSteps);
    onCloseModal && onCloseModal();
  }, [workspaceEditHistory.historyPosition, initialHistoryPosition, performUndoMany, onCloseModal]);

  const updateSymbolGlyph = React.useCallback(
    (delta: EnjcStringDeltaEntry) =>
      performEditAction.updateSymbolGlyph(symbol.id, delta, {
        title: 'Edit Symbol Glyph',
        groupTag,
      }),
    [performEditAction, symbol.id, groupTag],
  );

  const updateSymbolUnit = React.useCallback(
    (delta: EnjcStringDeltaEntry) =>
      performEditAction.updateSymbolUnit(symbol.id, delta, {
        title: 'Edit Symbol Unit',
        groupTag,
      }),
    [performEditAction, symbol.id, groupTag],
  );

  const updateSymbolDescription = React.useCallback(
    (delta: EnjcStringDeltaEntry) =>
      performEditAction.updateSymbolDescription(symbol.id, delta, {
        title: 'Edit Symbol Description',
        groupTag,
      }),
    [performEditAction, symbol.id, groupTag],
  );

  const updateSymbolComment = React.useCallback(
    (delta: EnjcStringDeltaEntry) =>
      performEditAction.updateSymbolComment(symbol.id, delta, {
        title: 'Edit Symbol Comment',
        groupTag,
      }),
    [performEditAction, symbol.id, groupTag],
  );

  const updateSymbolValueTree = React.useCallback(
    (delta: EnjcValueTreeDelta) =>
      performEditAction.updateSymbolValueTree(symbol.id, delta, {
        title: 'Edit Symbol Value',
        groupTag,
      }),
    [performEditAction, symbol.id, groupTag],
  );

  React.useEffect(() => {
    if (isAlpha) {
      if (currentSymbol.glyph === DELTA_BETA_C) {
        setCurrentStep(QUICK_START_TOUR_STEP_COUNT.AddValueForSymbol);
      }
    } else if (isBeta) {
      if (currentSymbol.glyph === XI_PH_THETA) {
        setCurrentStep(QUICK_START_TOUR_STEP_COUNT.AddValueForSymbolBetta);
      }
    } else {
      if (currentSymbol.glyph === GAMMA) {
        setCurrentStep(QUICK_START_TOUR_STEP_COUNT.AddValueForSymbolGamma);
      }
    }
  }, [currentSymbol.glyph, setCurrentStep, isAlpha, isBeta]);

  React.useEffect(() => {
    if (isAlpha) {
      // @ts-ignore
      if (currentSymbol.valueTree.nodes[0].literal?.numb === 12) {
        setCurrentStep(QUICK_START_TOUR_STEP_COUNT.SaveSymbol);
      }
    } else if (isBeta) {
      if (valueLiteralToString(currentSymbol.valueTree.result) === '112.00') {
        setCurrentStep(QUICK_START_TOUR_STEP_COUNT.SaveSymbolBetta);
      }
    }
  }, [currentSymbol, setCurrentStep, isAlpha, isBeta]);

  React.useEffect(() => {
    onSaveButtonStateChange && onSaveButtonStateChange(!isDisabledSaveButton);
  }, [onSaveButtonStateChange, isDisabledSaveButton]);

  return (
    <>
      <div className="flex flex-1 flex-col items-start">
        <DialogHeader className="gap-5">
          <DialogTitle>Symbol Editor</DialogTitle>
          <DialogClose onClick={onCloseModal} />
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <button
            className={`rounded p-2 ${
              workspaceEditHistory.historyPosition === initialHistoryPosition
                ? 'cursor-not-allowed bg-gray-200 text-gray-400'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
            disabled={workspaceEditHistory.historyPosition === initialHistoryPosition}
            onClick={performModalUndo}
          >
            <LiaUndoSolid />
          </button>
          <button
            className={`rounded p-2 ${
              workspaceEditHistory.historyPosition === workspaceEditHistory.historyEntries.length
                ? 'cursor-not-allowed bg-gray-200 text-gray-400'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
            disabled={!checkCanRedo(workspaceEditHistory)}
            onClick={performModalRedo}
          >
            <LiaRedoSolid />
          </button>
        </div>
        <hr className="my-4 w-full border-t border-gray-200" />

        <Accordion
          type="single"
          collapsible
          value={activeSection}
          onValueChange={handleAccordionSectionChange}
          className="w-full"
        >
          <AccordionItem value={ESections.calculations}>
            <AccordionTrigger>Calculations:</AccordionTrigger>
            <AccordionContent>
              <div className="flex flex-col items-start gap-2 pt-1">
                <div
                  className={`w-full ${
                    isAlpha
                      ? QUICK_START_TOUR_STEP_NAMES.WriteSymbolName
                      : isBeta
                        ? QUICK_START_TOUR_STEP_NAMES.WriteSymbolNameBetta
                        : QUICK_START_TOUR_STEP_NAMES.WriteSymbolNameGamma
                  }`}
                >
                  <SymbolTextLineFieldEditor
                    informationText={
                      'We are following the AsciiMath convention for the Symbol Name. In case of a random error, go to https://asciimath.org/ playground and find a mistake.'
                    }
                    name={'Symbol Name:'}
                    text={currentSymbol.glyph}
                    onTextDelta={updateSymbolGlyph}
                  />
                </div>

                <div
                  className={`${
                    isAlpha
                      ? QUICK_START_TOUR_STEP_NAMES.AddValueForSymbol
                      : isBeta
                        ? QUICK_START_TOUR_STEP_NAMES.AddValueForSymbolBetta
                        : ''
                  }`}
                >
                  <SymbolValueSplitEditor
                    workspace={workspace}
                    workspaceMath={{ functions: workspaceFunctions }}
                    symbol={currentSymbol}
                    showGlyph
                    showResult
                    mathProps={mathProps}
                    onSymbolValueDelta={updateSymbolValueTree}
                    performUndo={performModalUndo}
                    performRedo={performModalRedo}
                  />
                </div>

                <div className="w-full">
                  <SymbolTextLineFieldEditor
                    informationText={
                      'For the time being, we will keep the units as simple text. We are planning to deliver unit intelligence by mid-2025.'
                    }
                    name={'Unit:'}
                    text={symbolUnit}
                    onTextDelta={updateSymbolUnit}
                  />
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value={ESections.comment}>
            <AccordionTrigger>Description & Comment:</AccordionTrigger>
            <AccordionContent>
              <div className="flex flex-col items-start gap-2 pt-1">
                <SymbolTextAreaFieldEditor
                  name={'Description'}
                  text={symbolDescription}
                  onTextDelta={updateSymbolDescription}
                />

                <SymbolTextAreaFieldEditor name={'Comment'} text={symbolComment} onTextDelta={updateSymbolComment} />
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="absolute bottom-5 right-5 flex items-center space-x-2">
        <Button
          className="w-[210px] text-[15px]"
          variant="outline"
          disabled={workspaceEditHistory.historyPosition === 0}
          onClick={onRevertChange}
        >
          {'Revert'}
        </Button>
        <Button
          className={`w-[210px] text-[15px] ${
            isAlpha ? QUICK_START_TOUR_STEP_NAMES.SaveSymbol : isBeta ? QUICK_START_TOUR_STEP_NAMES.SaveSymbolBetta : ''
          }`}
          onClick={() => {
            onCloseModal && onCloseModal();

            if (isAlpha) {
              setCurrentStep(QUICK_START_TOUR_STEP_COUNT.AddSymbolBetta);
              return;
            }

            if (isOpenQuickStart) {
              setIsOpen(false);
              onToggle(true);
              setTimeout(() => {
                onToggle(false);
              }, CONFETTI_DURATION + 2000);
            }
          }}
        >
          {'Accept'}
        </Button>
      </div>
    </>
  );
};

import { EnjcValueTreeNode } from '../../enjc-symbol-value-tree';

export const findValueTreeRootNode = (nodes: ReadonlyArray<EnjcValueTreeNode>): EnjcValueTreeNode | undefined => {
  if (nodes.length === 0) {
    return undefined;
  }

  const args = nodes.flatMap((node) => node.funcArgs.map((a) => a.key));
  const rootNodes = nodes.filter((node) => !args.includes(node.key));

  if (rootNodes.length !== 1) {
    throw new Error('Invalid ValueTree (multiple roots)');
  }
  return rootNodes[0];
};

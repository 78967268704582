import { ValueTreeNodePosition } from '../model';
import { isTreeNodeFunction } from '../../tree-node';
import { getNodeOperator } from '../../../enjc-value-operators';
import { UValueTreePosition } from '../types';
import { getNodeByKeyNVTV, IValueTreeViewContext } from '../../../enjc-value-view-ctx';
import { findNodePositionNVTV } from './findNodePositionNVTV';
import { findNodePositionChildNeighbourNVTV } from './findNodePositionChildNeighbourNVTV';
import { findNodePositionParentNeighbourNVTV } from './findNodePositionParentNeighbourNVTV';

export const findNodePositionNeighbourNVTV = (
  vtvCtx: IValueTreeViewContext,
  position: ValueTreeNodePosition,
  moveForward: boolean,
): UValueTreePosition | undefined => {
  return position.atEnd === moveForward
    ? findNodePositionParentNeighbourInternalNVTV(vtvCtx, position, moveForward)
    : findNodePositionChildNeighbourNVTV(vtvCtx, position.nodeKey, moveForward);
};

export const findNodePositionParentNeighbourInternalNVTV = (
  vtvCtx: IValueTreeViewContext,
  position: ValueTreeNodePosition,
  moveForward: boolean,
): UValueTreePosition | undefined => {
  const positionNode = getNodeByKeyNVTV(vtvCtx, position.nodeKey);
  if (!positionNode) {
    // TODO: review and report error
    return undefined;
  }
  if (isTreeNodeFunction(positionNode) && getNodeOperator(positionNode)) {
    return findNodePositionNVTV(
      vtvCtx,
      moveForward ? positionNode.funcArgs[positionNode.funcArgs.length - 1].key : positionNode.funcArgs[0].key,
      moveForward,
    );
  } else {
    return findNodePositionParentNeighbourNVTV(vtvCtx, position.nodeKey, moveForward);
  }
};

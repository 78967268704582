import { Button, Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from 'src/shadcn';
import React from 'react';
import { SideBarContext } from 'src/context/SideBarContext';
import Autoplay from 'embla-carousel-autoplay';

declare global {
  interface Window {
    YT: any;
    onYouTubeIframeAPIReady: () => void;
  }
}

const AppWelcomeF = (): React.ReactElement => {
  const { isShow } = React.useContext(SideBarContext);
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const playerRef = React.useRef<any>(null);

  const loadYouTubeAPI = () => {
    const script = document.createElement('script');
    script.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(script);
  };

  const handleCarouselPrevious = React.useCallback(() => {
    if (playerRef.current) {
      playerRef.current.pauseVideo();
    }
  }, []);

  React.useEffect(() => {
    loadYouTubeAPI();

    window.onYouTubeIframeAPIReady = () => {
      if (iframeRef.current) {
        playerRef.current = new window.YT.Player(iframeRef.current, {
          events: {
            onReady: () => console.log('YouTube Player is ready'),
          },
        });
      }
    };
  }, []);

  return (
    <Carousel
      plugins={[
        Autoplay({
          delay: 5000,
          stopOnInteraction: false,
          stopOnMouseEnter: true,
        }),
      ]}
      className="h-[calc(100vh-35px)] w-[calc(100vw-50px)]"
      onChange={() => alert('change')}
    >
      <CarouselContent className="pt-28">
        <CarouselItem className="pl-32">
          <div className="flex w-full flex-col justify-between space-y-0">
            <div className="hidden md:block">
              <h2 className="pb-6 text-2xl font-bold text-black">1. Welcome Message</h2>
              <p className="text-lg text-black">Dear user,</p>
              <p className="w-[600px] pt-8 text-lg text-black">
                We would appreciate your support and feedback on our product.
              </p>
              <p className="w-[600px] pt-8 text-lg text-black">
                By visiting our Canny Feedback platform and actively participating in discussions, voting on feature
                suggestions, or sharing your thoughts, you are playing a crucial role in shaping the future of our
                product. Your input is not just valuable, it's essential.
              </p>
              <p className="w-[600px] pt-8 text-lg text-black">Let's make Enjicalc better together.</p>
              <p className="w-[600px] pt-8 text-lg text-black">Best regards,</p>
              <p className="w-[600px] text-lg text-black">Team Enji.io</p>
              <div className="flex gap-8 py-12">
                <a
                  href="https://enjicalc.canny.io/feedback"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block"
                >
                  <Button className="h-[50px] w-[300px] text-[15px]" variant="outline">
                    Submit Feedback
                  </Button>
                </a>
                <a
                  href="https://docs.enji.io/documentation"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block"
                >
                  <Button className="h-[50px] w-[300px] text-[15px]" variant="outline">
                    Documentation
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </CarouselItem>

        <CarouselItem className="pl-32">
          <div className="flex w-full flex-col justify-between space-y-0">
            <div className="hidden md:block">
              <h2 className="pb-6 text-2xl font-bold text-black">2. Saving Your Progress</h2>
              <p className="text-lg text-black">Dear user,</p>
              <p className="w-[600px] pt-8 text-lg text-black">
                Although we aim to make the Enjicalc experience frictionless, we want to get your attention around
                saving capabilities.
              </p>
              <p className="w-[600px] pt-8 text-lg text-black">
                To prioritise sheet-level undo/redo capabilities, we had to move saving responsibilities to you. The
                save feature removes modification history and does not allow you to undo your progress afterwards. To
                ensure a smooth experience, press the save button in the top left corner only when you are sure of the
                modifications you have made.
              </p>
              <p className="w-[600px] pt-8 text-lg text-black">
                We will keep you updated on all future modifications to fix this experience.
              </p>
              <p className="w-[600px] pt-8 text-lg text-black">Best regards,</p>
              <p className="w-[600px] text-lg text-black">Team Enji.io</p>
            </div>
          </div>
        </CarouselItem>

        <CarouselItem className="pl-32">
          <h2 className="pb-6 text-2xl font-bold text-black">3. Getting Started Video</h2>
          <div>
            <iframe
              ref={iframeRef}
              className={`h-[calc(100vh-400px)] ${isShow ? 'w-[calc(100vw-600px)]' : 'w-[calc(100vw-300px)]'} rounded-sm border-2 border-gray-300`}
              src="https://www.youtube.com/embed/hq9SEsf0pRc?enablejsapi=1" // Enable JS API
            ></iframe>
          </div>
        </CarouselItem>

        <CarouselItem className="pl-32">
          <div className="flex w-full flex-col justify-between space-y-0">
            <div className="hidden md:block">
              <h2 className="pb-6 text-2xl font-bold text-black">4. Example Workspace</h2>
              <p className="text-lg text-black">Dear user,</p>
              <p className="w-[600px] pt-8 text-lg text-black">
                On our dashboard, there is an Example Workspace that contains Crack Width calculations according to
                Eurocode 2.
              </p>
              <p className="w-[600px] pt-8 text-lg text-black">
                A Chartered Structural Engineer has reviewed the following calculations according to BS EN
                1992-1-1:2004+A1:2014. Errors are still possible, always do your own checks before issuing.
              </p>
              <p className="w-[600px] pt-8 text-lg text-black">
                If you have any questions, please email to viktar@enji.io.
              </p>
              <p className="w-[600px] pt-8 text-lg text-black">Best regards,</p>
              <p className="w-[600px] text-lg text-black">Team Enji.io</p>
            </div>
          </div>
        </CarouselItem>
      </CarouselContent>

      <div className="absolute left-[160px] top-4">
        <CarouselPrevious
          onClickCustom={handleCarouselPrevious}
          className="border-primary-green bg-primary-green text-primary-offwhite hover:bg-transparent hover:text-primary-green"
        />
        <CarouselNext className="border-primary-green bg-primary-green text-primary-offwhite hover:bg-transparent hover:text-primary-green" />
      </div>
    </Carousel>
  );
};

export const AppWelcome = React.memo(AppWelcomeF);

import React, { ReactElement } from 'react';
import {
  EnjicalcSheet,
  EnjicalcWorkspace,
  getSheetAuxMeta,
  getWorkspaceItemTitle,
  EnjcWorkspaceDTO,
} from '../../../libenjc/enjc-workspace';
import { calculateStringDeltaEntry } from '../../../libenjc/enjc-delta';
import { SheetHeaderView } from './SheetHeaderView';
import { EnjcWorkspaceSectionDelta, WorkspaceEditHistoryEntry } from '../../../libenjc/enjc-workspace-editing';
import { mkWorkspaceEditHistoryEntryDeprecated } from '../../../libenjc/enjc-workspace-editing/utils';
import {
  AUX_META_WORKSPACE_SHEET_APPROVED_DATE,
  AUX_META_WORKSPACE_SHEET_APPROVED_NAME,
  AUX_META_WORKSPACE_SHEET_CHECKED_DATE,
  AUX_META_WORKSPACE_SHEET_CHECKED_NAME,
  AUX_META_WORKSPACE_SHEET_SUBMISSION_DATE,
} from '../../../libenjc/enjc-workspace/constants';

interface IProps {
  readonly workspace: EnjcWorkspaceDTO;
  readonly sheet: EnjicalcSheet;
  readonly performEdit: (editEntry: WorkspaceEditHistoryEntry) => void;
}

export const SheetHeader = ({ workspace, sheet, performEdit }: IProps): ReactElement => {
  const updateSheet = React.useCallback(
    (title: string, sectionDelta: EnjcWorkspaceSectionDelta) => {
      const hEntry = mkWorkspaceEditHistoryEntryDeprecated(title, { section: [sectionDelta] });
      performEdit(hEntry);
    },
    [performEdit],
  );

  const sheetAuxMeta = React.useMemo(() => {
    return getSheetAuxMeta(sheet);
  }, [sheet]);

  const setSubmissionDate = React.useCallback(
    (value: Date | undefined) => {
      const valueDelta = calculateStringDeltaEntry(sheetAuxMeta.checkedDate, value?.valueOf()?.toString() ?? '');
      valueDelta &&
        updateSheet('Edit sheet submission date', {
          id: sheet.id,
          auxMeta: [{ name: AUX_META_WORKSPACE_SHEET_SUBMISSION_DATE, value: [valueDelta] }],
        });
    },
    [sheet.id, sheetAuxMeta.checkedDate, updateSheet],
  );

  const setSheetCheckedName = React.useCallback(
    (value: string) => {
      const valueDelta = calculateStringDeltaEntry(sheetAuxMeta.checkedName, value);
      valueDelta &&
        updateSheet('Edit sheet checked name', {
          id: sheet.id,
          auxMeta: [{ name: AUX_META_WORKSPACE_SHEET_CHECKED_NAME, value: [valueDelta] }],
        });
    },
    [sheet.id, sheetAuxMeta.checkedName, updateSheet],
  );

  const setSheetApprovedName = React.useCallback(
    (value: string) => {
      const valueDelta = calculateStringDeltaEntry(sheetAuxMeta.approvedName, value);
      valueDelta &&
        updateSheet('Edit sheet approved name', {
          id: sheet.id,
          auxMeta: [{ name: AUX_META_WORKSPACE_SHEET_APPROVED_NAME, value: [valueDelta] }],
        });
    },
    [sheet.id, sheetAuxMeta.approvedName, updateSheet],
  );

  const setCheckedDate = React.useCallback(
    (value: Date | undefined) => {
      const valueDelta = calculateStringDeltaEntry(sheetAuxMeta.checkedDate, value?.valueOf()?.toString() ?? '');
      valueDelta &&
        updateSheet('Edit sheet checked date', {
          id: sheet.id,
          auxMeta: [{ name: AUX_META_WORKSPACE_SHEET_CHECKED_DATE, value: [valueDelta] }],
        });
    },
    [sheet.id, sheetAuxMeta.checkedDate, updateSheet],
  );

  const setApprovedDate = React.useCallback(
    (value: Date | undefined) => {
      const valueDelta = calculateStringDeltaEntry(sheetAuxMeta.approvedDate, value?.valueOf()?.toString() ?? '');
      valueDelta &&
        updateSheet('Edit sheet approved date', {
          id: sheet.id,
          auxMeta: [{ name: AUX_META_WORKSPACE_SHEET_APPROVED_DATE, value: [valueDelta] }],
        });
    },
    [sheet.id, sheetAuxMeta.approvedDate, updateSheet],
  );

  return (
    <SheetHeaderView
      workspaceTitle={getWorkspaceItemTitle(workspace)}
      sheet={sheet}
      setSubmissionDate={setSubmissionDate}
      setSheetCheckedName={setSheetCheckedName}
      setSheetApprovedName={setSheetApprovedName}
      setCheckedDate={setCheckedDate}
      setApprovedDate={setApprovedDate}
    />
  );
};

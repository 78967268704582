import { EnjcWorkspaceSectionDelta, ItemCreateEntry } from '../model';
import { IEnjcWorkspaceLocalHistory } from '../interfaces';
import { TSectionId } from '../../../generated/graphql/types';
import { getEnjcSection } from '../../enjc-workspace';
import { hCreateSection } from './hCreateSection';

export const hCreateSectionIn = (wlh: IEnjcWorkspaceLocalHistory, sectionId: TSectionId): ItemCreateEntry => {
  const existingSection = getEnjcSection(wlh.currentWorkspace, sectionId);

  const hcr = hCreateSection(wlh);

  const sectionDelta: EnjcWorkspaceSectionDelta = {
    id: existingSection.id,
    items: [{ slicePosition: existingSection.items.length, sliceAfter: [hcr.itemId] }],
  };

  return {
    ...hcr,
    historyEntry: {
      ...hcr.historyEntry,
      delta: {
        ...hcr.historyEntry.delta,
        section: [...(hcr.historyEntry.delta.section ?? []), sectionDelta],
      },
    },
  };
};

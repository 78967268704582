import { EnjcSymbolValueTree } from '../tree-core';
import { TValueTreeNodeKey, EnjcValueTreeNode } from '../tree-node';
import { ValueTreeNodeKeyNotFoundError } from '../tree-errors';
import { EnjcValueTreeFragment } from '../../../generated/graphql/operations';

export const getTreeNodeByKey = (valueTree: EnjcValueTreeFragment, nodeKey: TValueTreeNodeKey): EnjcValueTreeNode => {
  const node: EnjcValueTreeNode | undefined = valueTree.nodes.find((n) => n.key === nodeKey);

  if (node === undefined) {
    throw new ValueTreeNodeKeyNotFoundError(nodeKey);
  }

  return node;
};

export const getTreeNodeByKeyBackup = (treeNodes: EnjcSymbolValueTree, nodeKey: TValueTreeNodeKey) => {
  const node = treeNodes.nodes.get(nodeKey);

  if (node === undefined) {
    throw new ValueTreeNodeKeyNotFoundError(nodeKey);
  }

  return node;
};

import React from 'react';
import { EnjcWorkspaceDTO, getWorkspaceItemsVersionCounter, TSymbolId } from '../../enjc-workspace';
import {
  applyWorkspaceDelta,
  EnjcWorkspaceSectionDelta,
  EnjcWorkspaceSelfDelta,
  EnjcWorkspaceSymbolDelta,
  invertWorkspaceDelta,
  WorkspaceEditActionMeta,
  WorkspaceEditHistory,
  WorkspaceEditHistoryEntry,
} from '../../enjc-workspace-editing';
import { IEnjicalcWorkspaceEditActions, IEnjicalcWorkspaceEditHistoryContext } from '../../enjc-context';
import { NotImplementedError } from '../../../errors';
import { convertWorkspaceEditHistoryEntryToDiffInput, FMutWorkspaceUpdate } from '../../enjc-client';
import { mkWorkspaceEditHistoryEntry } from '../../enjc-workspace-editing/utils';
import {
  EnjcWorkspaceItemByIdAuxMetaAction,
  EnjcWorkspaceItemByIdVisibilityAction,
  EnjcWorkspaceSymbolTextFieldAction,
  EnjcWorkspaceSymbolValueTreeAction,
} from '../../enjc-workspace-editing/types';
import { EnjcWorkspaceSectionItemsAction } from '../../enjc-workspace-editing/types/IEnjicalcWorkspaceEditActions';
import { hCreateSection } from '../../enjc-workspace-editing/actions/hCreateSection';
import { ItemCreateEntry } from '../../enjc-workspace-editing/model';
import { EnjcKeyListDelta, IEnjcAtomDelta } from '../../enjc-delta';
import {
  AUX_META_WORKSPACE_SYMBOL_COMMENT,
  AUX_META_WORKSPACE_SYMBOL_DESCRIPTION,
  AUX_META_WORKSPACE_SYMBOL_UNIT,
} from '../../enjc-workspace/constants';
import { WORKSPACE_CREATE_ID_PREFIX } from '../../enjc-client/constants';
import { countGroupTagHistorySteps } from './countGroupTagHistorySteps';

export const updateKeyListValue = <T extends { id: string }>(
  keyListValue: ReadonlyArray<T>,
  keyMapper: (idValue: string) => string,
): ReadonlyArray<T> => {
  return keyListValue.map((kli) => ({ ...kli, id: keyMapper(kli.id) }));
};

export const updateKeyItemValue = <T extends { id: string }>(
  keyItemValue: T,
  keyMapper: (idValue: string) => string,
): T => {
  return { ...keyItemValue, id: keyMapper(keyItemValue.id) };
};

export const updateKeyListDeltaIds = (
  keyListDelta: EnjcKeyListDelta,
  keyMapper: (idValue: string) => string,
): EnjcKeyListDelta => {
  return keyListDelta.map((kldi) => ({
    ...kldi,
    sliceBefore: kldi.sliceBefore?.map((sliceKey) => keyMapper(sliceKey)),
    sliceAfter: kldi.sliceAfter?.map((sliceKey) => keyMapper(sliceKey)),
  }));
};

export const updateKeyAtomDelta = (
  keyAtomDelta: IEnjcAtomDelta<TSymbolId | undefined>,
  keyMapper: (idValue: string) => string,
): IEnjcAtomDelta<TSymbolId | undefined> => {
  return {
    ...keyAtomDelta,
    atomBefore: keyAtomDelta.atomBefore && keyMapper(keyAtomDelta.atomBefore),
    atomAfter: keyAtomDelta.atomAfter && keyMapper(keyAtomDelta.atomAfter),
  };
};

export const useWorkspaceEditHistoryConstructor = (
  workspace: EnjcWorkspaceDTO,
  updateWorkspace: FMutWorkspaceUpdate,
): IEnjicalcWorkspaceEditHistoryContext => {
  const [currentWorkspace, setCurrentWorkspace] = React.useState(workspace);
  const [historyEntries, setHistoryEntries] = React.useState<WorkspaceEditHistoryEntry[]>([]);
  const [historyPosition, setHistoryPosition] = React.useState(0);
  const [localIdCounter, setLocalIdCounter] = React.useState(1);

  const performEdit = React.useCallback(
    (editEntry: WorkspaceEditHistoryEntry): Promise<EnjcWorkspaceDTO> => {
      // console.debug('Performing EnjcWorkspace History Edit', editEntry);
      // Calculate undo input
      // * Clear remaining redo items (if any)
      // * Add entry to the end of `editHistory`
      // * Apply [redo] input to workspace
      // * Move position pointer forward
      setHistoryEntries([...historyEntries.slice(0, historyPosition), editEntry]);
      console.debug('applyWorkspaceDelta here');
      const updatedWorkspace = applyWorkspaceDelta(currentWorkspace, editEntry.delta);
      console.debug('Result of EnjcWorkspace History Edit', updatedWorkspace);
      setCurrentWorkspace(updatedWorkspace);
      setHistoryPosition(historyPosition + 1);
      //performRedo();
      return Promise.resolve(updatedWorkspace);
    },
    [currentWorkspace, historyEntries, historyPosition],
  );

  const performUndoOne = React.useCallback(() => {
    if (historyPosition > 0) {
      // Apply inverted delta
      const updatedWorkspace = applyWorkspaceDelta(
        currentWorkspace,
        invertWorkspaceDelta(historyEntries[historyPosition - 1].delta),
      );
      setCurrentWorkspace(updatedWorkspace);
      // Move position pointer backward
      setHistoryPosition(historyPosition - 1);
    }
  }, [currentWorkspace, historyEntries, historyPosition]);

  const performRedoOne = React.useCallback(() => {
    if (historyPosition < historyEntries.length) {
      // Apply redo input
      const updatedWorkspace = applyWorkspaceDelta(currentWorkspace, historyEntries[historyPosition].delta);
      setCurrentWorkspace(updatedWorkspace);
      // Move position pointer forward
      setHistoryPosition(historyPosition + 1);
    }
  }, [currentWorkspace, historyEntries, historyPosition]);

  const performUndoMany = React.useCallback(
    (steps: number) => {
      console.debug(
        `Performing EnjcWorkspace Edit History Undo Many with ${steps} steps, current position is ${historyPosition}`,
      );
      if (historyPosition >= steps) {
        // Apply undo input
        const updatedWorkspace = historyEntries
          .slice(historyPosition - steps, historyPosition)
          .reduceRight((acc, item) => applyWorkspaceDelta(acc, invertWorkspaceDelta(item.delta)), currentWorkspace);
        setCurrentWorkspace(updatedWorkspace);
        // Move position pointer backward
        setHistoryPosition(historyPosition - steps);
      } else {
        console.error(
          `EnjcWorkspace Edit History Undo Many requested to many steps (${steps}), current position is ${historyPosition}`,
        );
      }
    },
    [currentWorkspace, historyEntries, historyPosition],
  );

  const performRedoMany = React.useCallback(
    (steps: number) => {
      console.debug(
        `Performing EnjcWorkspace Edit History Redo Many with ${steps} steps, current position is ${historyPosition}`,
      );
      if (historyPosition + steps <= historyEntries.length) {
        // Apply redo input
        const updatedWorkspace = historyEntries
          .slice(historyPosition, historyPosition + steps)
          .reduce((acc, item) => applyWorkspaceDelta(acc, item.delta), currentWorkspace);
        setCurrentWorkspace(updatedWorkspace);
        // Move position pointer forward
        setHistoryPosition(historyPosition + steps);
      } else {
        console.error(
          `EnjcWorkspace Edit History Redo Many requested to many steps (${steps}), current position is ${historyPosition}`,
        );
      }
    },
    [currentWorkspace, historyEntries, historyPosition],
  );

  const performUndo = React.useCallback(
    (groupTag?: string) => {
      console.debug(
        `Performing EnjcWorkspace Edit History Undo, current position is ${historyPosition}, groupTag: '${groupTag}'`,
      );
      // Count undo steps
      const undoSteps = countGroupTagHistorySteps(historyEntries, historyPosition, groupTag, false);
      console.log(`Gog ${undoSteps} undo steps`);
      performUndoMany(undoSteps);
    },
    [historyEntries, historyPosition, performUndoMany],
  );

  const performRedo = React.useCallback(
    (groupTag?: string) => {
      console.debug(
        `Performing EnjcWorkspace Edit History Redo, current position is ${historyPosition}, groupTag: '${groupTag}'`,
      );

      // Count redo steps
      const redoSteps = countGroupTagHistorySteps(historyEntries, historyPosition, groupTag, true);
      console.log(`Gog ${redoSteps} redo steps`);
      performRedoMany(redoSteps);
    },
    [historyEntries, historyPosition, performRedoMany],
  );

  const generateLocalCreateId = React.useCallback(() => {
    setLocalIdCounter(localIdCounter + 1);
    return `${WORKSPACE_CREATE_ID_PREFIX}${localIdCounter}`;
  }, [localIdCounter]);

  const performReset = React.useCallback(
    (keepRedoHistory: boolean) => {
      setHistoryEntries(keepRedoHistory ? historyEntries.slice(historyPosition) : []);
      setHistoryPosition(0);
    },
    [historyEntries, historyPosition],
  );

  const workspaceEditHistory = React.useMemo<WorkspaceEditHistory>(() => {
    return {
      currentState: currentWorkspace,
      historyEntries,
      historyPosition,
      localIdCounter,
    };
  }, [currentWorkspace, historyEntries, historyPosition, localIdCounter]);

  // FIXME: implement missing parts
  // throw new NotImplementedError('Fix item creation (track multiple local IDs)');
  const performSave = React.useCallback(async () => {
    console.debug(
      `Saving first ${workspaceEditHistory.historyPosition} out of ${workspaceEditHistory.historyEntries.length} workspace edit history entries`,
    );

    const entriesToSave = workspaceEditHistory.historyEntries
      .slice(0, workspaceEditHistory.historyPosition)
      .map((editHistoryEntry) => convertWorkspaceEditHistoryEntryToDiffInput(editHistoryEntry));
    console.debug(`Converted edit history entries to GraphQL diff inputs`, entriesToSave);

    const updateResult = await updateWorkspace(workspace.id, getWorkspaceItemsVersionCounter(workspace), {
      items: entriesToSave,
    });
    console.debug('Received workspace update mutation result', updateResult);

    const keyMapper = (keyValue: string): string => {
      return keyValue;
    };

    // Update local create IDs with IDs returned by server
    const nextCurrentState: EnjcWorkspaceDTO = {
      ...workspaceEditHistory.currentState,
      sheetBookmarks: updateKeyListValue(workspaceEditHistory.currentState.sheetBookmarks, keyMapper),
      sections: workspaceEditHistory.currentState.sections.map((iSe) => ({
        ...iSe,
        items: updateKeyListValue(iSe.items, keyMapper),
      })),
      symbols: workspaceEditHistory.currentState.symbols.map((iSy) => ({
        ...iSy,
        valueTree: {
          ...iSy.valueTree,
          nodes: iSy.valueTree.nodes.map((vtn) => ({
            ...vtn,
            symbol: vtn.symbol && updateKeyItemValue(vtn.symbol, keyMapper),
          })),
        },
      })),
    };

    const nextHistoryEntries = workspaceEditHistory.historyEntries.map((he) => {
      return {
        ...he,
        delta: {
          ...he.delta,
          workspaceSelf: he.delta.workspaceSelf?.map((heItem) => ({
            ...heItem,
            sheetBookmarks: heItem.sheetBookmarks && updateKeyListDeltaIds(heItem.sheetBookmarks, keyMapper),
          })),
          section: he.delta.section?.map((heItem) => ({
            ...heItem,
            items: heItem.items && updateKeyListDeltaIds(heItem.items, keyMapper),
          })),
          symbol: he.delta.symbol?.map((heItem) => ({
            ...heItem,
            valueTree: {
              ...heItem.valueTree,
              nodes: heItem.valueTree?.nodes?.map((vtnd) => ({
                ...vtnd,
                symbols: vtnd.symbol && updateKeyAtomDelta(vtnd.symbol, keyMapper),
              })),
            },
          })),
        },
      };
    });
    setCurrentWorkspace(nextCurrentState);
    setHistoryEntries(nextHistoryEntries);

    performReset(true);
  }, [performReset, updateWorkspace, workspace, workspaceEditHistory]);

  const updateWorkspaceSelf = React.useCallback(
    (workspaceSelfDelta: EnjcWorkspaceSelfDelta, actionMeta: WorkspaceEditActionMeta) => {
      const hEntry = mkWorkspaceEditHistoryEntry({ workspaceSelf: [workspaceSelfDelta] }, actionMeta);
      performEdit(hEntry);
    },
    [performEdit],
  );

  const createSection = React.useCallback(
    (actionMeta: WorkspaceEditActionMeta): ItemCreateEntry => {
      return hCreateSection({
        performEdit,
        generateLocalCreateId,
        currentWorkspace: workspaceEditHistory.currentState,
      });
    },
    [generateLocalCreateId, performEdit, workspaceEditHistory.currentState],
  );

  const createSymbol = React.useCallback((actionMeta: WorkspaceEditActionMeta): ItemCreateEntry => {
    throw new NotImplementedError('');
  }, []);

  const updateSection = React.useCallback(
    (sectionDelta: EnjcWorkspaceSectionDelta, actionMeta: WorkspaceEditActionMeta) => {
      const hEntry = mkWorkspaceEditHistoryEntry({ section: [sectionDelta] }, actionMeta);
      performEdit(hEntry);
    },
    [performEdit],
  );

  const updateSymbol = React.useCallback(
    (symbolDelta: EnjcWorkspaceSymbolDelta, actionMeta: WorkspaceEditActionMeta) => {
      const hEntry = mkWorkspaceEditHistoryEntry({ symbol: [symbolDelta] }, actionMeta);
      performEdit(hEntry);
    },
    [performEdit],
  );

  const performEditAction: IEnjicalcWorkspaceEditActions = React.useMemo(() => {
    // const updateWorkspaceItemAuxMeta: EnjcWorkspaceItemAuxMetaAction = (workspaceItem, delta, actionMeta) => {
    //   if (isEnjcSymbol(workspaceItem))
    // }
    const updateSectionVisibility: EnjcWorkspaceItemByIdVisibilityAction = (itemId, delta, actionMeta) =>
      updateSection({ id: itemId, visibility: delta }, actionMeta);
    const updateSymbolVisibility: EnjcWorkspaceItemByIdVisibilityAction = (itemId, delta, actionMeta) =>
      updateSymbol({ id: itemId, visibility: delta }, actionMeta);
    const updateSectionAuxMeta: EnjcWorkspaceItemByIdAuxMetaAction = (sectionId, auxMetaDelta, actionMeta) =>
      updateSection(
        {
          id: sectionId,
          auxMeta: auxMetaDelta,
        },
        actionMeta,
      );
    const updateSectionItems: EnjcWorkspaceSectionItemsAction = (sectionId, delta, actionMeta) =>
      updateSection(
        {
          id: sectionId,
          items: delta,
        },
        actionMeta,
      );
    const updateSymbolAuxMeta: EnjcWorkspaceItemByIdAuxMetaAction = (symbolId, auxMetaDelta, actionMeta) =>
      updateSymbol(
        {
          id: symbolId,
          auxMeta: auxMetaDelta,
        },
        actionMeta,
      );

    const updateSymbolGlyph: EnjcWorkspaceSymbolTextFieldAction = (symbolId, delta, actionMeta) =>
      updateSymbol(
        {
          id: symbolId,
          glyph: [delta],
        },
        actionMeta,
      );

    const updateSymbolValueTree: EnjcWorkspaceSymbolValueTreeAction = (symbolId, delta, actionMeta) =>
      updateSymbol({ id: symbolId, valueTree: delta }, actionMeta);

    const updateSymbolUnit: EnjcWorkspaceSymbolTextFieldAction = (symbolId, delta, actionMeta) =>
      updateSymbolAuxMeta(symbolId, [{ name: AUX_META_WORKSPACE_SYMBOL_UNIT, value: [delta] }], actionMeta);

    const updateSymbolDescription: EnjcWorkspaceSymbolTextFieldAction = (symbolId, delta, actionMeta) =>
      updateSymbolAuxMeta(symbolId, [{ name: AUX_META_WORKSPACE_SYMBOL_DESCRIPTION, value: [delta] }], actionMeta);

    const updateSymbolComment: EnjcWorkspaceSymbolTextFieldAction = (symbolId, delta, actionMeta) =>
      updateSymbolAuxMeta(symbolId, [{ name: AUX_META_WORKSPACE_SYMBOL_COMMENT, value: [delta] }], actionMeta);

    return {
      updateWorkspaceSelf,
      createSection,
      createSymbol,
      updateSection,
      updateSymbol,
      updateSectionVisibility,
      updateSectionAuxMeta,
      updateSectionItems,
      updateSymbolVisibility,
      updateSymbolAuxMeta,
      updateSymbolGlyph,
      updateSymbolValueTree,
      updateSymbolUnit,
      updateSymbolDescription,
      updateSymbolComment,
    };
  }, [updateWorkspaceSelf, createSection, createSymbol, updateSection, updateSymbol]);

  return {
    currentWorkspace: workspaceEditHistory.currentState,
    workspaceEditHistory,
    generateLocalCreateId,
    performEdit,
    performUndo,
    performUndoMany,
    performRedo,
    performReset,
    performSave,
    performEditAction,
  };
};

import React from 'react';
import { Alert, AlertDescription, AlertTitle } from 'src/shadcn';
import { BiSolidErrorCircle } from 'react-icons/bi';

interface IProps {
  title?: string;
  description?: string;
}

export const ErrorMessage: React.FC<IProps> = ({ title, description }) => {
  console.error(`${title || 'ErrorMessage'}: ${description}`);

  return (
    <Alert className="h-fit bg-red-400">
      <BiSolidErrorCircle size={20} />
      <div>
        {title && <AlertTitle>{title}</AlertTitle>}
        {description && <AlertDescription>{description}</AlertDescription>}
        {!description && !title && <AlertDescription>Error</AlertDescription>}
      </div>
    </Alert>
  );
};

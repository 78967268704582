import React, { ChangeEvent } from 'react';
import { EnjcStringDeltaEntry, calculateStringDeltaEntry } from '../../../libenjc/enjc-delta';

interface IProps {
  readonly value: string;
  readonly onValueDelta: (delta: EnjcStringDeltaEntry) => void;
}

export const SymbolTextAreaDeltaInput = ({ value, onValueDelta }: IProps) => {
  const handleChange = React.useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const enjcStringDelta = calculateStringDeltaEntry(value, event.target.value);
      enjcStringDelta && onValueDelta(enjcStringDelta);
    },
    [value, onValueDelta],
  );

  return (
    <div className="flex">
      <textarea className="min-h-24 w-[700px] rounded-sm border p-2 text-base" onChange={handleChange} value={value} />
    </div>
  );
};

export const AUX_META_WORKSPACE_ITEM_TITLE = 'enjcWorkspaceItemTitle';
export const AUX_META_WORKSPACE_ITEM_DESCRIPTION = 'enjcWorkspaceItemDescription';

export const AUX_META_WORKSPACE_FUNC_DESCRIPTION = 'enjcWorkspaceFuncDescription';

export const AUX_META_WORKSPACE_SYMBOL_UNIT = 'enjcWorkspaceSymbolUnit';
export const AUX_META_WORKSPACE_SYMBOL_DESCRIPTION = 'enjcWorkspaceSymbolDescription';
export const AUX_META_WORKSPACE_SYMBOL_COMMENT = 'enjcWorkspaceSymbolComment';

export const AUX_META_WORKSPACE_SHEET_SUBMISSION_DATE = 'enjcWorkspaceSheetSubmissionDate';
export const AUX_META_WORKSPACE_SHEET_APPROVED_NAME = 'enjcWorkspaceSheetApprovedName';
export const AUX_META_WORKSPACE_SHEET_APPROVED_DATE = 'enjcWorkspaceSheetApprovedDate';
export const AUX_META_WORKSPACE_SHEET_CHECKED_NAME = 'enjcWorkspaceSheetCheckedName';
export const AUX_META_WORKSPACE_SHEET_CHECKED_DATE = 'enjcWorkspaceSheetCheckedDate';

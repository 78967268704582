import { Button, ButtonProps } from 'src/shadcn';

export const SidebarButton = (props: ButtonProps) => (
  <Button
    className="w-full justify-start bg-none text-[16px] font-normal disabled:cursor-not-allowed"
    variant="ghost"
    size="xs"
    {...props}
  />
);

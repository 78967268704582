import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'src/shadcn';
import React from 'react';
import { FiSettings } from 'react-icons/fi';
import { MdSettings, MdStyle } from 'react-icons/md';

const SettingsMenuF = () => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" aria-label="settings">
          <FiSettings size={25} color="gray" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem disabled>
          <MdSettings />
          Settings
        </DropdownMenuItem>
        <DropdownMenuItem disabled>
          <MdStyle />
          Themes
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const SettingsMenu = React.memo(SettingsMenuF);

import { EnjicalcWorkspace, findWorkspaceItemContainer, TSymbolId, EnjcWorkspaceDTO } from '../../enjc-workspace';
import { EnjcWorkspaceSectionDelta } from '../model';

export const changeMoveSymbolBeforeAfter = (
  workspace: EnjcWorkspaceDTO,
  symbolToMoveId: TSymbolId,
  symbolBeforeAfterId: TSymbolId,
  addAfter: boolean,
): ReadonlyArray<EnjcWorkspaceSectionDelta> => {
  if (symbolToMoveId === symbolBeforeAfterId) {
    return [];
  }

  const containerSrcInfo = findWorkspaceItemContainer(workspace, symbolToMoveId);
  const containerDstInfo = findWorkspaceItemContainer(workspace, symbolBeforeAfterId);
  if (!containerSrcInfo || !containerDstInfo) {
    // TODO: report error
    return [];
  }

  return [
    {
      id: containerSrcInfo.container.id,
      items: [{ slicePosition: containerSrcInfo.index, sliceBefore: [symbolToMoveId] }],
    },
    {
      id: containerDstInfo.container.id,
      items: [
        {
          slicePosition:
            containerDstInfo.index +
            (containerSrcInfo.container.id === containerDstInfo.container.id &&
            containerSrcInfo.index < containerDstInfo.index
              ? -1
              : 0) +
            (addAfter ? 1 : 0),
          sliceAfter: [symbolToMoveId],
        },
      ],
    },
  ];
};

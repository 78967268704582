import { EnjcWorkspaceDelta, WorkspaceEditActionMeta, WorkspaceEditHistoryEntry } from '../model';

export const mkWorkspaceEditHistoryEntry = (
  delta: EnjcWorkspaceDelta,
  actionMeta: WorkspaceEditActionMeta,
): WorkspaceEditHistoryEntry => {
  return {
    timestamp: Date.now(),
    delta,
    actionMeta,
  };
};

import { EnjcWorkspaceItemVisibility, EnjicalcWorkspaceItemsRefs } from '../../enjc-workspace';
import { mkAtomDelta } from '../../enjc-delta';
import { WorkspaceEditHistoryEntry } from '../model';
import { mkWorkspaceEditHistoryEntryDeprecated } from '../utils';

export const editSetWorkspaceVisibility = (
  workspace: EnjicalcWorkspaceItemsRefs,
  nextVisibility: EnjcWorkspaceItemVisibility,
): WorkspaceEditHistoryEntry => {
  return mkWorkspaceEditHistoryEntryDeprecated('editSetWorkspaceVisibility', {
    workspaceSelf:
      workspace.visibility === nextVisibility
        ? undefined
        : [
            {
              id: workspace.id,
              visibility: mkAtomDelta(workspace.visibility, nextVisibility),
            },
          ],
  });
};

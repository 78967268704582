import React, { ReactElement } from 'react';

interface IProps {
  index: number;
}

type FComp = (props: IProps) => ReactElement;

export const DocumentItemIndexView = React.memo<FComp>(({ index }) => {
  return (
    <span className="absolute left-[-75px] size-0">
      <div
        className={`noPrint flex w-[40px] flex-row-reverse justify-start border-t border-gray-300 p-1 text-xs text-black/50`}
      >
        {index}
      </div>
    </span>
  );
});

import React, { ReactElement } from 'react';
import { VscFilePdf } from 'react-icons/vsc';
import { QUICK_START_TOUR_STEP_NAMES } from 'src/hooks';
import { Button, ButtonProps } from 'src/shadcn';

type FComp = (props: ButtonProps) => ReactElement;

export const PrintButton = React.memo<FComp>((props) => {
  return (
    <Button className={`text-[13px] font-light`} variant="link" {...props}>
      PRINT TO PDF <VscFilePdf className="ml-2 size-[20px]" />
    </Button>
  );
});

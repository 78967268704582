import { EnjcValueTreeNode } from '../../tree-node';
import { INodeParentInfoWithOperator } from '../../symbol-value-tree-review';
import { getNodeOperator } from '../../../enjc-value-operators';
import { isExpressionOperandLeft } from './isExpressionOperandLeft';

export const checkExpressionOperandImplicitParenthesis = (
  node: EnjcValueTreeNode,
  nodeParentInfo: INodeParentInfoWithOperator,
): boolean => {
  const nodeOperator = getNodeOperator(node);

  return !!(
    nodeOperator &&
    nodeParentInfo.operator &&
    ((isExpressionOperandLeft(nodeParentInfo) && nodeOperator.priority < nodeParentInfo.operator.priority) ||
      (!isExpressionOperandLeft(nodeParentInfo) && nodeOperator.priority <= nodeParentInfo.operator.priority))
  );
};

import './workspace-sheets-document.css';

import React, { ReactElement } from 'react';

import { isNotUndefined } from '../../utils';
import {
  EnjicalcWorkspaceEditHistoryContext,
  useCtxEnjcWorkspaceData,
} from '../../libenjc/enjc-react/enjc-react-context';
import {
  EnjicalcSection,
  getEnjcSectionOrUndefined,
  isWorkspaceItemVisible,
  TSectionId,
} from '../../libenjc/enjc-workspace';
import { SideBarContext } from '../../context/SideBarContext';
import { useEnjcRawWorkspaceMutations } from '../../libenjc/enjc-react/enjc-react-client';
import { hCreateSection } from '../../libenjc/enjc-workspace-editing/actions/hCreateSection';
import { SidebarContainer } from '../sidebar-container';
import { useWorkspaceSheetsView } from './useWorkspaceSheetsView';
import { WorkspaceSheetsSidebarPrimary } from './WorkspaceSheetsSidebarPrimary';
import { WorkspaceSheetsTabs } from './WorkspaceSheetsTabs';
import { useSearchParamsSheetDocumentTabs } from './useSearchParamsSheetDocumentTabs';
import { QUICK_START_TOUR_STEP_COUNT, useQuickStartTour, useShortcuts } from 'src/hooks';
import { useEnjicalcWorkspaceTracker } from './useEnjicalcWorkspaceTracker';
import { AppActivityBar } from 'src/app/app-activity-bar';
import { useToast } from 'src/shadcn';

export const WorkspaceSheets = (): ReactElement => {
  const { toast } = useToast();
  const { workspaceWithMath: remoteWorkspaceData } = useCtxEnjcWorkspaceData();
  const { updateWorkspace } = useEnjcRawWorkspaceMutations();
  const workspaceTracker = useEnjicalcWorkspaceTracker(remoteWorkspaceData, updateWorkspace);
  const { setCurrentStep } = useQuickStartTour();

  const { showHidden } = useWorkspaceSheetsView();
  const { selectedSheetId, openSheetsIds, setSheetTabsState } = useSearchParamsSheetDocumentTabs();

  const { isShow } = React.useContext(SideBarContext);

  // FIXME: implement (use current state from workspace edit history)
  const workspace = workspaceTracker.currentWorkspace;

  const selectedSheet = React.useMemo<EnjicalcSection | undefined>(
    () => (selectedSheetId ? getEnjcSectionOrUndefined(workspace, selectedSheetId) : undefined),
    [selectedSheetId, workspace],
  );

  const openSheets = React.useMemo<ReadonlyArray<EnjicalcSection>>(
    () => openSheetsIds.map((tabSheetId) => getEnjcSectionOrUndefined(workspace, tabSheetId)).filter(isNotUndefined),
    [openSheetsIds, workspace],
  );

  const openSheetTab = React.useCallback(
    (sheetId: TSectionId) => {
      if (openSheetsIds.indexOf(sheetId) >= 0) {
        setSheetTabsState(openSheetsIds, sheetId);
      } else {
        if (!!workspace.sheets.find((v) => v.id === sheetId)) {
          setSheetTabsState([...openSheetsIds, sheetId], sheetId);
        }
      }
    },
    [openSheetsIds, setSheetTabsState, workspace.sheets],
  );

  const selectSheetTab = React.useCallback(
    (sheetId: TSectionId | undefined) => {
      if (sheetId) {
        openSheetTab(sheetId);
      } else {
        setSheetTabsState(openSheetsIds, undefined);
      }
    },
    [openSheetTab, openSheetsIds, setSheetTabsState],
  );

  const closeSheetTab = React.useCallback(
    (sheetId: TSectionId) => {
      // TODO: implement nextSelectedSheetId
      // const closedTabIndex = openSheetsIds.indexOf(sheetId);
      // const nextSelectedSheetId = selectedSheetId === sheetId ? : selectedSheetId;
      const nextSheetTabs = openSheetsIds.filter((tabSheetId) => tabSheetId !== sheetId);
      setSheetTabsState(nextSheetTabs, nextSheetTabs.indexOf(sheetId) >= 0 ? sheetId : undefined);
    },
    [openSheetsIds, setSheetTabsState],
  );

  const handleSheetCreate = React.useCallback(() => {
    // TODOVB: replace with different analytics service
    // track('create-sheet', { emailAddress: `${user?.primaryEmailAddress}`, id: `${user?.id}` });
    const iche = hCreateSection(workspaceTracker);
    workspaceTracker
      .performEdit(iche.historyEntry)
      .then((updatedWorkspace) => {
        console.debug(`Updated workspace with id ${updatedWorkspace.id}`, updatedWorkspace);
      })
      .then(() => {
        setCurrentStep(QUICK_START_TOUR_STEP_COUNT.OpenSheet);
      });
  }, [setCurrentStep, workspaceTracker]);

  const handleSheetListClick = React.useCallback(
    (sheetSectionId: TSectionId) => {
      openSheetTab(sheetSectionId);
    },
    [openSheetTab],
  );

  const handleSheetTabSelect = React.useCallback(
    (sheetSectionId: TSectionId | undefined) => {
      selectSheetTab(sheetSectionId);
    },
    [selectSheetTab],
  );

  const handleSheetTabClose = React.useCallback(
    (sheetSectionId: TSectionId) => {
      closeSheetTab(sheetSectionId);
    },
    [closeSheetTab],
  );

  const visibleSheets = React.useMemo(
    () => workspace.sheets.filter((sheet) => showHidden || isWorkspaceItemVisible(sheet)),
    [workspace.sheets, showHidden],
  );

  const handleKeyPress = React.useCallback(
    (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 's') {
        event.preventDefault();
        workspaceTracker.performSave();
        toast({
          description: 'Your changes have been successfully saved.',
        });
      }
    },
    [toast, workspaceTracker],
  );

  useShortcuts({ cb: handleKeyPress });

  React.useEffect(() => {
    if (workspaceTracker.workspaceEditHistory.historyPosition === 0) return;

    const beforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      workspaceTracker.performSave();
    };

    window.addEventListener('beforeunload', beforeUnload);
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [workspaceTracker]);

  return (
    <EnjicalcWorkspaceEditHistoryContext.Provider value={workspaceTracker}>
      <div className="flex">
        <AppActivityBar onSave={() => workspaceTracker.performSave()} />

        <div className="flex flex-row items-stretch gap-0">
          {isShow && (
            <SidebarContainer position={'left'}>
              <WorkspaceSheetsSidebarPrimary
                sheets={visibleSheets}
                openSheets={openSheets}
                activeSheet={selectedSheet}
                onSheetOpen={handleSheetListClick}
                onSheetCreate={handleSheetCreate}
                performWorkspaceEdit={workspaceTracker.performEdit}
              />
            </SidebarContainer>
          )}

          <WorkspaceSheetsTabs
            openSheets={openSheets}
            activeSheet={selectedSheet}
            onSheetSelect={handleSheetTabSelect}
            onSheetClose={handleSheetTabClose}
          />
        </div>
      </div>
    </EnjicalcWorkspaceEditHistoryContext.Provider>
  );
};

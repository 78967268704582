import React from 'react';
import { Popover, PopoverContent, PopoverTrigger, Button } from 'src/shadcn';

interface IProps {
  onConfirm: () => void;
  children: React.ReactNode;
}

export const DeleteConfirmationPopover = ({ onConfirm, children }: IProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const firstFieldRef = React.useRef(null);

  const handleToggle = React.useCallback(() => {
    setIsOpen(!isOpen);
    setInputValue('');
  }, [isOpen]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleConfirm = () => {
    if (inputValue === 'delete') {
      onConfirm();
      handleToggle();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.toLowerCase() === 'delete') {
      handleConfirm();
    }
  };

  return (
    <Popover open={isOpen} onOpenChange={handleToggle} modal={false}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent side="bottom" align="start" className="p-0">
        <div className="p-4">
          <p className="mb-2 text-sm text-black">
            This action <b>CANNOT</b> be undone. <br /> Type 'delete' to confirm:
          </p>
          <input
            ref={firstFieldRef}
            value={inputValue}
            onChange={handleInputChange}
            placeholder="delete"
            className="mb-2 w-full rounded-md border p-2 text-sm focus-visible:outline-none"
            onKeyDown={handleKeyDown}
          />
          <Button
            onClick={handleConfirm}
            className="w-full bg-red-600 text-[15px] hover:bg-red-700"
            disabled={inputValue !== 'delete'}
          >
            Confirm
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

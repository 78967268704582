import { EnjcAuxMetaEntryDelta } from '../model';
import { invertAuxMetaDeltaEntry } from './invertAuxMetaDeltaEntry';

export const invertAuxMetaDelta = (
  delta: ReadonlyArray<EnjcAuxMetaEntryDelta>,
): ReadonlyArray<EnjcAuxMetaEntryDelta> => {
  return [...delta].reverse().map((deltaEntry) => {
    return invertAuxMetaDeltaEntry(deltaEntry);
  });
};

import { EnjcValueTreeNode } from '../../tree-node';
import { IConstructedOperatorsChain } from '../interfaces';
import { findOperatorsChainRootNVTV } from './findOperatorsChainRootNVTV';
import { collectLeafOperatorsChainNVTV } from './collectLeafOperatorsChainNVTV';
import { IValueTreeViewContext } from '../../../enjc-value-view-ctx';

export const constructNodeOperatorsChainNVTV = (
  vtvCtx: IValueTreeViewContext,
  node: EnjcValueTreeNode,
): IConstructedOperatorsChain => {
  const chainRootNode = findOperatorsChainRootNVTV(vtvCtx, node);
  const operatorsChain = collectLeafOperatorsChainNVTV(vtvCtx, chainRootNode);
  return { chainRootNode, operatorsChain };
};

import { EnjcWorkspaceDelta, WorkspaceEditHistoryEntry } from '../model';

export const mkWorkspaceEditHistoryEntryDeprecated = (
  title: string,
  delta: EnjcWorkspaceDelta,
): WorkspaceEditHistoryEntry => {
  return {
    timestamp: Date.now(),
    delta,
    actionMeta: { title },
  };
};

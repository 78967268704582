import { EnjcStringInput } from '../../enjicalc-graphql';
import { EnjcStringDeltaEntry } from '../model';

export const stringDeltaToInput = (strDelta: EnjcStringDeltaEntry, toUndo: boolean): EnjcStringInput => {
  return {
    // TODO: check when this should be undefined
    position: strDelta.slicePosition ?? 0,
    count: toUndo ? strDelta.sliceAfter?.length : strDelta.sliceBefore?.length,
    value: toUndo ? strDelta.sliceBefore : strDelta.sliceAfter,
  };
};

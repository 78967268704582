import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdMoreHoriz, MdOutlineDriveFileRenameOutline, MdOutlineFileOpen } from 'react-icons/md';
import { EnjcWorkspaceItemVisibility, EnjicalcWorkspaceItemsRefs } from '../../../libenjc/enjc-workspace';
import {
  EnjicalcWorkspaceUpdateMessage,
  WorkspaceEditHistoryEntry,
  editSetWorkspaceVisibility,
} from '../../../libenjc/enjc-workspace-editing';
import { TbArchiveFilled, TbArchiveOff } from 'react-icons/tb';
import { CiViewTable } from 'react-icons/ci';
import { IoCloseSharp, IoDuplicate } from 'react-icons/io5';
import { DeleteConfirmationPopover } from 'src/components/misc';
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from 'src/shadcn';

interface IProps {
  readonly workspace: EnjicalcWorkspaceItemsRefs;
  readonly onOpenRenameTitle: () => void;
  readonly performWorkspaceEdit: (editEntry: WorkspaceEditHistoryEntry) => Promise<EnjicalcWorkspaceUpdateMessage>;
  readonly onWorkspaceFork: () => void;
}

const WorkspaceMenuF = ({
  workspace,
  onOpenRenameTitle,
  performWorkspaceEdit,
  onWorkspaceFork,
}: IProps): ReactElement => {
  const navigate = useNavigate();

  const [isOpenMenu, setIsOpenMenu] = React.useState(false);

  const isNotArchiveWorkspace = workspace.visibility === EnjcWorkspaceItemVisibility.Visible;

  const setEnjcWorkspaceVisibility = React.useCallback(
    (nextVisibility: EnjcWorkspaceItemVisibility) => {
      const hEntry = editSetWorkspaceVisibility(workspace, nextVisibility);
      performWorkspaceEdit && performWorkspaceEdit(hEntry);
    },
    [performWorkspaceEdit, workspace],
  );

  const handleDeleteWorkspace = React.useCallback(() => {
    setIsOpenMenu(false);
    setEnjcWorkspaceVisibility(EnjcWorkspaceItemVisibility.Deleted);
  }, [setEnjcWorkspaceVisibility]);

  const handleArchiveWorkspace = React.useCallback(() => {
    if (isNotArchiveWorkspace) {
      setEnjcWorkspaceVisibility(EnjcWorkspaceItemVisibility.Hidden);
    } else {
      setEnjcWorkspaceVisibility(EnjcWorkspaceItemVisibility.Visible);
    }
    setIsOpenMenu(false);
  }, [isNotArchiveWorkspace, setEnjcWorkspaceVisibility]);

  return (
    <DropdownMenu modal={false} open={isOpenMenu} onOpenChange={(open) => setIsOpenMenu(open)}>
      <DropdownMenuTrigger>
        <Button onClick={(e) => e.stopPropagation()} size={'sm'} aria-label={'workspace menu'} variant="ghost">
          <MdMoreHoriz />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent onClick={(e) => e.stopPropagation()} className="border border-transparent shadow-lg">
        <Button
          variant="ghost"
          onClick={() => {
            navigate(`/workspace/${workspace.id}`);
          }}
          className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
        >
          <MdOutlineFileOpen size={17} className="mr-2 text-black" />
          {'Open workspace'}
        </Button>
        <Button
          variant="ghost"
          onClick={onOpenRenameTitle}
          className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
        >
          <MdOutlineDriveFileRenameOutline size={17} className="mr-2 text-black" />
          {'Rename workspace'}
        </Button>
        <Button
          variant="ghost"
          onClick={onWorkspaceFork}
          className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
        >
          <IoDuplicate size={17} className="mr-2 text-black" />
          {'Duplicate workspace'}
        </Button>
        {isNotArchiveWorkspace ? (
          <Button
            variant="ghost"
            onClick={handleArchiveWorkspace}
            className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
          >
            <TbArchiveFilled size={17} className="mr-2 text-black" />
            {'Archive workspace'}
          </Button>
        ) : (
          <Button
            variant="ghost"
            onClick={() => setEnjcWorkspaceVisibility(EnjcWorkspaceItemVisibility.Visible)}
            className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
          >
            <TbArchiveOff size={17} className="mr-2 text-black" />
            {'Un-archive workspace'}
          </Button>
        )}
        <DeleteConfirmationPopover onConfirm={handleDeleteWorkspace}>
          <Button
            onClick={(e) => e.stopPropagation()}
            variant="ghost"
            className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
          >
            <IoCloseSharp size={17} className="mr-2 text-red-600" />
            {'Delete workspace'}
          </Button>
        </DeleteConfirmationPopover>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const WorkspaceMenu = React.memo(WorkspaceMenuF);

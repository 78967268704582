import React, { ReactElement } from 'react';
import { Popover, PopoverTrigger, PopoverContent } from 'src/shadcn';
import { useValueTreeViewContext } from '../../../enjc-react-ui';
import { UValueHintItem } from '../../../../../libenjc/enjc-value-view-ctx/model/IValueTreeViewContext';
import { getValueHintEntries } from '../../../../../libenjc/enjc-value-view-ctx';
import { useCtxEnjicalcSheet } from '../../../../../libenjc/enjc-react/enjc-react-context';
import { SymbolValueHintsView } from './SymbolValueHintsView';

interface Props {
  readonly onHintClick?: (hint: UValueHintItem) => void;
  readonly qfixHideCursor?: boolean;
}

export const SymbolValuePlainCursor = ({ onHintClick, qfixHideCursor }: Props): ReactElement => {
  const vtvCtx = useValueTreeViewContext();
  const { symbol, hasFocus, showValueHints } = vtvCtx;
  const symbolSheet = useCtxEnjicalcSheet().sheet;
  const valueHints = getValueHintEntries(vtvCtx, symbol, symbolSheet);

  // TODO: fix <div> and <section> elements inside <p> elements
  // TODO: check if popover is correct component for the value hints

  return (
    <Popover open={showValueHints && valueHints.length > 0}>
      <PopoverTrigger asChild>
        <div
          className={`${hasFocus ? 'inline' : 'hidden'} absolute top-[-5px] h-[30px] w-px ${
            !qfixHideCursor ? 'bg-black' : ''
          } animate-blink`}
        />
      </PopoverTrigger>
      <PopoverContent className="z-[9999] w-[300px] max-w-[300px] p-0">
        <h2 className="border-b bg-gray-100 p-2 text-sm font-semibold">Hints</h2>
        <div className="max-h-40 overflow-auto">
          <SymbolValueHintsView onHintClick={onHintClick} />
        </div>
      </PopoverContent>
    </Popover>
  );
};

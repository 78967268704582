import { EnjcAuxMeta } from '../../../generated/graphql/types';
import { getWorkspaceItemAuxMetaStrEntry } from './getWorkspaceItemAuxMetaStrEntry';
import {
  AUX_META_WORKSPACE_SYMBOL_COMMENT,
  AUX_META_WORKSPACE_SYMBOL_DESCRIPTION,
  AUX_META_WORKSPACE_SYMBOL_UNIT,
} from '../constants';

interface IResult {
  unit: string;
  description: string;
  comment: string;
}

export const getSymbolUnitDescriptionComment = (workspaceItem: { auxMeta?: EnjcAuxMeta | null }): IResult => {
  const unit = getWorkspaceItemAuxMetaStrEntry(workspaceItem, AUX_META_WORKSPACE_SYMBOL_UNIT);
  const description = getWorkspaceItemAuxMetaStrEntry(workspaceItem, AUX_META_WORKSPACE_SYMBOL_DESCRIPTION);
  const comment = getWorkspaceItemAuxMetaStrEntry(workspaceItem, AUX_META_WORKSPACE_SYMBOL_COMMENT);
  return {
    unit,
    description,
    comment,
  };
};

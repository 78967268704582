import { IValueTreeViewContext, collectTreeNodeDescendantsNVTV } from '../../../enjc-value-view-ctx';
import { mkTreeLiteral, EnjcValueTreeNode } from '../../tree-node';
import { mkTreeCursorNode, mkTreeCursorNodeDraft } from '../../tree-cursor';
import { ValueTreeClientDelta } from '../model';
import { processLiteralDraft } from './processLiteralDraft';
import { mkNodeRemovalDelta } from './mkNodeRemovalDelta';
import { calculateNodeDeltaNVTV } from './calculateNodeDeltaNVTV';

export const resetTreeNodeNVTV = (
  vtvCtx: IValueTreeViewContext,
  node: EnjcValueTreeNode,
  draft: string = '',
): ValueTreeClientDelta => {
  const placeholderNode = mkTreeLiteral(node.key, draft, processLiteralDraft(draft));
  const nodesUpsert = [placeholderNode];
  return {
    delta: {
      nodes: [
        ...nodesUpsert.map((n) => calculateNodeDeltaNVTV(vtvCtx, n)),
        ...collectTreeNodeDescendantsNVTV(vtvCtx, node).map((n) => mkNodeRemovalDelta(n)),
      ],
    },
    cursor: draft.length
      ? mkTreeCursorNodeDraft(placeholderNode.key, draft.length)
      : mkTreeCursorNode(placeholderNode.key, false),
  };
};

import React, { ReactElement } from 'react';
import { EnjcStringDeltaEntry } from '../../../libenjc/enjc-delta';
import { SymbolTextLineDeltaInput } from './SymbolTextLineDeltaInput';
import { HoverCard, HoverCardTrigger, HoverCardContent } from '../../../shadcn/components';
import { HiOutlineInformationCircle } from 'react-icons/hi2';

interface IProps {
  readonly name: string;
  readonly text: string;
  readonly onTextDelta: (delta: EnjcStringDeltaEntry) => void;
  readonly isMandatory?: boolean;
  readonly informationText?: string;
}

export const SymbolTextLineFieldEditor = ({
  name,
  text,
  onTextDelta,
  isMandatory,
  informationText,
}: IProps): ReactElement => {
  return (
    <div className="flex items-center gap-2">
      <div className="flex w-40 items-center justify-end gap-1">
        {informationText && (
          <HoverCard>
            <HoverCardTrigger>
              <HiOutlineInformationCircle style={{ fontSize: '1.2em' }} />
            </HoverCardTrigger>
            <HoverCardContent className="w-[300px]">{informationText}</HoverCardContent>
          </HoverCard>
        )}
        <span>{name}</span>
      </div>

      <SymbolTextLineDeltaInput value={text} onValueDelta={onTextDelta} />
    </div>
  );
};

import { StrictTypedTypePolicies } from '../../generated/graphql/apollo-type-policies-helpers';

export const queryTypePolicy: StrictTypedTypePolicies['Query'] = {
  fields: {
    // workspaceById(existing, { args, toReference }) {
    //   return toReference({
    //     __typename: 'Workspace',
    //     id: args!.id,
    //   });
    // },
    // sectionById(existing, { args, toReference }) {
    //   return toReference({
    //     __typename: 'EnjcWorkspaceSection',
    //     id: args!.id,
    //   });
    // },
    // symbolById(existing, { args, toReference }) {
    //   return toReference({
    //     __typename: 'EnjcWorkspaceSymbol',
    //     id: args!.id,
    //   });
    // },
  },
};

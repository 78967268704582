import { calculateStringDelta } from '../../enjc-delta';
import {
  EnjicalcWorkspace,
  getEnjcSection,
  TSectionId,
  getWorkspaceItemAuxMetaStrEntry,
  EnjcWorkspaceDTO,
} from '../../enjc-workspace';
import { WorkspaceEditHistoryEntry } from '../model';
import { mkWorkspaceEditHistoryEntryDeprecated } from '../utils';

export const editSetSectionAuxMetaStrEntry = (
  workspace: EnjcWorkspaceDTO,
  sectionId: TSectionId,
  name: string,
  valueStr: string,
): WorkspaceEditHistoryEntry => {
  const existingSection = getEnjcSection(workspace, sectionId);
  const existingAuxMetaEntry = getWorkspaceItemAuxMetaStrEntry(existingSection, name);
  const sectionDeltaPart = calculateStringDelta(existingAuxMetaEntry, valueStr);
  return mkWorkspaceEditHistoryEntryDeprecated('editSetSectionAuxMetaStrEntry', {
    section: sectionDeltaPart && [{ id: sectionId, auxMeta: [{ name, value: sectionDeltaPart }] }],
  });
};

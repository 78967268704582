import { getAuxMetaStrEntry, mkAuxMetaStringMeta } from '../../enjc-common';
import { EnjcAuxMeta } from '../../../generated/graphql/types';
import { EnjcAuxMetaEntryDelta } from '../model';
import { applyStringDelta } from './applyStringDelta';

export const applyAuxMetaDelta = (
  value: EnjcAuxMeta | undefined | null,
  delta: ReadonlyArray<EnjcAuxMetaEntryDelta>,
): EnjcAuxMeta => {
  const existingAuxMeta: EnjcAuxMeta = value || { __typename: 'EnjcAuxMeta', items: [] };
  return delta.reduce((acc, deltaEntry) => {
    const existingStrValue = getAuxMetaStrEntry(value, deltaEntry.name);
    const nextStrValue = applyStringDelta(existingStrValue, deltaEntry.value);

    const nextAccItems = nextStrValue
      ? existingStrValue
        ? acc?.items.map((auxMetaItem) =>
            auxMetaItem.name === deltaEntry.name ? mkAuxMetaStringMeta(deltaEntry.name, nextStrValue) : auxMetaItem,
          )
        : [...(acc?.items ?? []), mkAuxMetaStringMeta(deltaEntry.name, nextStrValue)]
      : existingStrValue
        ? (acc?.items ?? []).flatMap((auxMetaItem) => (auxMetaItem.name === deltaEntry.name ? [] : [auxMetaItem]))
        : acc?.items;

    return {
      ...acc,
      items: nextAccItems,
    };
  }, existingAuxMeta);
};

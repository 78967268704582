import React, { ReactElement } from 'react';
import { EnjicalcSheetContext, useCtxEnjicalcWorkspace } from '../../libenjc/enjc-react/enjc-react-context';
import { SheetDocumentEditor } from '../../components/editors/sheet-document-editor';
import { IItemViewProps } from '../../components/enjc-items/interfaces';
import { mkEnjicalcSheet } from '../../libenjc/enjc-workspace';
import { EnjcWorkspaceSectionFragment } from '../../generated/graphql/operations';

interface IProps extends IItemViewProps<EnjcWorkspaceSectionFragment> {}

export const WorkspaceSheetView = ({ item: enjcSection }: IProps): ReactElement => {
  const { workspace } = useCtxEnjicalcWorkspace();
  const enjicalcSheet = React.useMemo(() => mkEnjicalcSheet(workspace, enjcSection), [enjcSection, workspace]);

  return (
    <EnjicalcSheetContext.Provider value={{ sheet: enjicalcSheet }}>
      <div className="flex h-full flex-col overflow-hidden">
        <SheetDocumentEditor />
      </div>
    </EnjicalcSheetContext.Provider>
  );
};

import React, { ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SymbolEditor } from '../symbol-editor';
import { WorkspaceSymbolsStats } from './WorkspaceSymbolsStats';
import { useCtxEnjicalcWorkspace } from '../../../libenjc/enjc-react/enjc-react-context';

export const WorkspaceSymbolsEditor = (): ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchTerm = React.useMemo(() => searchParams.get('searchTerm') || '', [searchParams]);

  const setSearchTerm = React.useCallback(
    (value: string) => setSearchParams(value ? { searchTerm: value } : {}),
    [setSearchParams],
  );

  const { workspace } = useCtxEnjicalcWorkspace();

  return (
    <div className="flex h-screen flex-col items-start overflow-y-auto">
      <WorkspaceSymbolsStats />
      <div className="flex w-full flex-row">
        <input
          className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search..."
          value={searchTerm}
        />
      </div>
      {workspace.symbols.map((symbol) => (
        <SymbolEditor key={symbol.id} symbol={symbol} />
      ))}
    </div>
  );
};

import { ApolloError } from '@apollo/client';
import { useGetWorkspacesListQuery } from '../../../../generated/graphql/apollo';
import { EnjicalcWorkspaceItemsRefs } from '../../../enjc-workspace';

interface IHook {
  readonly loading: boolean;
  readonly error?: ApolloError;
  readonly workspaces?: ReadonlyArray<EnjicalcWorkspaceItemsRefs>;
  readonly refetch: () => void;
}

export const useWorkspacesList = (): IHook => {
  const { loading, error, data, refetch } = useGetWorkspacesListQuery();

  return {
    loading,
    error,
    workspaces: data?.workspaces,
    refetch,
  };
};

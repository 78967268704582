import { EnjcWorkspaceSymbolDelta } from '../model';
import { invertAtomDelta, invertAuxMetaDelta, invertStringDelta } from '../../enjc-delta';
import { invertValueTreeDelta } from './invertValueTreeDelta';

export const invertSymbolDelta = (delta: EnjcWorkspaceSymbolDelta): EnjcWorkspaceSymbolDelta => {
  return {
    id: delta.id,
    visibility: delta.visibility && invertAtomDelta(delta.visibility),
    auxMeta: delta.auxMeta && invertAuxMetaDelta(delta.auxMeta),
    glyph: delta.glyph && invertStringDelta(delta.glyph),
    glyphPlaintext: delta.glyphPlaintext && invertStringDelta(delta.glyphPlaintext),
    valueTree: delta.valueTree && invertValueTreeDelta(delta.valueTree),
  };
};

import { EnjcValueFunctionSpecFragment } from '../../enjicalc-graphql';
import { EnjcWorkspaceDTO, EnjcWorkspaceWithMath } from '../model';

export const mkEnjcWorkspaceWithMath = (
  workspace: EnjcWorkspaceDTO,
  functions: ReadonlyArray<EnjcValueFunctionSpecFragment>,
): EnjcWorkspaceWithMath => {
  // const sheets = findSheetSections(workspace.sections);
  return {
    ...workspace,
    functions,
  };
};

import { WorkspaceEditHistoryEntry } from '../../enjc-workspace-editing';

export const countGroupTagHistorySteps = (
  historyEntries: WorkspaceEditHistoryEntry[],
  historyPosition: number,
  groupTag: string | undefined,
  countRedo: boolean,
): number => {
  console.log(`countGroupTagHistorySteps ${historyPosition} / ${historyEntries.length}`);
  if (!countRedo) {
    if (historyPosition === 0) {
      return 0;
    }

    const groupTagCount = historyEntries
      .slice(0, historyPosition)
      .reverse()
      .findIndex((entry) => entry.actionMeta.groupTag === groupTag);
    if (groupTagCount === -1) {
      return historyPosition;
    } else if (groupTagCount === 0) {
      return 1;
    } else {
      return groupTagCount;
    }
  } else {
    if (historyPosition === historyEntries.length) {
      return 0;
    }

    const groupTagCount = historyEntries
      .slice(historyPosition)
      .findIndex((entry) => entry.actionMeta.groupTag === groupTag);
    if (groupTagCount === -1) {
      return historyEntries.length - historyPosition;
    } else if (groupTagCount === 0) {
      return 1;
    } else {
      return groupTagCount;
    }
  }
};

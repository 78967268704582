import { TSymbolId, findWorkspaceItemContainer, TSectionId } from '../../enjc-workspace';
import { IEnjcWorkspaceLocalHistory } from '../interfaces';
import { EnjcWorkspaceSectionDelta, ItemCreateEntry } from '../model';
import { hCreateSection } from './hCreateSection';

export const hCreateSheetSectionFlatBeforeAfter = (
  wlh: IEnjcWorkspaceLocalHistory,
  sheetId: TSectionId,
  symbolBeforeAfterId: TSymbolId,
  addAfter: boolean,
): ItemCreateEntry => {
  const containerDstInfo = findWorkspaceItemContainer(wlh.currentWorkspace, symbolBeforeAfterId);
  if (!containerDstInfo) {
    // TODO: report error
    throw new Error('Dst container not found');
  }

  // get container info parent
  // if null create section
  // if sheet create section
  // otherwise throw error
  const dstParentContainerInfo = findWorkspaceItemContainer(wlh.currentWorkspace, containerDstInfo.container.id);

  const hcr = hCreateSection(wlh);
  if (!dstParentContainerInfo) {
    const sectionDelta: EnjcWorkspaceSectionDelta = {
      id: containerDstInfo.container.id,
      items: [{ slicePosition: containerDstInfo.index + (addAfter ? 1 : 0), sliceAfter: [hcr.itemId] }],
    };

    return {
      ...hcr,
      historyEntry: {
        ...hcr.historyEntry,
        delta: {
          ...hcr.historyEntry.delta,
          section: [...(hcr.historyEntry.delta.section ?? []), sectionDelta],
        },
      },
    };
  } else {
    if (dstParentContainerInfo.container.id !== sheetId) {
      throw new Error(`Dst container parent (${dstParentContainerInfo.container.id}) is not sheet (${sheetId})`);
    }

    const sectionDeltas: EnjcWorkspaceSectionDelta[] = [
      {
        id: containerDstInfo.container.id,
        items: [
          {
            slicePosition: containerDstInfo.index + (addAfter ? 1 : 0),
            sliceBefore: containerDstInfo.container.items
              .slice(containerDstInfo.index + (addAfter ? 1 : 0))
              .map((si) => si.id),
            sliceAfter: [],
          },
        ],
      },
      {
        id: hcr.itemId,
        items: [
          {
            slicePosition: 0,
            sliceAfter: containerDstInfo.container.items
              .slice(containerDstInfo.index + (addAfter ? 1 : 0))
              .map((si) => si.id),
          },
        ],
      },
      {
        id: dstParentContainerInfo.container.id,
        items: [{ slicePosition: dstParentContainerInfo.index + 1, sliceAfter: [hcr.itemId] }],
      },
    ];

    return {
      ...hcr,
      historyEntry: {
        ...hcr.historyEntry,
        delta: {
          ...hcr.historyEntry.delta,
          section: [...(hcr.historyEntry.delta.section ?? []), ...sectionDeltas],
        },
      },
    };
  }
};

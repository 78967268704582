import { mkLiteralVoidNull, UEnjcValueLiteral } from '../../../enjc-literal';
import { EnjcValueTreeNode } from '../model';
import { EnjcValueTreeNodeMode } from '../../../../generated/graphql/types';

export const mkTreeLiteral = (key: string, draft: string, literal: UEnjcValueLiteral): EnjcValueTreeNode => ({
  __typename: 'EnjcValueTreeNode',
  key,
  tags: [],
  draft,
  mode: EnjcValueTreeNodeMode.Literal,
  literal,
  funcArgs: [],
  result: mkLiteralVoidNull(),
});

import React, { ReactElement, useEffect, useRef } from 'react';
import { useValueTreeViewContext } from '../../../enjc-react-ui';
import { useCtxEnjicalcSheet } from '../../../enjc-react-context';
import { getValueHintEntries } from '../../../../enjc-value-view-ctx';
import { EnjcGlyphMathBlockView } from '../../../../../components/editors/symbol-math-view';
import { UValueHintItem } from '../../../../enjc-value-view-ctx/model/IValueTreeViewContext';
import {
  EnjicalcSymbol,
  getSymbolUnitDescriptionComment,
  getWorkspaceItemAuxMetaStrEntry,
} from '../../../../enjc-workspace';
import { valueLiteralToString } from '../../../../enjc-literal';
import { TooltipContent, TooltipTrigger, Tooltip } from 'src/shadcn';
import { AUX_META_WORKSPACE_FUNC_DESCRIPTION } from '../../../../enjc-workspace/constants';

export const isHintEntrySymbol = (entry: UValueHintItem): entry is EnjicalcSymbol =>
  '__typename' in entry && entry.__typename === 'EnjcWorkspaceSymbol';

interface Props {
  readonly onHintClick?: (hint: UValueHintItem) => void;
}

export const SymbolValueHintsView = ({ onHintClick }: Props): ReactElement => {
  const vtvCtx = useValueTreeViewContext();
  const { symbol, valueHintItem } = vtvCtx;
  const symbolSheet = useCtxEnjicalcSheet().sheet;
  const valueHints = getValueHintEntries(vtvCtx, symbol, symbolSheet);

  // console.log(`[DEBUG] SymbolValueHintsView symbolSheet ${symbolSheet.id}-'${symbolSheet.title}'`);

  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (listRef.current && valueHintItem) {
      const selectedItem = listRef.current.querySelector(`li[data-id="${valueHintItem.id}"]`);
      if (selectedItem) {
        selectedItem.scrollIntoView({ behavior: 'auto', block: 'center' });
      }
    }
  }, [valueHintItem, valueHints]);

  return (
    <ul ref={listRef} className="list-none p-0">
      {valueHints.map((hSy) => {
        return (
          <li
            key={hSy.item.id}
            data-id={hSy.item.id}
            className={`flex h-8 w-full cursor-pointer px-2 py-1 ${
              valueHintItem?.id === hSy.item.id ? 'bg-gray-200' : ''
            } hover:bg-gray-300`}
            onClick={() => onHintClick?.(hSy.item)}
          >
            {isHintEntrySymbol(hSy.item) ? (
              <div className="flex w-full flex-row items-center gap-1">
                <span className="text-xs text-gray-500">{`${hSy.sheetIndex}.`}</span>
                <span className="flex items-center">
                  <EnjcGlyphMathBlockView glyph={hSy.item.glyph} />
                </span>
                <span className="flex items-center">{' = '}</span>
                <span className="flex items-center">{valueLiteralToString(hSy.item.valueTree.result)}</span>

                <Tooltip delayDuration={100}>
                  <TooltipTrigger asChild>
                    <span className="ml-1 items-center truncate text-xs text-gray-500">
                      {getSymbolUnitDescriptionComment(hSy.item).description}
                    </span>
                  </TooltipTrigger>
                  <TooltipContent side="right" className="ml-4 w-[400px]">
                    {getSymbolUnitDescriptionComment(hSy.item).description}
                  </TooltipContent>
                </Tooltip>
              </div>
            ) : (
              <div>
                <span className="text-sm">{hSy.item.glyph.replace(/"/g, '') + '()'}</span>
                {/* <Tooltip delayDuration={100}>
                  <TooltipTrigger asChild>
                    <span className="ml-1 items-center truncate text-xs text-gray-500">
                      {getWorkspaceItemAuxMetaStrEntry(hSy.item, AUX_META_WORKSPACE_FUNC_DESCRIPTION)}
                    </span>
                  </TooltipTrigger>
                  <TooltipContent side="right" className="ml-4 w-[400px]">
                    {getWorkspaceItemAuxMetaStrEntry(hSy.item, AUX_META_WORKSPACE_FUNC_DESCRIPTION)}
                  </TooltipContent>
                </Tooltip> */}
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};

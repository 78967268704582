import { mkAtomDelta } from '../../enjc-delta';
import { EnjcWorkspaceItemVisibility } from '../../enjc-workspace';
import { IEnjcWorkspaceLocalHistory } from '../interfaces';
import { ItemCreateEntry } from '../model';
import { mkWorkspaceEditHistoryEntry } from '../utils';

export const hCreateSymbol = (wlh: IEnjcWorkspaceLocalHistory): ItemCreateEntry => {
  // Allocate unique local id for the new section
  const lcrId = wlh.generateLocalCreateId();
  // Create history entry
  const hEntry = mkWorkspaceEditHistoryEntry(
    {
      symbol: [
        {
          id: lcrId,
          visibility: mkAtomDelta(EnjcWorkspaceItemVisibility.Deleted, EnjcWorkspaceItemVisibility.Visible),
        },
      ],
    },
    { title: 'Create Symbol', actComments: ['hCreateSymbol'] },
  );
  return {
    historyEntry: hEntry,
    itemId: lcrId,
  };
  // Add history entry
  // return wlh.performEdit(hEntry).then((updateResult) => {
  //   // Extract created section from the result
  //   // const allocatedId = extractUpdateAllocatedId(updateResult, lcrId);
  //   // if (!allocatedId) {
  //   //   throw Error('Cannot find allocated ID for the created section');
  //   // }
  //   // const createdSection = updateResult.updateValue.sections.find((upSe) => upSe.id === allocatedId.id);
  //   const createdSymbol = updateResult.symbols.find((upSe) => upSe.id === lcrId);
  //   if (!createdSymbol) {
  //     throw Error(`Cannot find created symbol with ID '${lcrId}'`);
  //   }
  //   return createdSymbol;
  // });
};

import {
  EnjcValueLiteralVoidCause,
  EnjcWorkspaceItemVisibility,
  EnjcWorkspaceSymbolFragment,
} from '../../enjicalc-graphql';
import { applyAuxMetaDelta, applyStringDelta } from '../../enjc-delta';
import { EnjicalcSymbol } from '../../enjc-workspace';
import { EnjcWorkspaceSymbolDelta } from '../model';
import { applyValueTreeDelta } from './applyValueTreeDelta';

export const applySymbolDelta = (
  existingSymbol: EnjcWorkspaceSymbolFragment | undefined,
  delta: EnjcWorkspaceSymbolDelta,
): EnjcWorkspaceSymbolFragment => {
  const symbol: EnjicalcSymbol = existingSymbol || {
    __typename: 'EnjcWorkspaceSymbol',
    id: delta.id,
    created: Date.now() / 1000,
    version: { __typename: 'EnjcVersionMeta', timestamp: Date.now(), counter: 0 },
    visibility: EnjcWorkspaceItemVisibility.Deleted,
    auxMeta: { __typename: 'EnjcAuxMeta', items: [] },
    glyph: '',
    glyphPlaintext: '',
    aliases: [],
    valueTree: {
      __typename: 'EnjcValueTree',
      nodes: [],
      rootNode: undefined,
      result: { __typename: 'EnjcValueLiteralVoid', cause: EnjcValueLiteralVoidCause.Null },
    },
  };
  return {
    ...symbol,
    visibility: delta.visibility?.atomAfter ?? symbol.visibility,
    auxMeta: delta.auxMeta ? applyAuxMetaDelta(symbol.auxMeta, delta.auxMeta) : symbol.auxMeta,
    glyph: delta.glyph ? applyStringDelta(symbol.glyph, delta.glyph) : symbol.glyph,
    glyphPlaintext: delta.glyphPlaintext
      ? applyStringDelta(symbol.glyphPlaintext, delta.glyphPlaintext)
      : symbol.glyphPlaintext,
    valueTree: delta.valueTree ? applyValueTreeDelta(symbol.valueTree, delta.valueTree) : symbol.valueTree,
  };
};

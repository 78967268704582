import { createContext } from 'react';
import { EnjcWorkspaceItemVisibility } from '../../../enjc-workspace';
import { IEnjicalcSheetContext } from '../../../enjc-context';

export const EnjicalcSheetContext = createContext<IEnjicalcSheetContext>({
  sheet: {
    __typename: 'EnjcWorkspaceSection',
    id: '',
    created: 0,
    version: { __typename: 'EnjcVersionMeta', counter: 0, timestamp: 0 },
    visibility: EnjcWorkspaceItemVisibility.Visible,
    auxMeta: { __typename: 'EnjcAuxMeta', items: [] },
    items: [],
    symbols: [],
    sheetItems: [],
  },
});

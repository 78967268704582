import { invertAtomDelta, invertKeyListDelta, invertKeySetDelta, invertStringDelta } from '../../enjc-delta';
import { EnjcValueTreeNodeDelta } from '../model';

export const invertValueTreeNodeDelta = (delta: EnjcValueTreeNodeDelta): EnjcValueTreeNodeDelta => {
  return {
    key: delta.key,
    tags: delta.tags && invertKeySetDelta(delta.tags),
    draft: delta.draft && invertStringDelta(delta.draft),
    mode: delta.mode && invertAtomDelta(delta.mode),
    literal: delta.literal && invertAtomDelta(delta.literal),
    symbol: delta.symbol && invertAtomDelta(delta.symbol),
    funcSpec: delta.funcSpec && invertAtomDelta(delta.funcSpec),
    funcArgs: delta.funcArgs && invertKeyListDelta(delta.funcArgs),
    removeAdd: delta.removeAdd && (delta.removeAdd === 'remove' ? 'add' : 'remove'),
  };
};

'use client';

import * as React from 'react';

import { CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from 'src/shadcn';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStringColor } from '../../utils/getStringColor';
import { EnjicalcWorkspaceItemsRefs, getWorkspaceItemTitle } from '../../libenjc/enjc-workspace';
import { COMMAND_ELEMENT } from 'src/utils/platform';

interface IProps {
  readonly workspaces: ReadonlyArray<EnjicalcWorkspaceItemsRefs>;
}

export const SearchWorkspaces = ({ workspaces }: IProps): ReactElement => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState<boolean>(false);

  const handleOpenSearch = React.useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        handleOpenSearch();
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, [handleOpenSearch]);

  return (
    <div className="flex items-center gap-7">
      <div
        className="flex w-64 cursor-pointer items-center justify-between rounded-md border border-slate-300 bg-white px-3 py-1.5"
        onClick={handleOpenSearch}
      >
        <p className="text-sm text-muted-foreground">Search</p>
        <p className="text-sm text-muted-foreground">
          <span className="text-xs">{COMMAND_ELEMENT}K</span>
        </p>
      </div>

      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder="Search workspaces ..." />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          {workspaces.map((workspace) => {
            const workspaceColor = getStringColor(workspace.id);

            return (
              <CommandGroup key={workspace.id}>
                <CommandItem
                  onSelect={() => navigate(`/workspace/${workspace.id}`)}
                  className="cursor-pointer hover:bg-gray-200"
                >
                  <div className="mr-2 size-[12px]" style={{ backgroundColor: `${workspaceColor}` }} />
                  <span>{getWorkspaceItemTitle(workspace) || 'Untitled'}</span>
                </CommandItem>
              </CommandGroup>
            );
          })}
        </CommandList>
      </CommandDialog>
    </div>
  );
};

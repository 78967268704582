import { ValueTreeCursor } from '../../enjc-symbol-value-tree/tree-cursor';
import { UValueTreePosition } from '../../enjc-symbol-value-tree/tree-position';
import { EnjcValueTreeNode } from '../../enjc-symbol-value-tree';
import { IValueTreeViewContext } from '../model';
import { getTreeCursorNVTV } from './getTreeCursorNVTV';
import { getNodeByKeyNVTV } from './getNodeByKeyNVTV';

interface ValueCursorPositionNode {
  valueCursor: ValueTreeCursor;
  cursorPosition: UValueTreePosition;
  positionNode: EnjcValueTreeNode;
}

export const getCursorPositionNode = (vtvCtx: IValueTreeViewContext): ValueCursorPositionNode | undefined => {
  const valueCursor = getTreeCursorNVTV(vtvCtx);
  const cursorPosition = valueCursor.position;
  if (!cursorPosition) {
    return undefined;
  }
  const positionNode = getNodeByKeyNVTV(vtvCtx, valueCursor.position.nodeKey);
  return {
    valueCursor,
    cursorPosition,
    positionNode,
  };
};

import { mkAtomDelta } from '../../enjc-delta';
import { EnjcWorkspaceItemVisibility, EnjicalcId } from '../../enjc-workspace';
import { IEnjcWorkspaceLocalHistory } from '../interfaces';
import { EnjicalcWorkspaceUpdateMessage, ItemCreateEntry, WorkspaceEditHistoryEntry } from '../model';
import { mkWorkspaceEditHistoryEntry } from '../utils';

// TODO: deprecate and remove
export interface IWorkspaceUpdateResult extends EnjicalcWorkspaceUpdateMessage {
  readonly createdIds: ReadonlyArray<{
    readonly __typename: 'EnjcIdMeta';
    readonly id: EnjicalcId;
    readonly original: string;
  }>;
  // readonly updateValue: {
  //   readonly __typename: 'EnjcWorkspaceUpdateValue';
  //   readonly workspaces: EnjcWorkspaceDTO[];
  //   readonly sections: ReadonlyArray<EnjicalcSection>;
  //   readonly symbols: ReadonlyArray<EnjicalcSymbol>;
  // };
}

export const hCreateSection = (wlh: IEnjcWorkspaceLocalHistory): ItemCreateEntry => {
  // Allocate unique local id for the new section
  const lcrId = wlh.generateLocalCreateId();
  // Create history entry
  const hEntry = mkWorkspaceEditHistoryEntry(
    {
      section: [
        {
          id: lcrId,
          visibility: mkAtomDelta(EnjcWorkspaceItemVisibility.Deleted, EnjcWorkspaceItemVisibility.Visible),
        },
      ],
    },
    { title: 'Create Section', actComments: ['hCreateSection'] },
  );
  return {
    historyEntry: hEntry,
    itemId: lcrId,
  };
  // Add history entry
  // return wlh.performEdit(hEntry).then((updateResult) => {
  //   // console.log(updateResult);
  //   // Extract created section from the result
  //   // const allocatedId = extractUpdateAllocatedId(updateResult, lcrId);
  //   // if (!allocatedId) {
  //   //   throw Error('Cannot find allocated ID for the created section');
  //   // }
  //   // const createdSection = updateResult.updateValue.sections.find((upSe) => upSe.id === allocatedId.id);
  //   const createdSection = updateResult.sections.find((upSe) => upSe.id === lcrId);
  //   if (!createdSection) {
  //     throw Error(`Cannot find created section with ID '${lcrId}'`);
  //   }
  //   return createdSection;
  // });
};

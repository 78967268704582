import { calculateStringDelta } from '../../enjc-delta';
import { EnjicalcWorkspaceItemsRefs, getWorkspaceItemAuxMetaStrEntry } from '../../enjc-workspace';
import { WorkspaceEditHistoryEntry } from '../model';
import { mkWorkspaceEditHistoryEntryDeprecated } from '../utils';

export const editSetWorkspaceAuxMetaStrEntry = (
  workspace: EnjicalcWorkspaceItemsRefs,
  name: string,
  valueStr: string,
): WorkspaceEditHistoryEntry => {
  const existingAuxMetaEntry = getWorkspaceItemAuxMetaStrEntry(workspace, name);
  const workspaceDeltaPart = calculateStringDelta(existingAuxMetaEntry, valueStr);
  return mkWorkspaceEditHistoryEntryDeprecated('editSetWorkspaceAuxMetaStrEntry', {
    workspaceSelf: workspaceDeltaPart && [{ id: workspace.id, auxMeta: [{ name, value: workspaceDeltaPart }] }],
  });
};

import { IFunctionBinaryOperator } from '../../enjc-value-operators';
import { isTreeNodeFunction, EnjcValueTreeNode, mkValueTreeNodeRef } from '../../enjc-symbol-value-tree/tree-node';
import {
  isTreePositionNode,
  isTreePositionNodeDraft,
  UValueTreePosition,
} from '../../enjc-symbol-value-tree/tree-position';
import { assembleOperatorsChain } from '../../enjc-symbol-value-tree/tree-operators';
import { mkTreeCursorNode } from '../../enjc-symbol-value-tree/tree-cursor';
import { ValueTreeClientDelta, calculateNodeDeltaNVTV } from '../../enjc-symbol-value-tree/tree-editing';
import {
  constructNodeOperatorsChainNVTV,
  insertOperatorsChainLinkNVTV,
} from '../../enjc-symbol-value-tree/tree-operators/utils-nvtv';
import { IValueTreeViewContext } from '../model';
import { getTreeNodeParentInfoOrUndefinedNVTV } from './getTreeNodeParentInfoOrUndefinedNVTV';
import { prepareNewOperatorOperandsNVTV } from './prepareNewOperatorOperandsNVTV';

// Create operand and add operator
export const addBinaryOperatorVvCtx = (
  vtvCtx: IValueTreeViewContext,
  node: EnjcValueTreeNode,
  position: UValueTreePosition,
  operator: IFunctionBinaryOperator,
): ValueTreeClientDelta => {
  // Existing operand is right if operator added at function or at the beginning of a node
  const existingOperandRight =
    (isTreePositionNodeDraft(position) && isTreeNodeFunction(node)) ||
    (isTreePositionNode(position) && !position.atEnd);

  const [existingOperand, createdOperand] = prepareNewOperatorOperandsNVTV(
    vtvCtx,
    node,
    position,
    existingOperandRight,
  );

  const { chainRootNode, operatorsChain } = constructNodeOperatorsChainNVTV(vtvCtx, existingOperand);
  const chainParentInfo = getTreeNodeParentInfoOrUndefinedNVTV(vtvCtx, chainRootNode);

  const nextOperatorsChain = insertOperatorsChainLinkNVTV(
    vtvCtx,
    operatorsChain,
    existingOperand,
    operator,
    createdOperand,
    existingOperandRight,
  );

  const assembledOperatorsChain = assembleOperatorsChain(nextOperatorsChain);

  const nextChainParent: ReadonlyArray<EnjcValueTreeNode> = chainParentInfo
    ? [
        {
          ...chainParentInfo.node,
          funcArgs: [
            ...chainParentInfo.node.funcArgs.slice(0, chainParentInfo.index),
            mkValueTreeNodeRef(assembledOperatorsChain.rootNode.key),
            ...chainParentInfo.node.funcArgs.slice(chainParentInfo.index + 1),
          ],
        },
      ]
    : [];

  const nodesUpsert = [createdOperand, existingOperand, ...nextChainParent, ...assembledOperatorsChain.updatedNodes];
  const nodesDelta = nodesUpsert.map((nUp) => calculateNodeDeltaNVTV(vtvCtx, nUp));

  // vtvCtx.symbol.valueTree.nodes.map((n) => {
  //   console.log(`[DEBUG] existing node '${n.key}' args: ${n.arguments.map((a) => a.key)}`);
  // });

  // nodesDelta.map((n) => {
  //   console.log(`[DEBUG] node '${n.key}' delta args: ${JSON.stringify(n.arguments)}`);
  // });

  return {
    delta: {
      nodes: nodesDelta,
    },
    cursor: mkTreeCursorNode(existingOperandRight ? existingOperand.key : createdOperand.key, false),
  };
};

import { EnjcKeyListInput } from '../../enjicalc-graphql';
import { EnjcKeyListDeltaEntry } from '../model';

export const keyListDeltaToInput = (delta: EnjcKeyListDeltaEntry, toUndo: boolean): EnjcKeyListInput => {
  return {
    // TODO: check when this should be undefined
    position: delta.slicePosition ?? 0,
    count: toUndo ? delta.sliceAfter?.length : delta.sliceBefore?.length,
    value: toUndo ? delta.sliceBefore : delta.sliceAfter,
  };
};

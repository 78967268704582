import React, { ReactElement } from 'react';
import { TextPopover } from '../../misc';

interface IProps {
  name: string;
  text: string;
  onSubmit?: (v: string) => void;
}

export const SheetHeaderFieldPopoverF = ({ name, text, onSubmit }: IProps): ReactElement => {
  const hoverClasses = onSubmit ? 'hover:bg-[#f4f8fa] hover:cursor-pointer' : '';

  return (
    <TextPopover initialValue={text} onSave={onSubmit}>
      <div className={`sheetHeader flex h-auto w-full ${hoverClasses}`}>
        <span className="min-w-fit">{`${name}:`}</span>
        <span className="size-auto pl-1.5 text-black">{text || 'Untitled'}</span>
      </div>
    </TextPopover>
  );
};

export const SheetHeaderFieldPopover = React.memo(SheetHeaderFieldPopoverF);

import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

export const ErrorRoute: React.FC = () => {
  const error = useRouteError();
  console.error('Error route visited', error);

  if (isRouteErrorResponse(error)) {
    return (
      <div className="container mx-auto px-4">
        <h1 className="mb-4 text-3xl font-bold">Oops!</h1>
        <p className="mb-2">Error at {window.location.href} has occurred</p>
        <p className="mb-2">
          Status: {error.status} ({error.statusText})
        </p>
        {error.data?.message && <p className="mb-2">{error.data.message}</p>}
      </div>
    );
  } else {
    return (
      <div className="container mx-auto px-4">
        <p className="mb-2">Unknown error has occurred</p>
      </div>
    );
  }
};

import { findWorkspaceItemContainers, getEnjcSymbol, TSymbolId, EnjcWorkspaceDTO } from '../../enjc-workspace';
import { EnjcWorkspaceSectionDelta, WorkspaceEditHistoryEntry } from '../../enjc-workspace-editing';
import { mkWorkspaceEditHistoryEntryDeprecated, changeMarkSymbolHidden } from '../utils';

export const editHideSymbol = (workspace: EnjcWorkspaceDTO, symbolId: TSymbolId): WorkspaceEditHistoryEntry => {
  const existingSymbol = getEnjcSymbol(workspace, symbolId);
  const symbolContainers = findWorkspaceItemContainers(workspace, symbolId);
  const containerDeltas: EnjcWorkspaceSectionDelta[] = symbolContainers.map((iCt) => {
    return {
      id: iCt.container.id,
      items: [{ slicePosition: iCt.index, sliceBefore: [iCt.container.items[iCt.index].id] }],
    };
  });
  const symbolDelta = changeMarkSymbolHidden(existingSymbol);
  return mkWorkspaceEditHistoryEntryDeprecated('editMarkSymbolHidden', {
    section: containerDeltas.length > 0 ? containerDeltas : undefined,
    symbol: symbolDelta && [symbolDelta],
  });
};

import { EnjicalcSheet } from '../model';
import {
  AUX_META_WORKSPACE_SHEET_APPROVED_DATE,
  AUX_META_WORKSPACE_SHEET_APPROVED_NAME,
  AUX_META_WORKSPACE_SHEET_CHECKED_DATE,
  AUX_META_WORKSPACE_SHEET_CHECKED_NAME,
  AUX_META_WORKSPACE_SHEET_SUBMISSION_DATE,
} from '../constants';
import { getWorkspaceItemAuxMetaStrEntry } from './getWorkspaceItemAuxMetaStrEntry';

export interface ISheetAuxMeta {
  submissionDate: string;
  checkedName: string;
  checkedDate: string;
  approvedName: string;
  approvedDate: string;
}

export const getSheetAuxMeta = (sheet: EnjicalcSheet): ISheetAuxMeta => {
  const submissionDate = getWorkspaceItemAuxMetaStrEntry(sheet, AUX_META_WORKSPACE_SHEET_SUBMISSION_DATE);
  const checkedName = getWorkspaceItemAuxMetaStrEntry(sheet, AUX_META_WORKSPACE_SHEET_CHECKED_NAME);
  const checkedDate = getWorkspaceItemAuxMetaStrEntry(sheet, AUX_META_WORKSPACE_SHEET_CHECKED_DATE);
  const approvedName = getWorkspaceItemAuxMetaStrEntry(sheet, AUX_META_WORKSPACE_SHEET_APPROVED_NAME);
  const approvedDate = getWorkspaceItemAuxMetaStrEntry(sheet, AUX_META_WORKSPACE_SHEET_APPROVED_DATE);
  return { submissionDate, checkedName, checkedDate, approvedName, approvedDate };
};

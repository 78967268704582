import React, { ReactElement } from 'react';
import { IValueCompositeViewComponents, ISymbolValueViewOptions } from './interfaces';
import { SymbolValueNodeCompositeView } from './components';
import { useValueTreeViewContext } from '../../enjc-react-ui';
import { getRootNodeNVTV } from '../../../enjc-value-view-ctx';
import { UValueHintItem } from '../../../../libenjc/enjc-value-view-ctx/model/IValueTreeViewContext';

interface IProps {
  readonly viewComponents: IValueCompositeViewComponents;
  readonly viewOptions: ISymbolValueViewOptions;
  readonly onNodeClick: (nodeKey: string) => void;
  readonly onHintClick?: (hint: UValueHintItem) => void;
  readonly noRootContainer?: boolean;
}

export const EnjcSymbolValueCompositeView = ({
  viewComponents,
  viewOptions,
  onNodeClick,
  onHintClick,
  noRootContainer,
}: IProps): ReactElement => {
  const { rootViewComp: RootView } = viewComponents;
  const vtvCtx = useValueTreeViewContext();
  const rootNode = getRootNodeNVTV(vtvCtx);

  if (!rootNode) {
    return <>{'Empty Value'}</>;
  }

  return !!noRootContainer ? (
    <SymbolValueNodeCompositeView
      node={rootNode}
      viewComponents={viewComponents}
      viewOptions={viewOptions}
      onNodeClick={onNodeClick}
      onHintClick={onHintClick}
    />
  ) : (
    <RootView>
      <SymbolValueNodeCompositeView
        node={rootNode}
        viewComponents={viewComponents}
        viewOptions={viewOptions}
        onNodeClick={onNodeClick}
        onHintClick={onHintClick}
      />
    </RootView>
  );
};

import { EnjcValueTreeNode } from '../../tree-node';
import { EnjcValueTreeNodeDelta } from '../../../enjc-workspace-editing/model';
import { calculateKeyListDelta, calculateStringDelta, calculateKeySetDelta } from '../../../enjc-delta';
import { EnjcValueTreeNodeMode } from '../../../../generated/graphql/types';
import { mkLiteralVoidNull } from '../../../enjc-literal';

export const calculateNodeDelta = (
  nodeBefore: EnjcValueTreeNode | undefined,
  nodeAfter: EnjcValueTreeNode,
): EnjcValueTreeNodeDelta => {
  // FIXME: review
  return {
    key: nodeAfter.key,
    tags: calculateKeySetDelta(nodeBefore?.tags ?? [], nodeAfter.tags),
    draft: calculateStringDelta(nodeBefore?.draft ?? '', nodeAfter.draft),
    mode:
      nodeBefore?.mode !== nodeAfter.mode
        ? { atomBefore: nodeBefore?.mode ?? EnjcValueTreeNodeMode.Literal, atomAfter: nodeAfter.mode }
        : undefined,
    literal:
      nodeBefore?.literal !== nodeAfter.literal
        ? { atomBefore: nodeBefore?.literal ?? mkLiteralVoidNull(), atomAfter: nodeAfter.literal }
        : undefined,
    symbol:
      nodeBefore?.symbol !== nodeAfter.symbol
        ? { atomBefore: nodeBefore?.symbol?.id, atomAfter: nodeAfter.symbol?.id }
        : undefined,
    funcSpec:
      nodeBefore?.funcSpec !== nodeAfter.funcSpec
        ? { atomBefore: nodeBefore?.funcSpec?.id, atomAfter: nodeAfter.funcSpec?.id }
        : undefined,
    funcArgs: calculateKeyListDelta(
      nodeBefore?.funcArgs.map((n) => n.key) ?? [],
      nodeAfter?.funcArgs.map((n) => n.key) ?? [],
    ),
    removeAdd: nodeBefore ? undefined : 'add',
  };
};

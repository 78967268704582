import { EnjcWorkspaceSectionRefFragment, EnjcWorkspaceSymbolRefFragment } from '../../enjicalc-graphql';
import { applyKeyListDelta, EnjcKeyListDelta } from '../../enjc-delta';

type EnjcSectionItemRef = EnjcWorkspaceSectionRefFragment | EnjcWorkspaceSymbolRefFragment;

export const applySectionItemsDelta = (
  items: ReadonlyArray<EnjcSectionItemRef>,
  delta: EnjcKeyListDelta,
  getItemTypename: (id: string) => 'EnjcWorkspaceSymbol' | 'EnjcWorkspaceSection',
): ReadonlyArray<EnjcSectionItemRef> => {
  return applyKeyListDelta(
    items.map((item) => item.id),
    delta,
  ).map((itemId) => ({ __typename: getItemTypename(itemId), id: itemId }));
};

import { EnjcValueTreeNode } from '../../enjc-symbol-value-tree';
import { EnjcValueTreeFragment } from '../../enjicalc-graphql';
import { TValueTreeNodeKey } from '../../enjc-symbol-value-tree/tree-node';
import { applyValueTreeNodeDelta } from './applyValueTreeNodeDelta';
import { mkLiteralVoidNull } from '../../enjc-literal';
import { EnjcValueTreeDelta } from '../model';
import { findValueTreeRootNode } from './findValueTreeRootNode';

export const applyValueTreeDelta = (
  valueTree: EnjcValueTreeFragment,
  delta: EnjcValueTreeDelta,
): EnjcValueTreeFragment => {
  if (!delta || !delta.nodes || delta.nodes.length === 0) {
    return valueTree;
  }

  const modifiedNodes = delta.nodes.reduce(
    (acc, dNode) => {
      const currentNode = acc.get(dNode.key);
      const updatedNode = applyValueTreeNodeDelta(currentNode, dNode);
      if (updatedNode) {
        acc.set(dNode.key, updatedNode);
      } else {
        acc.delete(dNode.key);
      }
      return acc;
    },
    new Map<TValueTreeNodeKey, EnjcValueTreeNode>(valueTree.nodes.map((n) => [n.key, n])),
  );

  const nextNodes = [...modifiedNodes.values()];
  const nextRootNode = findValueTreeRootNode(nextNodes);

  return {
    __typename: 'EnjcValueTree',
    rootNode: nextRootNode === undefined ? undefined : { __typename: 'EnjcValueTreeNode', key: nextRootNode.key },
    nodes: nextNodes,
    result: mkLiteralVoidNull(),
  };
};

import React, { ReactElement } from 'react';
import { MdMoreHoriz, MdDriveFileRenameOutline, MdOutlineFileCopy, MdOutlineFileOpen } from 'react-icons/md';
import { EnjicalcWorkspace, TSectionId, EnjcWorkspaceDTO } from '../../libenjc/enjc-workspace';
import {
  editDeleteSection,
  EnjicalcWorkspaceUpdateMessage,
  WorkspaceEditHistoryEntry,
} from '../../libenjc/enjc-workspace-editing';
import { EnjcWorkspaceSectionFragment } from '../../libenjc/enjicalc-graphql';
import { IoCloseSharp } from 'react-icons/io5';
import { DeleteConfirmationPopover } from 'src/components/misc';
import { QUICK_START_TOUR_STEP_COUNT, useQuickStartTour } from 'src/hooks';
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from 'src/shadcn';

type TSheet = EnjcWorkspaceSectionFragment;

interface IProps {
  readonly workspace: EnjcWorkspaceDTO;
  readonly sheet: TSheet;
  readonly onSheetOpen: (sheetSectionId: TSectionId) => void;
  readonly onRenameTitle: (sheetSectionId: TSectionId) => void;
  readonly performWorkspaceEdit: (editEntry: WorkspaceEditHistoryEntry) => void;
}

const SheetMenuF = ({ workspace, sheet, onSheetOpen, performWorkspaceEdit, onRenameTitle }: IProps): ReactElement => {
  const { setCurrentStep } = useQuickStartTour();

  const [isOpenMenu, setIsOpenMenu] = React.useState(false);

  const handleDeleteSheet = React.useCallback(() => {
    setIsOpenMenu(false);
    const hEntry = editDeleteSection(workspace, sheet.id);
    performWorkspaceEdit(hEntry);
  }, [performWorkspaceEdit, sheet.id, workspace]);

  return (
    <DropdownMenu modal={false} open={isOpenMenu} onOpenChange={(open) => setIsOpenMenu(open)}>
      <DropdownMenuTrigger>
        <Button onClick={(e) => e.stopPropagation()} size={'sm'} aria-label={'workspace menu'} variant="ghost">
          <MdMoreHoriz />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent onClick={(e) => e.stopPropagation()} className="border border-transparent shadow-lg">
        <Button
          variant="ghost"
          onClick={() => {
            onSheetOpen(sheet.id);
            setCurrentStep(QUICK_START_TOUR_STEP_COUNT.AddSymbol);
          }}
          className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
        >
          <MdOutlineFileOpen size={17} className="mr-2 text-black" />
          {'Open sheet'}
        </Button>
        <Button
          variant="ghost"
          onClick={() => onRenameTitle(sheet.id)}
          className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
        >
          <MdDriveFileRenameOutline size={17} className="mr-2 text-black" />
          {'Rename sheet'}
        </Button>
        <Button
          variant="ghost"
          onClick={() => onRenameTitle(sheet.id)}
          className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
          disabled
        >
          <MdOutlineFileCopy size={17} className="mr-2 text-black" />
          {'Add to Global Library (Pro Version)'}
        </Button>

        <DeleteConfirmationPopover onConfirm={handleDeleteSheet}>
          <Button
            onClick={(e) => e.stopPropagation()}
            variant="ghost"
            className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
          >
            <IoCloseSharp size={17} className="mr-2 text-red-600" />
            {'Delete sheet'}
          </Button>
        </DeleteConfirmationPopover>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const SheetMenu = React.memo(SheetMenuF);

import React, { ReactElement, ReactNode } from 'react';

export const TreeFunctionCndView = ({
  argCnd,
  argTrue,
  argFalse,
}: {
  argCnd: ReactNode;
  argTrue: ReactNode;
  argFalse: ReactNode;
}): ReactElement => {
  return (
    <mrow>
      <mo>{'{'}</mo>
      <mtable columnalign={'left'}>
        <mtr>
          <mtd>{argTrue}</mtd>
          <mtd>
            <mtext>{'if '}</mtext>
            <mspace width={'5px'} />
            {argCnd}
          </mtd>
        </mtr>
        <mtr>
          <mtd>{argFalse}</mtd>
          <mtd>
            <mtext>otherwise.</mtext>
          </mtd>
        </mtr>
      </mtable>
    </mrow>
  );
};

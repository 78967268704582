import { EnjcValueTreeNode } from '../../enjc-symbol-value-tree';
import {
  applyAtomDelta,
  applyKeyListDelta,
  applyKeySetDelta,
  applyStringDelta,
  IEnjcAtomDelta,
} from '../../enjc-delta';
import { EnjcValueTreeNodeDelta } from '../model';
import {
  EnjcWorkspaceSymbolRefFragment,
  EnjcValueFunctionSpecRefFragment,
  TSymbolId,
  EnjcValueTreeNodeMode,
} from '../../enjicalc-graphql';
import { mkLiteralVoidNull } from '../../enjc-literal';
import { mkValueTreeNodeRef } from '../../enjc-symbol-value-tree/tree-node';
import { TValueFunctionId } from '../../enjc-value-math';

export const applyEnjcSymbolRefDelta = (
  value: EnjcWorkspaceSymbolRefFragment | undefined,
  delta: IEnjcAtomDelta<TSymbolId | undefined>,
): EnjcWorkspaceSymbolRefFragment | undefined => {
  const nextId = applyAtomDelta(value?.id, delta);
  if (!nextId) {
    return undefined;
  }
  return { __typename: 'EnjcWorkspaceSymbol', id: nextId };
};

export const applyEnjcFunctionRefDelta = (
  value: EnjcValueFunctionSpecRefFragment | undefined,
  delta: IEnjcAtomDelta<TValueFunctionId | undefined>,
): EnjcValueFunctionSpecRefFragment | undefined => {
  const nextId = applyAtomDelta(value?.id, delta);
  return nextId === undefined ? undefined : { __typename: 'EnjcValueFunctionSpec', id: nextId };
};

export const applyValueTreeNodeDelta = (
  node: EnjcValueTreeNode | undefined,
  delta: EnjcValueTreeNodeDelta,
): EnjcValueTreeNode | undefined => {
  if (delta.removeAdd === 'remove') {
    return undefined;
  }

  return {
    ...node,
    __typename: 'EnjcValueTreeNode',
    key: delta.key,
    tags: delta.tags ? applyKeySetDelta(node?.tags ?? [], delta.tags) : node?.tags ?? [],
    draft: delta.draft ? applyStringDelta(node?.draft ?? '', delta.draft) : node?.draft ?? '',
    mode: (delta.mode ? applyAtomDelta(node?.mode, delta.mode) : node?.mode) ?? EnjcValueTreeNodeMode.Literal,
    literal: (delta.literal ? applyAtomDelta(node?.literal, delta.literal) : node?.literal) ?? mkLiteralVoidNull(),
    symbol: delta.symbol ? applyEnjcSymbolRefDelta(node?.symbol ?? undefined, delta.symbol) : node?.symbol,
    funcSpec: delta.funcSpec ? applyEnjcFunctionRefDelta(node?.funcSpec ?? undefined, delta.funcSpec) : node?.funcSpec,
    funcArgs: delta.funcArgs
      ? applyKeyListDelta(node?.funcArgs.map((a) => a.key) ?? [], delta.funcArgs).map((aKey) =>
          mkValueTreeNodeRef(aKey),
        )
      : node?.funcArgs ?? [],
    result: node?.result ?? mkLiteralVoidNull(),
  };
};

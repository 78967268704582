import { EnjicalcWorkspaceItemsRefs } from '../../enjc-workspace';
import { WorkspaceEditHistoryEntry } from '../model';
import { editSetWorkspaceAuxMetaStrEntry } from './editSetWorkspaceAuxMetaStrEntry';
import { AUX_META_WORKSPACE_ITEM_TITLE } from '../../enjc-workspace/constants';

export const editSetWorkspaceTitle = (
  workspace: EnjicalcWorkspaceItemsRefs,
  title: string,
): WorkspaceEditHistoryEntry => {
  return editSetWorkspaceAuxMetaStrEntry(workspace, AUX_META_WORKSPACE_ITEM_TITLE, title);
};

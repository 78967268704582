import React, { ReactElement } from 'react';
import { EnjicalcSymbol } from '../../../libenjc/enjc-workspace';
import { TValueTreeNodeKey } from '../../../libenjc/enjc-symbol-value-tree/tree-node';
import { SymbolValueMathBlockView } from '../symbol-math-view';

interface IProps {
  readonly symbol: EnjicalcSymbol;
  readonly onValueTreeNodeSelected: (nodeKey: TValueTreeNodeKey) => void;
}

const SymbolValueTreeViewF = ({ symbol, onValueTreeNodeSelected }: IProps): ReactElement => {
  return (
    <SymbolValueMathBlockView
      symbol={symbol}
      showGlyph
      showSymbolic
      showNumeric
      // showResult
    />
  );
};

export const SymbolValueTreeView = React.memo(SymbolValueTreeViewF);

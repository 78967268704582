import { EnjcWorkspaceSelfDelta } from '../../enjc-workspace-editing';
import { EnjcWorkspaceSelfInput } from '../../enjicalc-graphql';
import { convertAuxMetaEntryDeltaToDiffInput } from './convertAuxMetaEntryDeltaToDiffInput';

export const convertWorkspaceMetaDeltaToDiffInput = (delta: EnjcWorkspaceSelfDelta): EnjcWorkspaceSelfInput => {
  return {
    id: delta.id,
    visibility: delta.visibility?.atomAfter,
    auxMeta: delta.auxMeta && delta.auxMeta.map((d) => convertAuxMetaEntryDeltaToDiffInput(d, false)),
  };
};

import { EnjcValueTreeNode } from '../../enjc-symbol-value-tree/tree-node';
import { mkTreeCursorNodeDraft } from '../../enjc-symbol-value-tree/tree-cursor';
import { ValueTreeClientDelta } from '../../enjc-symbol-value-tree/tree-editing';
import { createPlaceholderNodeNVTV } from '../../enjc-symbol-value-tree/tree-editing/utils/createPlaceholderNodeNVTV';
import { insertFunctionArgumentNVTV } from '../../enjc-symbol-value-tree/tree-editing/actions-nvtv';
import { calculateNodeDelta } from '../../enjc-symbol-value-tree/tree-navigation/actions';
import { findNonOperatorParentInfoNVTV } from './findNonOperatorParentInfoNVTV';
import { IValueTreeViewContext } from '../model';

export const addFunctionArgumentAtNVTV = (
  vtvCtx: IValueTreeViewContext,
  atNode: EnjcValueTreeNode,
  addAfter: boolean,
): ValueTreeClientDelta => {
  const nodeParentInfo = findNonOperatorParentInfoNVTV(vtvCtx, atNode);
  if (!nodeParentInfo) {
    return {
      cursor: undefined,
    };
  }

  // Create placeholder node
  const placeholderNode = createPlaceholderNodeNVTV(vtvCtx, []);
  const nodesDelta = [
    insertFunctionArgumentNVTV(nodeParentInfo.node, nodeParentInfo.index + (addAfter ? 1 : 0), placeholderNode),
    calculateNodeDelta(undefined, placeholderNode),
  ];

  return {
    delta: { nodes: nodesDelta },
    cursor: mkTreeCursorNodeDraft(placeholderNode.key, 0),
  };
};

import React, { ChangeEvent, ReactNode } from 'react';

interface IProps {
  initialValue: string;
  firstFieldRef: React.RefObject<HTMLInputElement>;
  onCancel: () => void;
  onSave: (v: string) => void;
  isHidden: boolean;
  validator: (value: string) => string;
  children?: (value: string) => ReactNode;
}

export const ValueInput = ({
  initialValue,
  firstFieldRef,
  onCancel,
  onSave,
  isHidden,
  validator,
  children,
}: IProps) => {
  const [value, setValue] = React.useState(initialValue);
  const [validationErrorMsg, setValidationErrorMsg] = React.useState('');
  const [hidden, setHidden] = React.useState(isHidden);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setValidationErrorMsg(validator(event.target.value));
  };

  React.useEffect(() => {
    setHidden(isHidden);
    if (!hidden) {
      setValue(initialValue);
      setValidationErrorMsg('');
    }
  }, [isHidden, hidden, initialValue]);

  const handleKeyDown = (event: { key: string }) => {
    if (!validationErrorMsg && event.key === 'Enter') {
      onSave(value);
    }
  };

  return (
    <div className="relative flex">
      <span className="absolute left-[22px] z-10 size-fit bg-white p-[3px_7px_0px_7px] text-[9px] text-gray-500">
        {validationErrorMsg || 'Press ↵ to save'}
      </span>
      {children && children(value)}
      <input
        ref={firstFieldRef}
        className={`my-2.5 ml-2.5 w-full font-mono text-xl ${
          !validationErrorMsg ? 'outline-black' : 'outline-red-500'
        } rounded-md border border-gray-300 px-3 py-2`}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={value}
      />
      <button className="mx-1.5 mt-0 text-black" onClick={onCancel} aria-label="Close">
        <svg
          className="size-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  );
};

import React, { createContext, ReactNode, useCallback } from 'react';

interface IPrintButtonTooltipContext {
  showPrintButtonTooltip: boolean;
  onToggle: (show: boolean) => void;
}

export const PrintButtonTooltipContext = createContext<IPrintButtonTooltipContext>({
  showPrintButtonTooltip: false,
  onToggle: () => {},
});

export const PrintButtonTooltipProvider = ({ children }: { children: ReactNode }) => {
  const [showPrintButtonTooltip, setShowPrintButtonTooltip] = React.useState<boolean>(false);

  const handleToggle = useCallback((show: boolean) => {
    setShowPrintButtonTooltip(show);
  }, []);

  const value = {
    showPrintButtonTooltip,
    onToggle: handleToggle,
  };

  return <PrintButtonTooltipContext.Provider value={value}>{children}</PrintButtonTooltipContext.Provider>;
};

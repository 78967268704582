import { EnjcWorkspaceRefItemsFragment } from '../../../generated/graphql/operations';
import { EnjicalcWorkspaceUpdateMessage } from '../../enjc-workspace-editing';

export const extractUpdateCreatedWorkspace = (
  updateResult: EnjicalcWorkspaceUpdateMessage,
): EnjcWorkspaceRefItemsFragment => {
  // TODO: review id extraction method
  const createdWorkspace = updateResult.updateValue.workspaces[0];
  if (!createdWorkspace) {
    throw Error(`Cannot find created workspace (no workspaces returned)'`);
  }
  return createdWorkspace;
};

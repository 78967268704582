import { IValueTreeViewContext, getNodeByKeyOrUndefinedNVTV } from '../../../enjc-value-view-ctx';
import { EnjcValueTreeNode } from '../../tree-node';
import { EnjcValueTreeNodeDelta } from '../../../enjc-workspace-editing/model';
import { calculateNodeDelta } from '../../tree-navigation/actions';

export const calculateNodeDeltaNVTV = (
  vtvCtx: IValueTreeViewContext,
  node: EnjcValueTreeNode,
): EnjcValueTreeNodeDelta => {
  const existingNode = getNodeByKeyOrUndefinedNVTV(vtvCtx, node.key);
  return calculateNodeDelta(existingNode, node);
};

import { EnjcValueTreeFragment } from '../../enjicalc-graphql';
import { mkLiteralVoidNull, UEnjcValueLiteral } from '../../enjc-literal';
import { TSymbolId } from '../../enjc-workspace';
import { evaluateValueTreeBranch } from './evaluateValueTreeBranch';

export const evaluateValueTree = (
  valueTree: EnjcValueTreeFragment,
  evaluatorSymbols: Map<TSymbolId, UEnjcValueLiteral>,
): UEnjcValueLiteral => {
  if (!valueTree.rootNode?.key) return mkLiteralVoidNull();
  return evaluateValueTreeBranch(valueTree, valueTree.rootNode.key, evaluatorSymbols);
};

import React from 'react';
import FocusLock from 'react-focus-lock';
import { ValueInput } from './ValueInput';
import { Popover, PopoverContent, PopoverTrigger } from 'src/shadcn';

interface IProps {
  initialValue: string;
  onSave?: (v: string) => void;
  children: React.ReactNode;
}

export const TextPopover = ({ initialValue, onSave, children }: IProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const firstFieldRef = React.useRef(null);

  const handleToggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <Popover open={!!onSave && isOpen} onOpenChange={handleToggle}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent side="bottom" align="start" className="w-[400px] p-0">
        <FocusLock returnFocus persistentFocus={false} className="rounded-lg">
          <ValueInput
            initialValue={initialValue}
            firstFieldRef={firstFieldRef}
            onCancel={handleToggle}
            onSave={(v) => {
              onSave && onSave(v);
              handleToggle();
            }}
            isHidden={!isOpen}
            validator={(v) => (v.length <= 100 && !/^\s*$/.test(v) ? '' : 'Invalid Entry')}
          >
            {(value) => (
              <div className="absolute bottom-0 left-[22px] z-10 size-fit bg-white px-[7px] text-[9px] text-[#999]">
                {value.length + '/100'}
              </div>
            )}
          </ValueInput>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};

import React, { ReactElement } from 'react';
import { EnjicalcSection } from '../../libenjc/enjc-workspace';
import { TSectionId } from '../../libenjc/enjicalc-graphql';
import { EnjcItemsTabs } from '../../components/enjc-items/enjc-items-tabs';
import { WorkspaceSheetView } from './WorkspaceSheetView';

interface IProps {
  readonly openSheets: ReadonlyArray<EnjicalcSection>;
  readonly activeSheet?: EnjicalcSection;
  readonly onSheetSelect: (sheetSectionId: TSectionId | undefined) => void;
  readonly onSheetClose: (sheetSectionId: TSectionId) => void;
}

export const WorkspaceSheetsTabs = ({ openSheets, activeSheet, onSheetSelect, onSheetClose }: IProps): ReactElement => {
  return (
    <EnjcItemsTabs
      items={openSheets}
      selectedItemId={activeSheet?.id || ''}
      onItemSelect={onSheetSelect}
      onItemClose={onSheetClose}
      itemView={WorkspaceSheetView}
    />
  );
};

import { EnjcWorkspaceSelfDelta } from '../model';
import { invertAtomDelta, invertAuxMetaDelta } from '../../enjc-delta';

export const invertWorkspaceMetaDelta = (delta: EnjcWorkspaceSelfDelta): EnjcWorkspaceSelfDelta => {
  return {
    id: delta.id,
    visibility: delta.visibility && invertAtomDelta(delta.visibility),
    auxMeta: delta.auxMeta && invertAuxMetaDelta(delta.auxMeta),
  };
};

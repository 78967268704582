import React, { ReactElement } from 'react';
import { SheetHeaderField } from './SheetHeaderField';
import { useUser } from '@clerk/clerk-react';
import { SheetHeaderDateField } from './SheetHeaderDateField';
import { enjcTimestampToDate } from '../../../utils';
import { EnjicalcSheet, getWorkspaceItemTitle, getSheetAuxMeta } from '../../../libenjc/enjc-workspace';

interface IProps {
  readonly workspaceTitle: string;
  readonly sheet: EnjicalcSheet;
  readonly setSubmissionDate: (value: Date | undefined) => void;
  readonly setSheetCheckedName: (value: string) => void;
  readonly setSheetApprovedName: (value: string) => void;
  readonly setCheckedDate: (value: Date | undefined) => void;
  readonly setApprovedDate: (value: Date | undefined) => void;
}

const SheetHeaderViewF = ({
  workspaceTitle,
  sheet,
  setSubmissionDate,
  setSheetCheckedName,
  setSheetApprovedName,
  setCheckedDate,
  setApprovedDate,
}: IProps): ReactElement => {
  const { user } = useUser();

  const {
    submissionDate: sheetSubmissionDate,
    checkedName: sheetCheckedName,
    checkedDate: sheetCheckedDate,
    approvedName: sheetApprovedName,
    approvedDate: sheetApprovedDate,
  } = getSheetAuxMeta(sheet);

  return (
    <div className="pb-5">
      <div className="flex h-fit border-y border-gray-300">
        <div className="w-1/2">
          <SheetHeaderField name="Title" text={getWorkspaceItemTitle(sheet)} />
          <SheetHeaderField name="Workspace" text={workspaceTitle} />
          <SheetHeaderField name="Author" text={user?.fullName ? user?.fullName : 'Unknown'} />
          {/*<SheetHeaderDateField name="Date" date={enjcTimestampToDate(sheet.version.timestamp)} />*/}
          <SheetHeaderDateField
            name="Date"
            date={sheetSubmissionDate ? new Date(Number(sheetSubmissionDate)) : null}
            onSubmit={setSubmissionDate}
          />
        </div>

        <div className="w-1/2 pl-1">
          <SheetHeaderField name="Checked by" text={sheetCheckedName || 'Not Checked'} onSubmit={setSheetCheckedName} />
          <SheetHeaderDateField
            name="Checked date"
            date={sheetCheckedDate ? new Date(Number(sheetCheckedDate)) : null}
            onSubmit={setCheckedDate}
          />
          <SheetHeaderField
            name="Approved by"
            text={sheetApprovedName || 'Not approved'}
            onSubmit={setSheetApprovedName}
          />
          <SheetHeaderDateField
            name="Approved date"
            date={sheetApprovedDate ? new Date(Number(sheetApprovedDate)) : null}
            onSubmit={setApprovedDate}
          />
        </div>
      </div>
    </div>
  );
};

export const SheetHeaderView = React.memo(SheetHeaderViewF);

import { createContext } from 'react';
import { IEnjicalcWorkspaceEditActions, IEnjicalcWorkspaceEditHistoryContext } from '../../../enjc-context';
import { enjcEmptyWorkspace } from '../skeletons';
import { EnjicalcWorkspaceTracker } from '../../../enjc-workspace-editing/model/EnjicalcWorkspaceTracker';

export const EnjicalcWorkspaceEditHistoryContext = createContext<EnjicalcWorkspaceTracker>({
  currentWorkspace: enjcEmptyWorkspace,
  workspaceEditHistory: {
    currentState: enjcEmptyWorkspace,
    historyPosition: 0,
    historyEntries: [],
    localIdCounter: 0,
  },
  performEdit: () => Promise.reject(),
  generateLocalCreateId: () => '',
  performUndo: () => {},
  performUndoMany: (steps: number) => {},
  performRedo: () => {},
  performReset: () => {},
  performSave: () => {},
  // FIXME: review
  performEditAction: <IEnjicalcWorkspaceEditActions>{},
});

import { ValueTreeFunctionInvalidArgumentAtError } from '../../tree-errors';
import { EnjcValueTreeNode } from '../../tree-node';
import { EnjcValueTreeNodeDelta } from '../../../enjc-workspace-editing/model';

export const insertFunctionArgumentNVTV = (
  treeFunction: EnjcValueTreeNode,
  at: number,
  argumentNode: EnjcValueTreeNode,
): EnjcValueTreeNodeDelta => {
  if (at < 0 || at > treeFunction.funcArgs.length) {
    throw new ValueTreeFunctionInvalidArgumentAtError(at, treeFunction.key, treeFunction.funcArgs.length);
  }

  return {
    key: treeFunction.key,
    funcArgs: [{ slicePosition: at, sliceAfter: [argumentNode.key] }],
  };
};

import { WorkspaceItemContainerInfo, EnjcWorkspaceDTO } from '../model';

export const findWorkspaceItemContainer = (
  workspace: EnjcWorkspaceDTO,
  itemId: string,
): WorkspaceItemContainerInfo | undefined =>
  workspace.sections
    .map((se) => {
      const itemIndex = se.items.findIndex((seIt) => seIt.id === itemId);
      return itemIndex >= 0 ? { container: se, index: itemIndex } : undefined;
    })
    .find((icf) => icf !== undefined);

import { IFunctionBinaryOperator } from '../../../enjc-value-operators';
import { EnjcValueTreeNode } from '../../tree-node';
import { ValueTreeClientDelta } from '../../tree-editing';
import { IValueTreeViewContext } from '../../../enjc-value-view-ctx';

export const applyModifierToOperatorNVTV = (
  vtvCtx: IValueTreeViewContext,
  operatorNode: EnjcValueTreeNode,
  nodeOperator: IFunctionBinaryOperator,
  operator: IFunctionBinaryOperator,
): ValueTreeClientDelta => {
  // TODO: implement operator modification (e.g. '>' and '=' result in '≥'

  // FIXME: process operators chain

  // Replace operator function id
  return {
    delta: {
      nodes: [
        {
          key: operatorNode.key,
          funcSpec: { atomBefore: nodeOperator.id, atomAfter: operator.id },
        },
      ],
    },
  };
};

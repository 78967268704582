import React, { ReactElement, useCallback } from 'react';
import { Popover, PopoverContent, PopoverTrigger, Calendar } from 'src/shadcn';

interface IProps {
  name: string;
  date: Date | null;
  onSubmit?: (value: Date | undefined) => void;
}

export const SheetHeaderDateFieldPopoverF = ({ name, date, onSubmit }: IProps): ReactElement => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(date ?? undefined);

  const handleSubmit = useCallback(
    (currentDate: Date | undefined) => {
      setSelectedDate(currentDate);
      onSubmit && onSubmit(currentDate);
      setIsPopoverOpen(false);
    },
    [onSubmit],
  );

  return (
    <div className="sheetHeader flex h-6 w-full cursor-pointer">
      <span>{`${name}:`}</span>

      <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <PopoverTrigger asChild>
          <span className="w-auto pl-1.5 text-black">{selectedDate ? selectedDate.toLocaleDateString() : 'N/A'}</span>
        </PopoverTrigger>
        <PopoverContent
          className="w-auto p-0"
          align="start"
          style={{ transform: 'scale(0.85)', position: 'absolute', top: -30, left: -60 }}
        >
          <Calendar mode="single" selected={selectedDate} onSelect={handleSubmit} initialFocus />
        </PopoverContent>
      </Popover>
    </div>
  );
};

export const SheetHeaderDateFieldPopover = React.memo(SheetHeaderDateFieldPopoverF);

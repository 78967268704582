export type TGraphQlId = string;
export type TTimestamp = number;
export type EnjicalcId = TGraphQlId;
export type TUserId = EnjicalcId;
export type TWorkspaceId = EnjicalcId;
export type TSectionId = EnjicalcId;
export type TSymbolId = EnjicalcId;
export type TSectionItemId = TSectionId | TSymbolId;
export type TWorkspaceItemId = TSectionId | TSymbolId;
export type TEnjicalcEntityId = TWorkspaceId | TWorkspaceItemId;
export type TValueFunctionId = EnjicalcId;
export type TValueTreeNodeKey = string;

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: EnjicalcId; output: EnjicalcId };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  EnjcValueTreeNodeKey: { input: TValueTreeNodeKey; output: TValueTreeNodeKey };
  Timestamp: { input: TTimestamp; output: TTimestamp };
};

export enum ApiVersion {
  V01 = 'V01',
  V02 = 'V02',
  V03 = 'V03',
  V04 = 'V04',
  V05 = 'V05',
  V06 = 'V06',
  V07 = 'V07',
  V08 = 'V08',
  V09 = 'V09',
  V10 = 'V10',
}

export type EnjcAuxMeta = {
  readonly __typename: 'EnjcAuxMeta';
  readonly items: ReadonlyArray<UEnjcAuxMetaEntry>;
};

export type EnjcAuxMetaEntryDelta = {
  readonly __typename: 'EnjcAuxMetaEntryDelta';
  readonly name: Scalars['String']['output'];
  readonly value: ReadonlyArray<EnjcStringDeltaEntry>;
};

export type EnjcAuxMetaInput = {
  readonly name: Scalars['String']['input'];
  readonly valueStr?: InputMaybe<ReadonlyArray<EnjcStringInput>>;
};

export type EnjcAuxMetaStringEntry = {
  readonly __typename: 'EnjcAuxMetaStringEntry';
  readonly name: Scalars['String']['output'];
  readonly valueStr: Scalars['String']['output'];
};

export type EnjcIdMeta = {
  readonly __typename: 'EnjcIdMeta';
  readonly id: Scalars['ID']['output'];
  readonly original: Scalars['String']['output'];
};

export type EnjcKeyDelta = {
  readonly __typename: 'EnjcKeyDelta';
  readonly valueAfter?: Maybe<Scalars['String']['output']>;
  readonly valueBefore?: Maybe<Scalars['String']['output']>;
};

export type EnjcKeyListDeltaEntry = IEnjcOrderedDeltaEntry & {
  readonly __typename: 'EnjcKeyListDeltaEntry';
  readonly position: Scalars['Int']['output'];
  readonly sliceAfter: ReadonlyArray<Scalars['String']['output']>;
  readonly sliceAfterLength: Scalars['Int']['output'];
  readonly sliceBefore: ReadonlyArray<Scalars['String']['output']>;
  readonly sliceBeforeLength: Scalars['Int']['output'];
};

export type EnjcKeyListInput = {
  readonly count?: InputMaybe<Scalars['Int']['input']>;
  readonly position?: InputMaybe<Scalars['Int']['input']>;
  readonly value?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type EnjcKeySetDelta = {
  readonly __typename: 'EnjcKeySetDelta';
  readonly subsetAfter: ReadonlyArray<Scalars['String']['output']>;
  readonly subsetAfterCount: Scalars['Int']['output'];
  readonly subsetBefore: ReadonlyArray<Scalars['String']['output']>;
  readonly subsetBeforeCount: Scalars['Int']['output'];
};

export type EnjcKeySetInput = {
  readonly add?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly clear?: InputMaybe<Scalars['Boolean']['input']>;
  readonly remove?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export enum EnjcRemoveAdd {
  Add = 'ADD',
  Remove = 'REMOVE',
}

export type EnjcStringDeltaEntry = IEnjcOrderedDeltaEntry & {
  readonly __typename: 'EnjcStringDeltaEntry';
  readonly position: Scalars['Int']['output'];
  readonly sliceAfter: Scalars['String']['output'];
  readonly sliceAfterLength: Scalars['Int']['output'];
  readonly sliceBefore: Scalars['String']['output'];
  readonly sliceBeforeLength: Scalars['Int']['output'];
};

export type EnjcStringInput = {
  readonly count?: InputMaybe<Scalars['Int']['input']>;
  readonly position?: InputMaybe<Scalars['Int']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

export type EnjcUser = {
  readonly __typename: 'EnjcUser';
  readonly auxMeta?: Maybe<EnjcAuxMeta>;
  readonly id: Scalars['ID']['output'];
};

export type EnjcUserInput = {
  readonly auxMeta?: InputMaybe<ReadonlyArray<EnjcAuxMetaInput>>;
  readonly id: Scalars['String']['input'];
};

export type EnjcValueFunctionSpec = IEnjcMathIdentifier & {
  readonly __typename: 'EnjcValueFunctionSpec';
  readonly aliases: ReadonlyArray<Scalars['String']['output']>;
  readonly auxMeta: EnjcAuxMeta;
  readonly defaultArgCount: Scalars['Int']['output'];
  readonly flagInternal: Scalars['Boolean']['output'];
  readonly glyph: Scalars['String']['output'];
  readonly glyphPlaintext: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
};

export type EnjcValueLiteralBoolean = {
  readonly __typename: 'EnjcValueLiteralBoolean';
  readonly bool: Scalars['Boolean']['output'];
};

export type EnjcValueLiteralDelta = {
  readonly __typename: 'EnjcValueLiteralDelta';
  readonly valueAfter: UEnjcValueLiteral;
  readonly valueBefore: UEnjcValueLiteral;
};

export type EnjcValueLiteralNumber = {
  readonly __typename: 'EnjcValueLiteralNumber';
  readonly numb: Scalars['Float']['output'];
};

export type EnjcValueLiteralString = {
  readonly __typename: 'EnjcValueLiteralString';
  readonly str: Scalars['String']['output'];
};

export type EnjcValueLiteralVoid = {
  readonly __typename: 'EnjcValueLiteralVoid';
  readonly cause: EnjcValueLiteralVoidCause;
};

export enum EnjcValueLiteralVoidCause {
  Null = 'NULL',
}

export type EnjcValueTree = {
  readonly __typename: 'EnjcValueTree';
  readonly nodes: ReadonlyArray<EnjcValueTreeNode>;
  readonly result: UEnjcValueLiteral;
  readonly rootNode?: Maybe<EnjcValueTreeNode>;
};

export type EnjcValueTreeDelta = {
  readonly __typename: 'EnjcValueTreeDelta';
  readonly nodes?: Maybe<ReadonlyArray<EnjcValueTreeNodeDelta>>;
};

export type EnjcValueTreeModeDelta = {
  readonly __typename: 'EnjcValueTreeModeDelta';
  readonly valueAfter: EnjcValueTreeNodeMode;
  readonly valueBefore: EnjcValueTreeNodeMode;
};

export type EnjcValueTreeNode = {
  readonly __typename: 'EnjcValueTreeNode';
  readonly draft: Scalars['String']['output'];
  readonly funcArgs: ReadonlyArray<EnjcValueTreeNode>;
  readonly funcSpec?: Maybe<EnjcValueFunctionSpec>;
  readonly key: Scalars['EnjcValueTreeNodeKey']['output'];
  readonly literal: UEnjcValueLiteral;
  readonly mode: EnjcValueTreeNodeMode;
  readonly result: UEnjcValueLiteral;
  readonly symbol?: Maybe<EnjcWorkspaceSymbol>;
  readonly tags: ReadonlyArray<Scalars['String']['output']>;
};

export type EnjcValueTreeNodeDelta = {
  readonly __typename: 'EnjcValueTreeNodeDelta';
  readonly draft?: Maybe<ReadonlyArray<EnjcStringDeltaEntry>>;
  readonly funcArgs?: Maybe<ReadonlyArray<EnjcKeyListDeltaEntry>>;
  readonly funcSpec?: Maybe<EnjcKeyDelta>;
  readonly key: Scalars['EnjcValueTreeNodeKey']['output'];
  readonly literal?: Maybe<EnjcValueLiteralDelta>;
  readonly mode?: Maybe<EnjcValueTreeModeDelta>;
  readonly removeAdd?: Maybe<EnjcRemoveAdd>;
  readonly symbol?: Maybe<EnjcKeyDelta>;
  readonly tags?: Maybe<EnjcKeySetDelta>;
};

export enum EnjcValueTreeNodeMode {
  Function = 'FUNCTION',
  Literal = 'LITERAL',
  Symbol = 'SYMBOL',
}

export type EnjcVersionMeta = {
  readonly __typename: 'EnjcVersionMeta';
  readonly counter: Scalars['Int']['output'];
  readonly timestamp: Scalars['Timestamp']['output'];
};

export type EnjcWorkspace = IEnjcWorkspaceItem & {
  readonly __typename: 'EnjcWorkspace';
  readonly ancestor?: Maybe<EnjcWorkspaceAncestor>;
  readonly auxMeta: EnjcAuxMeta;
  readonly created: Scalars['Timestamp']['output'];
  readonly id: Scalars['ID']['output'];
  readonly itemsVersion: EnjcVersionMeta;
  readonly owner: EnjcUser;
  readonly sections: ReadonlyArray<EnjcWorkspaceSection>;
  readonly sheetBookmarks: ReadonlyArray<EnjcWorkspaceSection>;
  readonly symbols: ReadonlyArray<EnjcWorkspaceSymbol>;
  readonly version: EnjcVersionMeta;
  readonly visibility: EnjcWorkspaceItemVisibility;
  readonly workspace: EnjcWorkspace;
};

export type EnjcWorkspaceAncestor = {
  readonly __typename: 'EnjcWorkspaceAncestor';
  readonly itemsVersionCounter: Scalars['Int']['output'];
  readonly workspaceId: Scalars['String']['output'];
};

export type EnjcWorkspaceCursor = {
  readonly __typename: 'EnjcWorkspaceCursor';
  readonly id: Scalars['ID']['output'];
  readonly session: EnjcWorkspaceSession;
  readonly timestamp: Scalars['Timestamp']['output'];
};

export type EnjcWorkspaceInput = {
  readonly items: ReadonlyArray<EnjcWorkspaceUpdateInput>;
};

export enum EnjcWorkspaceItemVisibility {
  Deleted = 'DELETED',
  Hidden = 'HIDDEN',
  Visible = 'VISIBLE',
}

export type EnjcWorkspaceItemVisibilityDelta = {
  readonly __typename: 'EnjcWorkspaceItemVisibilityDelta';
  readonly valueAfter: EnjcWorkspaceItemVisibility;
  readonly valueBefore: EnjcWorkspaceItemVisibility;
};

export type EnjcWorkspaceSection = IEnjcWorkspaceItem & {
  readonly __typename: 'EnjcWorkspaceSection';
  readonly auxMeta: EnjcAuxMeta;
  readonly created: Scalars['Timestamp']['output'];
  readonly id: Scalars['ID']['output'];
  readonly items: ReadonlyArray<UEnjcWorkspaceSectionItem>;
  readonly version: EnjcVersionMeta;
  readonly visibility: EnjcWorkspaceItemVisibility;
  readonly workspace: EnjcWorkspace;
};

export type EnjcWorkspaceSectionDelta = {
  readonly __typename: 'EnjcWorkspaceSectionDelta';
  readonly auxMeta?: Maybe<ReadonlyArray<EnjcAuxMetaEntryDelta>>;
  readonly id: Scalars['ID']['output'];
  readonly items?: Maybe<ReadonlyArray<EnjcKeyListDeltaEntry>>;
  readonly visibility?: Maybe<EnjcWorkspaceItemVisibilityDelta>;
};

export type EnjcWorkspaceSectionInput = {
  readonly auxMeta?: InputMaybe<ReadonlyArray<EnjcAuxMetaInput>>;
  readonly id: Scalars['String']['input'];
  readonly items?: InputMaybe<ReadonlyArray<EnjcKeyListInput>>;
  readonly visibility?: InputMaybe<EnjcWorkspaceItemVisibility>;
};

export type EnjcWorkspaceSelfDelta = {
  readonly __typename: 'EnjcWorkspaceSelfDelta';
  readonly auxMeta?: Maybe<ReadonlyArray<EnjcAuxMetaEntryDelta>>;
  readonly id: Scalars['ID']['output'];
  readonly sheetBookmarks?: Maybe<ReadonlyArray<EnjcKeyListDeltaEntry>>;
  readonly visibility?: Maybe<EnjcWorkspaceItemVisibilityDelta>;
};

export type EnjcWorkspaceSelfInput = {
  readonly auxMeta?: InputMaybe<ReadonlyArray<EnjcAuxMetaInput>>;
  readonly id: Scalars['String']['input'];
  readonly sheetBookmarks?: InputMaybe<ReadonlyArray<EnjcKeyListInput>>;
  readonly visibility?: InputMaybe<EnjcWorkspaceItemVisibility>;
};

export type EnjcWorkspaceSession = {
  readonly __typename: 'EnjcWorkspaceSession';
  readonly cursors: ReadonlyArray<EnjcWorkspaceCursor>;
  readonly id: Scalars['ID']['output'];
  readonly isActive: Scalars['Boolean']['output'];
  readonly user: EnjcUser;
};

export type EnjcWorkspaceSymbol = IEnjcMathIdentifier &
  IEnjcWorkspaceItem & {
    readonly __typename: 'EnjcWorkspaceSymbol';
    readonly aliases: ReadonlyArray<Scalars['String']['output']>;
    readonly auxMeta: EnjcAuxMeta;
    readonly created: Scalars['Timestamp']['output'];
    readonly glyph: Scalars['String']['output'];
    readonly glyphPlaintext: Scalars['String']['output'];
    readonly id: Scalars['ID']['output'];
    readonly valueCalcVersion: EnjcVersionMeta;
    readonly valueTree: EnjcValueTree;
    readonly version: EnjcVersionMeta;
    readonly visibility: EnjcWorkspaceItemVisibility;
    readonly workspace: EnjcWorkspace;
  };

export type EnjcWorkspaceSymbolDelta = {
  readonly __typename: 'EnjcWorkspaceSymbolDelta';
  readonly aliases?: Maybe<EnjcKeySetDelta>;
  readonly auxMeta?: Maybe<ReadonlyArray<EnjcAuxMetaEntryDelta>>;
  readonly glyph?: Maybe<ReadonlyArray<EnjcStringDeltaEntry>>;
  readonly glyphPlaintext?: Maybe<ReadonlyArray<EnjcStringDeltaEntry>>;
  readonly id: Scalars['ID']['output'];
  readonly valueTree?: Maybe<EnjcValueTreeDelta>;
  readonly visibility?: Maybe<EnjcWorkspaceItemVisibilityDelta>;
};

export type EnjcWorkspaceSymbolInput = {
  readonly aliases?: InputMaybe<EnjcKeySetInput>;
  readonly auxMeta?: InputMaybe<ReadonlyArray<EnjcAuxMetaInput>>;
  readonly glyph?: InputMaybe<ReadonlyArray<EnjcStringInput>>;
  readonly glyphPlaintext?: InputMaybe<ReadonlyArray<EnjcStringInput>>;
  readonly id: Scalars['String']['input'];
  readonly valueTree?: InputMaybe<ValueTreeInput>;
  readonly visibility?: InputMaybe<EnjcWorkspaceItemVisibility>;
};

export type EnjcWorkspaceSymbolTexFieldCursor = {
  readonly __typename: 'EnjcWorkspaceSymbolTexFieldCursor';
  readonly id: Scalars['ID']['output'];
  readonly session: EnjcWorkspaceSession;
  readonly symbol: EnjcWorkspaceSymbol;
  readonly textField: EnjcWorkspaceTextField;
  readonly textPosition?: Maybe<Scalars['Int']['output']>;
  readonly timestamp: Scalars['Timestamp']['output'];
};

export enum EnjcWorkspaceTextField {
  Glyph = 'Glyph',
}

export type EnjcWorkspaceUpdateDelta = {
  readonly __typename: 'EnjcWorkspaceUpdateDelta';
  readonly sections?: Maybe<ReadonlyArray<EnjcWorkspaceSectionDelta>>;
  readonly symbols?: Maybe<ReadonlyArray<EnjcWorkspaceSymbolDelta>>;
  readonly workspaces?: Maybe<ReadonlyArray<EnjcWorkspaceSelfDelta>>;
};

export type EnjcWorkspaceUpdateInput = {
  readonly section?: InputMaybe<ReadonlyArray<EnjcWorkspaceSectionInput>>;
  readonly symbol?: InputMaybe<ReadonlyArray<EnjcWorkspaceSymbolInput>>;
  readonly timestamp: Scalars['String']['input'];
  readonly title: Scalars['String']['input'];
  readonly workspaceSelf?: InputMaybe<ReadonlyArray<EnjcWorkspaceSelfInput>>;
};

export type EnjcWorkspaceUpdateMessage = {
  readonly __typename: 'EnjcWorkspaceUpdateMessage';
  readonly createdIds: ReadonlyArray<EnjcIdMeta>;
  readonly updateDelta: EnjcWorkspaceUpdateDelta;
  readonly updateValue: EnjcWorkspaceUpdateValue;
};

export type EnjcWorkspaceUpdateValue = {
  readonly __typename: 'EnjcWorkspaceUpdateValue';
  readonly sections: ReadonlyArray<EnjcWorkspaceSection>;
  readonly symbols: ReadonlyArray<EnjcWorkspaceSymbol>;
  readonly workspaces: ReadonlyArray<EnjcWorkspace>;
};

export type IEnjcMathIdentifier = {
  readonly aliases: ReadonlyArray<Scalars['String']['output']>;
  readonly glyph: Scalars['String']['output'];
  readonly glyphPlaintext: Scalars['String']['output'];
};

export type IEnjcOrderedDeltaEntry = {
  readonly position: Scalars['Int']['output'];
  readonly sliceAfterLength: Scalars['Int']['output'];
  readonly sliceBeforeLength: Scalars['Int']['output'];
};

export type IEnjcWorkspaceItem = {
  readonly auxMeta?: Maybe<EnjcAuxMeta>;
  readonly created: Scalars['Timestamp']['output'];
  readonly id: Scalars['ID']['output'];
  readonly version: EnjcVersionMeta;
  readonly visibility: EnjcWorkspaceItemVisibility;
  readonly workspace: EnjcWorkspace;
};

export type Mutation = {
  readonly __typename: 'Mutation';
  readonly createWorkspace: EnjcWorkspaceUpdateMessage;
  readonly forkWorkspace: EnjcWorkspaceUpdateMessage;
  readonly updateUser: EnjcUser;
  readonly updateWorkspace: EnjcWorkspaceUpdateMessage;
};

export type MutationCreateWorkspaceArgs = {
  diff?: InputMaybe<EnjcWorkspaceInput>;
};

export type MutationForkWorkspaceArgs = {
  diff?: InputMaybe<EnjcWorkspaceInput>;
  generation: Scalars['Int']['input'];
  workspace: Scalars['ID']['input'];
};

export type MutationUpdateUserArgs = {
  diff?: InputMaybe<EnjcUserInput>;
};

export type MutationUpdateWorkspaceArgs = {
  diff: EnjcWorkspaceInput;
  generation: Scalars['Int']['input'];
  workspace: Scalars['ID']['input'];
};

export type Query = {
  readonly __typename: 'Query';
  readonly apiVersion: ApiVersion;
  readonly searchWorkspaces?: Maybe<ReadonlyArray<EnjcWorkspace>>;
  readonly valueFunctions: ReadonlyArray<EnjcValueFunctionSpec>;
  readonly workspaceById: EnjcWorkspace;
  readonly workspaces: ReadonlyArray<EnjcWorkspace>;
};

export type QuerySearchWorkspacesArgs = {
  filter?: InputMaybe<WorkspacesFilterV1>;
};

export type QueryWorkspaceByIdArgs = {
  id: Scalars['ID']['input'];
};

export type Subscription = {
  readonly __typename: 'Subscription';
  readonly workspaceUpdate: EnjcWorkspaceUpdateMessage;
};

export type SubscriptionWorkspaceUpdateArgs = {
  workspace: Scalars['ID']['input'];
};

export type UEnjcAuxMetaEntry = EnjcAuxMetaStringEntry;

export type UEnjcValueLiteral =
  | EnjcValueLiteralBoolean
  | EnjcValueLiteralNumber
  | EnjcValueLiteralString
  | EnjcValueLiteralVoid;

export type UEnjcWorkspaceSectionItem = EnjcWorkspaceSection | EnjcWorkspaceSymbol;

export type ValueLiteralInput =
  | { readonly bool: Scalars['Boolean']['input']; readonly cause?: never; readonly numb?: never; readonly str?: never }
  | { readonly bool?: never; readonly cause: EnjcValueLiteralVoidCause; readonly numb?: never; readonly str?: never }
  | { readonly bool?: never; readonly cause?: never; readonly numb: Scalars['Float']['input']; readonly str?: never }
  | { readonly bool?: never; readonly cause?: never; readonly numb?: never; readonly str: Scalars['String']['input'] };

export type ValueTreeInput = {
  readonly nodes?: InputMaybe<ReadonlyArray<ValueTreeNodeInput>>;
};

export type ValueTreeNodeInput = {
  readonly draft?: InputMaybe<ReadonlyArray<EnjcStringInput>>;
  readonly funcArgs?: InputMaybe<ReadonlyArray<EnjcKeyListInput>>;
  readonly funcSpec?: InputMaybe<Scalars['ID']['input']>;
  readonly key: Scalars['EnjcValueTreeNodeKey']['input'];
  readonly literal?: InputMaybe<ValueLiteralInput>;
  readonly mode?: InputMaybe<EnjcValueTreeNodeMode>;
  readonly remove?: InputMaybe<Scalars['Boolean']['input']>;
  readonly symbol?: InputMaybe<Scalars['ID']['input']>;
  readonly tags?: InputMaybe<EnjcKeySetInput>;
};

export type WorkspacesFilterV1 = {
  readonly flagPublic?: InputMaybe<Scalars['Boolean']['input']>;
};

import React, { ChangeEvent } from 'react';
import { calculateStringDeltaEntry, EnjcStringDeltaEntry } from '../../../libenjc/enjc-delta';

interface IProps {
  readonly value: string;
  readonly isInvalid?: boolean;
  readonly onValueDelta: (delta: EnjcStringDeltaEntry) => void;
}

export const SymbolTextLineDeltaInput = ({ value, onValueDelta, isInvalid }: IProps) => {
  const handleChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const enjcStringDelta = calculateStringDeltaEntry(value, event.target.value);
      enjcStringDelta && onValueDelta(enjcStringDelta);
    },
    [value, onValueDelta],
  );

  return (
    <div className="flex">
      <input
        className={`w-[700px] rounded border p-2 font-mono text-xl ${isInvalid ? 'border-red-500' : 'border-gray-300'}`}
        onChange={handleChange}
        value={value}
        placeholder=""
      />
    </div>
  );
};

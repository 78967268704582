import { EnjcValueTreeNode } from '../../tree-node';
import { IOperatorsChain } from '../interfaces';
import { mkOperatorsChain } from './mkOperatorsChain';

export const replaceReducedOperatorsChainItem = (
  operatorsChain: IOperatorsChain,
  index: number,
  reducedItem: EnjcValueTreeNode,
): IOperatorsChain => {
  // TODO: Check arguments

  return index === 0
    ? mkOperatorsChain(reducedItem, operatorsChain.body.slice(1))
    : mkOperatorsChain(operatorsChain.head, [
        ...operatorsChain.body.slice(0, index - 1),
        { ...operatorsChain.body[index - 1], operandNode: reducedItem },
        ...operatorsChain.body.slice(index + 1),
      ]);
};

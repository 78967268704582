import { EnjcWorkspaceItemVisibility, EnjcWorkspaceSectionFragment } from '../../enjicalc-graphql';
import { applyAtomDelta, applyAuxMetaDelta } from '../../enjc-delta';
import { EnjicalcSection } from '../../enjc-workspace';
import { EnjcWorkspaceSectionDelta } from '../model';
import { applySectionItemsDelta } from './applySectionItemsDelta';

export const applySectionDelta = (
  existingSection: EnjcWorkspaceSectionFragment | undefined,
  delta: EnjcWorkspaceSectionDelta,
  getItemTypename: (id: string) => 'EnjcWorkspaceSymbol' | 'EnjcWorkspaceSection',
): EnjcWorkspaceSectionFragment => {
  const section: EnjicalcSection = existingSection || {
    __typename: 'EnjcWorkspaceSection',
    id: delta.id,
    created: Date.now() / 1000,
    version: { __typename: 'EnjcVersionMeta', timestamp: Date.now(), counter: 0 },
    visibility: EnjcWorkspaceItemVisibility.Deleted,
    auxMeta: { __typename: 'EnjcAuxMeta', items: [] },
    items: [],
  };
  return {
    ...section,
    visibility: delta.visibility ? applyAtomDelta(section.visibility, delta.visibility) : section.visibility,
    auxMeta: delta.auxMeta ? applyAuxMetaDelta(section.auxMeta, delta.auxMeta) : section.auxMeta,
    items: delta.items ? applySectionItemsDelta(section.items, delta.items, getItemTypename) : section.items,
  };
};

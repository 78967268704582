import React, { ReactElement, ReactNode } from 'react';
import { TWorkspaceId, mkEnjcWorkspaceWithMath } from '../../../enjc-workspace';
import { useEnjcMath, useEnjcWorkspace } from '../../enjc-react-client';
import { LoadingPlaceholder } from '../../../../components/misc';
import { ApolloErrorMessage, ErrorMessage } from '../../../../components/errors';
import { EnjicalcWorkspaceWithMathContext } from '../context';

interface IProps {
  readonly workspaceId: TWorkspaceId;
  readonly children: ReactNode;
}

export const WorkspaceContextLoader = ({ workspaceId, children }: IProps): ReactElement => {
  const { loading: mLoading, error: mError, math: enjcMath } = useEnjcMath();
  const { loading: wLoading, error: wError, workspace: enjcWorkspace } = useEnjcWorkspace(workspaceId);

  const enjcWorkspaceWithMath = React.useMemo(() => {
    return enjcWorkspace && enjcMath ? mkEnjcWorkspaceWithMath(enjcWorkspace, enjcMath.enjcFunctions) : undefined;
  }, [enjcWorkspace, enjcMath]);

  if (wLoading || mLoading) return <LoadingPlaceholder name={'Workspace'} />;
  if (wError) return <ApolloErrorMessage error={wError} />;
  if (mError) return <ApolloErrorMessage error={mError} />;
  if (!enjcWorkspaceWithMath)
    return <ErrorMessage description={'WorkspaceContextLoader: workspace or/and functions undefined'} />;

  return (
    <EnjicalcWorkspaceWithMathContext.Provider value={{ workspaceWithMath: enjcWorkspaceWithMath }}>
      {children}
    </EnjicalcWorkspaceWithMathContext.Provider>
  );
};

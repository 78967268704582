import {
  isLiteralBoolean,
  isLiteralNumber,
  mkLiteralBoolean,
  mkLiteralNumber,
  mkLiteralVoidNull,
  UEnjcValueLiteral,
} from '../../enjc-literal';
import { TValueFunctionId } from '../model';

export const evaluateValueFunction = (
  valueFunctionId: TValueFunctionId,
  evaluatorArgs: ReadonlyArray<UEnjcValueLiteral>,
): UEnjcValueLiteral => {
  if (valueFunctionId === 'fg.stub') {
    return mkLiteralVoidNull();
  }

  if (valueFunctionId === 'fg.pi' && evaluatorArgs.length === 0) {
    return mkLiteralNumber(Math.PI);
  }

  if (valueFunctionId === 'fg.noop' && evaluatorArgs.length === 1) {
    return evaluatorArgs[0];
  }

  if (valueFunctionId === 'fg.not' && evaluatorArgs.length === 1) {
    const arg1 = evaluatorArgs[0];
    if (isLiteralBoolean(arg1)) return mkLiteralBoolean(!arg1.bool);
  }

  if (valueFunctionId === 'fg.sum') {
    const res = evaluatorArgs.reduce(
      (acc: number | undefined, arg) =>
        acc === undefined ? undefined : isLiteralNumber(arg) ? acc + arg.numb : undefined,
      0,
    );
    if (res !== undefined) {
      return mkLiteralNumber(res);
    }
  }

  if (valueFunctionId === 'fg.min' && evaluatorArgs.length > 0 && isLiteralNumber(evaluatorArgs[0])) {
    const res = evaluatorArgs.reduce(
      (acc: number | undefined, arg) =>
        acc === undefined ? undefined : isLiteralNumber(arg) ? (acc > arg.numb ? arg.numb : acc) : undefined,
      evaluatorArgs[0].numb,
    );
    if (res !== undefined) {
      return mkLiteralNumber(res);
    }
  }

  if (valueFunctionId === 'fg.max' && evaluatorArgs.length > 0 && isLiteralNumber(evaluatorArgs[0])) {
    const res = evaluatorArgs.reduce(
      (acc: number | undefined, arg) =>
        acc === undefined ? undefined : isLiteralNumber(arg) ? (acc < arg.numb ? arg.numb : acc) : undefined,
      evaluatorArgs[0].numb,
    );
    if (res !== undefined) {
      return mkLiteralNumber(res);
    }
  }

  if (valueFunctionId === 'fg.cnd' && evaluatorArgs.length === 3) {
    const arg1 = evaluatorArgs[0];
    const arg2 = evaluatorArgs[1];
    const arg3 = evaluatorArgs[2];
    if (isLiteralBoolean(arg1)) return arg1.bool ? arg2 : arg3;
  }

  const unaryFunction = getUnaryFunction(valueFunctionId);
  if (unaryFunction && evaluatorArgs.length === 1) {
    const arg1 = evaluatorArgs[0];
    if (isLiteralNumber(arg1)) {
      const resultNumb = unaryFunction(arg1.numb);
      return isNaN(resultNumb) ? mkLiteralVoidNull() : mkLiteralNumber(resultNumb);
    }
  }

  const binaryFunction = getBinaryFunction(valueFunctionId);
  if (binaryFunction && evaluatorArgs.length === 2) {
    const arg1 = evaluatorArgs[0];
    const arg2 = evaluatorArgs[1];
    if (isLiteralNumber(arg1) && isLiteralNumber(arg2)) {
      const resultNumb = binaryFunction(arg1.numb, arg2.numb);
      return isNaN(resultNumb) ? mkLiteralVoidNull() : mkLiteralNumber(resultNumb);
    }
  }

  const binaryBinFunction = getBinaryBinFunction(valueFunctionId);
  if (binaryBinFunction && evaluatorArgs.length === 2) {
    const arg1 = evaluatorArgs[0];
    const arg2 = evaluatorArgs[1];
    if (isLiteralBoolean(arg1) && isLiteralBoolean(arg2)) {
      const resultBool = binaryBinFunction(arg1.bool, arg2.bool);
      return mkLiteralBoolean(resultBool);
    }
  }

  const binaryCmpFunction = getBinaryCmpFunction(valueFunctionId);
  if (binaryCmpFunction && evaluatorArgs.length === 2) {
    const arg1 = evaluatorArgs[0];
    const arg2 = evaluatorArgs[1];
    if (isLiteralNumber(arg1) && isLiteralNumber(arg2)) {
      const resultBool = binaryCmpFunction(arg1.numb, arg2.numb);
      return mkLiteralBoolean(resultBool);
    }
  }

  return mkLiteralVoidNull();
};

const getUnaryFunction = (valueFunctionId: TValueFunctionId): ((value: number) => number) | undefined => {
  switch (valueFunctionId) {
    case 'fg.uminus':
      return (a) => -a;
    case 'fg.uplus':
      return (a) => a;
    case 'fg.abs':
      return Math.abs;
    case 'fg.sqrt':
      return Math.sqrt;
    case 'fg.ln':
      return Math.log;
    case 'fg.exp':
      return Math.exp;
    case 'fg.sin':
      return Math.sin;
    case 'fg.cos':
      return Math.cos;
    case 'fg.tan':
      return Math.tan;
    case 'fg.asin':
      return Math.asin;
    case 'fg.acos':
      return Math.acos;
    case 'fg.atan':
      return Math.atan;
    default:
      return undefined;
  }
};

const getBinaryFunction = (valueFunctionId: TValueFunctionId): ((arg1: number, arg2: number) => number) | undefined => {
  switch (valueFunctionId) {
    case 'fg.add':
      return (a, b) => a + b;
    case 'fg.subtract':
      return (a, b) => a - b;
    case 'fg.multiply':
      return (a, b) => a * b;
    case 'fg.divide':
      return (a, b) => a / b;
    case 'fg.pow':
      return Math.pow;
    case 'fg.log':
      return (a, b) => Math.log(a) / Math.log(b);
    case 'fg.atan2':
      return Math.atan2;
    default:
      return undefined;
  }
};

const getBinaryBinFunction = (
  valueFunctionId: TValueFunctionId,
): ((arg1: boolean, arg2: boolean) => boolean) | undefined => {
  switch (valueFunctionId) {
    case 'fg.and':
      return (a, b) => a && b;
    case 'fg.or':
      return (a, b) => a || b;
    case 'fg.xor':
      return (a, b) => (a && b) || (!a && !b);
    default:
      return undefined;
  }
};

const getBinaryCmpFunction = (
  valueFunctionId: TValueFunctionId,
): ((arg1: number, arg2: number) => boolean) | undefined => {
  switch (valueFunctionId) {
    case 'fg.eq':
      return (a, b) => a === b;
    case 'fg.ne':
      return (a, b) => a !== b;
    case 'fg.lt':
      return (a, b) => a < b;
    case 'fg.le':
      return (a, b) => a <= b;
    case 'fg.gt':
      return (a, b) => a > b;
    case 'fg.ge':
      return (a, b) => a >= b;
    default:
      return undefined;
  }
};

import { EnjcValueTreeNode } from '../../tree-node';
import { IOperatorsChain, IOperatorsChainLink } from '../interfaces';

export const mkOperatorsChain = (
  head: EnjcValueTreeNode,
  body: ReadonlyArray<IOperatorsChainLink>,
): IOperatorsChain => ({
  head,
  body,
});

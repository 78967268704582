import { getNodeKey, mkTreeFunction } from '../../tree-node';
import { IOperatorsChainReductionResult } from '../interfaces';
import { replaceReducedOperatorsChainItem } from './replaceReducedOperatorsChainItem';

export const reduceOperatorsChainBody = (
  operatorsChainAcc: IOperatorsChainReductionResult,
): IOperatorsChainReductionResult => {
  const { operatorsChain, updatedNodes } = operatorsChainAcc;

  if (operatorsChain.body.length === 0) {
    return operatorsChainAcc;
  }

  // FIXME: take into account operator associativity
  const priorityOperatorIndex = operatorsChain.body.reduce(
    (iMax, item, index, arr) => (item.operator.priority > arr[iMax].operator.priority ? index : iMax),
    0,
  );
  const reducedChainLink = operatorsChain.body[priorityOperatorIndex];

  const operands = [
    priorityOperatorIndex === 0 ? operatorsChain.head : operatorsChain.body[priorityOperatorIndex - 1].operandNode,
    operatorsChain.body[priorityOperatorIndex].operandNode,
  ];

  const updatedOperatorNode = mkTreeFunction(
    reducedChainLink.operatorNode.key,
    reducedChainLink.operatorNode.draft,
    reducedChainLink.operatorNode.funcSpec?.id,
    operands.map(getNodeKey),
  );

  return reduceOperatorsChainBody({
    operatorsChain: replaceReducedOperatorsChainItem(operatorsChain, priorityOperatorIndex, updatedOperatorNode),
    updatedNodes: [...updatedNodes, updatedOperatorNode],
  });
};

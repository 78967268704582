import React, { ReactElement } from 'react';
import { UEnjcValueLiteral, valueLiteralToString } from '../../../libenjc/enjc-literal';

interface IProps {
  literal: UEnjcValueLiteral;
}

// FIXME: review

// TODO: use different colors for different literal types
export const SymbolResultMathView = ({ literal }: IProps): ReactElement => {
  // TODO: use full MathML
  return <mtext>{valueLiteralToString(literal)}</mtext>;
};

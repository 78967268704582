export type PossibleTypesResultData = {
  possibleTypes: {
    IEnjcMathIdentifier: ['EnjcValueFunctionSpec', 'EnjcWorkspaceSymbol'];
    IEnjcOrderedDeltaEntry: ['EnjcKeyListDeltaEntry', 'EnjcStringDeltaEntry'];
    IEnjcWorkspaceItem: ['EnjcWorkspace', 'EnjcWorkspaceSection', 'EnjcWorkspaceSymbol'];
    UEnjcAuxMetaEntry: ['EnjcAuxMetaStringEntry'];
    UEnjcValueLiteral: [
      'EnjcValueLiteralBoolean',
      'EnjcValueLiteralNumber',
      'EnjcValueLiteralString',
      'EnjcValueLiteralVoid',
    ];
    UEnjcWorkspaceSectionItem: ['EnjcWorkspaceSection', 'EnjcWorkspaceSymbol'];
  };
};
const result: PossibleTypesResultData = {
  possibleTypes: {
    IEnjcMathIdentifier: ['EnjcValueFunctionSpec', 'EnjcWorkspaceSymbol'],
    IEnjcOrderedDeltaEntry: ['EnjcKeyListDeltaEntry', 'EnjcStringDeltaEntry'],
    IEnjcWorkspaceItem: ['EnjcWorkspace', 'EnjcWorkspaceSection', 'EnjcWorkspaceSymbol'],
    UEnjcAuxMetaEntry: ['EnjcAuxMetaStringEntry'],
    UEnjcValueLiteral: [
      'EnjcValueLiteralBoolean',
      'EnjcValueLiteralNumber',
      'EnjcValueLiteralString',
      'EnjcValueLiteralVoid',
    ],
    UEnjcWorkspaceSectionItem: ['EnjcWorkspaceSection', 'EnjcWorkspaceSymbol'],
  },
};
export default result;

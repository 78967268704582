import { IOperatorsChain } from '../interfaces';
import { isTreeNodeLiteral, EnjcValueTreeNode } from '../../tree-node';
import { updateNodeDraft } from '../../tree-navigation/actions-nvtv/updateNodeDraft';
import { isLiteralVoid } from '../../../enjc-literal';

export const removeOperatorsChainOperator = (
  operatorsChain: IOperatorsChain,
  operatorIndex: number,
  mergeOperandsToRight: boolean,
): [IOperatorsChain | undefined, EnjcValueTreeNode | undefined] => {
  const operandLeft = operatorIndex === 0 ? operatorsChain.head : operatorsChain.body[operatorIndex - 1].operandNode;
  const operandRight = operatorsChain.body[operatorIndex].operandNode;

  // Right operand is empty, remove chain link
  if (isTreeNodeLiteral(operandRight) && isLiteralVoid(operandRight.literal) && operandRight.draft.length === 0) {
    return [
      {
        head: operatorsChain.head,
        body: [...operatorsChain.body.slice(0, operatorIndex), ...operatorsChain.body.slice(operatorIndex + 1)],
      },
      operatorIndex === 0 ? operatorsChain.head : operatorsChain.body[operatorIndex - 1].operandNode,
    ];
  }

  // Left operand is empty, combine two chain links
  if (isTreeNodeLiteral(operandLeft) && isLiteralVoid(operandLeft.literal) && operandLeft.draft.length === 0) {
    if (operatorIndex === 0) {
      // Remove chain head
      return [
        {
          head: operatorsChain.body[operatorIndex].operandNode,
          body: operatorsChain.body.slice(operatorIndex + 1),
        },
        operatorsChain.body[operatorIndex].operandNode,
      ];
    } else {
      // Combine two chain links into one
      return [
        {
          head: operatorsChain.head,
          body: [
            ...operatorsChain.body.slice(0, operatorIndex - 1),
            {
              operator: operatorsChain.body[operatorIndex - 1].operator,
              operatorNode: operatorsChain.body[operatorIndex - 1].operatorNode,
              operandNode: operatorsChain.body[operatorIndex].operandNode,
            },
            ...operatorsChain.body.slice(operatorIndex + 1),
          ],
        },
        operatorsChain.body[operatorIndex].operandNode,
      ];
    }
  }

  if (isTreeNodeLiteral(operandLeft) && isTreeNodeLiteral(operandRight)) {
    const nextDraft = operandLeft.draft + operandRight.draft;
    const nextNode = updateNodeDraft(!mergeOperandsToRight ? operandRight : operandLeft, nextDraft);

    if (operatorIndex === 0) {
      // update operators chain head
      return [
        {
          head: nextNode,
          body: operatorsChain.body.slice(operatorIndex + 1),
        },
        nextNode,
      ];
    } else {
      // update operators chain body
      return [
        {
          head: operatorsChain.head,
          body: [
            ...operatorsChain.body.slice(0, operatorIndex - 1),
            { ...operatorsChain.body[operatorIndex], operandNode: nextNode },
            ...operatorsChain.body.slice(operatorIndex + 1),
          ],
        },
        nextNode,
      ];
    }

    // FIXME: review and check
  } else {
    // Cannot join operands
    return [undefined, undefined];
  }
};

import { EnjcValueTreeNode } from '../../enjc-symbol-value-tree';
import { IValueTreeViewContext } from '../model';
import { getNodeByKeyNVTV } from './getNodeByKeyNVTV';

export const collectTreeNodeDescendantsNVTV = (
  vtvCtx: IValueTreeViewContext,
  node: EnjcValueTreeNode,
  includeNode: boolean = false,
): EnjcValueTreeNode[] => {
  const argNodes = node.funcArgs.map((argNode) => getNodeByKeyNVTV(vtvCtx, argNode.key));
  return [
    ...[includeNode ? [node] : []],
    argNodes,
    ...argNodes.map((argNode) => collectTreeNodeDescendantsNVTV(vtvCtx, argNode)),
  ].flat();
};

import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import type { RouteObject as ReactRouteObject } from 'react-router';
import { SignIn, SignUp, UserProfile } from '@clerk/clerk-react';
import {
  ErrorRoute,
  RootDashboard,
  RootSecured,
  RootUnsecured,
  SheetRoute,
  SymbolRoute,
  WorkspaceRoute,
  WorkspacesDashboardRoute,
} from '../routes';
import { DashboardRoute } from '../routes/dashboard';
import { WorkspaceSheetsRoute } from '../routes/workspace/sheets';
import { WorkspaceSymbolsRoute } from '../routes/workspace/symbols';

const routes: ReactRouteObject[] = [
  {
    path: '/',
    element: <RootUnsecured />,
    errorElement: <ErrorRoute />,
    children: [
      {
        index: true,
        element: <Navigate to={'/dashboard'} />,
      },

      /* Public routes */
      {
        path: 'sign-in/*',
        element: (
          <div className="flex size-full items-center justify-center">
            <SignIn routing="path" path="/sign-in" signUpUrl="/sign-up" />
          </div>
        ),
      },
      {
        path: 'sign-up/*',
        element: (
          <div className="flex size-full items-center justify-center">
            <SignUp routing="path" path="/sign-up" signInUrl="/sign-in" />
          </div>
        ),
      },

      {
        path: '/',
        element: <RootDashboard />,
        errorElement: <ErrorRoute />,
        children: [
          {
            path: 'dashboard',
            element: <DashboardRoute />,
          },
        ],
      },

      {
        path: '/',
        element: <RootSecured />,
        errorElement: <ErrorRoute />,
        children: [
          {
            path: 'profile/*',
            element: (
              <div className="flex size-full items-center justify-center">
                <UserProfile routing="path" path="/profile" />
              </div>
            ),
          },

          {
            path: 'workspaces',
            element: <WorkspacesDashboardRoute />,
          },
          {
            path: 'workspace/:workspaceId',
            element: <WorkspaceRoute />,
            children: [
              {
                index: true,
                element: <Navigate to={'./sheets'} />,
              },
              {
                path: 'sheets',
                element: <WorkspaceSheetsRoute />,
              },
              {
                path: 'symbols',
                element: <WorkspaceSymbolsRoute />,
              },
            ],
          },
          {
            path: 'workspace/:workspaceId/sheets2',
            element: <WorkspaceSheetsRoute />,
          },
          {
            path: 'workspace/:workspaceId/symbols2',
            element: <WorkspaceSymbolsRoute />,
          },

          {
            path: 'symbol/:symbolId',
            element: <SymbolRoute />,
          },

          {
            path: 'sheet/:sheetId',
            element: <SheetRoute />,
          },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(routes, {
  // basename: '/app',
  future: { v7_normalizeFormMethod: true },
});

export { router };

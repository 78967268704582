import React, { ReactElement, ReactNode } from 'react';
import { EnjicalcWorkspaceItemsRefs } from '../../../enjc-workspace';
import { WorkspacesListContext } from '../context';

interface IProps {
  readonly workspaces: ReadonlyArray<EnjicalcWorkspaceItemsRefs>;
  readonly refetch: () => void;
  readonly children: ReactNode;
}

type FComp = (props: IProps) => ReactElement;

export const WorkspacesListContextProvider = React.memo<FComp>(({ workspaces, refetch, children }) => {
  return <WorkspacesListContext.Provider value={{ workspaces, refetch }}>{children}</WorkspacesListContext.Provider>;
});

import { EnjcWorkspaceWithMath } from '../../libenjc/enjc-workspace';
import { FMutWorkspaceUpdate } from '../../libenjc/enjc-client';
import { EnjicalcWorkspaceTracker } from '../../libenjc/enjc-workspace-editing/model/EnjicalcWorkspaceTracker';
import { useWorkspaceEditHistoryConstructor } from '../../libenjc/enjc-react/hooks';
import { findSheetSections } from '../../libenjc/enjc-workspace/utils';
import { evaluateWorkspaceSymbols } from './evaluateWorkspaceSymbols';
import { mkLiteralVoidNull } from '../../libenjc/enjc-literal';

export const useEnjicalcWorkspaceTracker = (
  remoteWorkspaceData: EnjcWorkspaceWithMath,
  updateWorkspace: FMutWorkspaceUpdate,
): EnjicalcWorkspaceTracker => {
  const workspaceEditHistoryContext = useWorkspaceEditHistoryConstructor(remoteWorkspaceData, updateWorkspace);

  const sheets = findSheetSections(workspaceEditHistoryContext.workspaceEditHistory.currentState.sections);
  const calcCache = evaluateWorkspaceSymbols(workspaceEditHistoryContext.workspaceEditHistory.currentState);
  return {
    ...workspaceEditHistoryContext,
    currentWorkspace: {
      ...workspaceEditHistoryContext.workspaceEditHistory.currentState,
      symbols: workspaceEditHistoryContext.workspaceEditHistory.currentState.symbols.map((sy) =>
        calcCache.has(sy.id)
          ? { ...sy, valueTree: { ...sy.valueTree, result: calcCache.get(sy.id) ?? mkLiteralVoidNull() } }
          : sy,
      ),
      sheets,
      calcCache,
    },
  };
};

import { keyListDeltaToInput } from '../../enjc-delta';
import { EnjcWorkspaceSectionDelta } from '../../enjc-workspace-editing';
import { EnjcWorkspaceSectionInput } from '../../enjicalc-graphql';
import { convertAuxMetaEntryDeltaToDiffInput } from './convertAuxMetaEntryDeltaToDiffInput';

export const convertWorkspaceSectionDeltaToDiffInput = (
  delta: EnjcWorkspaceSectionDelta,
): EnjcWorkspaceSectionInput => {
  return {
    id: delta.id,
    visibility: delta.visibility?.atomAfter,
    auxMeta: delta.auxMeta && delta.auxMeta.map((d) => convertAuxMetaEntryDeltaToDiffInput(d, false)),
    items: delta.items && delta.items.map((d) => keyListDeltaToInput(d, false)),
  };
};

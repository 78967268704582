import { createContext } from 'react';
import { EnjicalcSymbol, EnjcWorkspaceWithMath } from '../../../enjc-workspace';
import { IValueTreeViewContext } from '../../../enjc-value-view-ctx';

export const ValueTreeViewContext = createContext<IValueTreeViewContext>({
  // TODO: review casting
  workspace: <EnjcWorkspaceWithMath>{},
  // TODO: review casting
  symbol: <EnjicalcSymbol>{
    __typename: 'EnjcWorkspaceSymbol',
    id: '',
  },
  valueTreeCursor: { position: undefined },
  hasFocus: false,
  showValueHints: false,
});

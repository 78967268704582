import React, { ReactElement } from 'react';
import { getFunctionBinaryOperatorById, getNodeOperator } from '../../../../enjc-value-operators';
import {
  getNodeByKeyNVTV,
  getValueFunctionOrUndefinedNVTV,
  getSymbolValueCursorAsNode,
} from '../../../../enjc-value-view-ctx';
import { useValueTreeViewContext } from '../../../enjc-react-ui';
import { ISymbolValueNodeCompositeViewProps } from '../props';
import { getNodeCursorPositionAsDraft } from '../utils';
import { SymbolValueNodeCompositeView } from './SymbolValueNodeCompositeView';

export const SymbolValueNodeFunctionCompositeView = ({
  node,
  viewComponents,
  viewOptions,
  onNodeClick,
  onHintClick,
  // TODO: think about adding 'renderNode' function / component
}: ISymbolValueNodeCompositeViewProps): ReactElement => {
  const {
    draftViewComp: DraftView,
    glyphViewComp: GlyphView,
    groupViewComp: GroupView,
    binaryOperatorViewComp: BinaryOperatorView,
    specialFunctionView,
  } = viewComponents;
  const vtvCtx = useValueTreeViewContext();
  const nodeDraftPosition = getNodeCursorPositionAsDraft(node, vtvCtx.valueTreeCursor);

  const argNodes = node.funcArgs.map((argRef) => argRef.key).map((argKey) => getNodeByKeyNVTV(vtvCtx, argKey));
  const argReactNodes = argNodes.map((n) => (
    <SymbolValueNodeCompositeView
      key={n.key}
      node={n}
      viewComponents={viewComponents}
      viewOptions={viewOptions}
      onNodeClick={onNodeClick}
      onHintClick={onHintClick}
    />
  ));

  const sfv =
    node.funcSpec?.id !== undefined &&
    specialFunctionView &&
    specialFunctionView({ funcSpecName: node.funcSpec.id, argReactNodes, onNodeClick });
  if (sfv) {
    return sfv;
  }

  const operator = (node.funcSpec?.id && getFunctionBinaryOperatorById(node.funcSpec.id)) || undefined;
  if (operator && argNodes.length === 2) {
    // const [argNodeLeft, argNodeRight] = argNodes;

    // TODO: combine all steps into single map sequence
    // const [argLeft, argRight] = argNodes.map((argNode) => {});

    const [argOperatorLeft, argOperatorRight] = argNodes.map((argNode) => argNode && getNodeOperator(argNode));

    const implicitParenthesisLeft = argOperatorLeft && argOperatorLeft.priority < operator.priority;
    const implicitParenthesisRight = argOperatorRight && argOperatorRight.priority <= operator.priority;

    const [nodeCursorLeft, nodeCursorRight] = argNodes.map((n) => getSymbolValueCursorAsNode(vtvCtx, n));

    // const [argLeft, argRight] = argReactNodes;
    const argLeft = implicitParenthesisLeft ? (
      <GroupView cursor={nodeCursorLeft}>{[argReactNodes[0]]}</GroupView>
    ) : (
      argReactNodes[0]
    );
    const argRight = implicitParenthesisRight ? (
      <GroupView cursor={nodeCursorRight}>{[argReactNodes[1]]}</GroupView>
    ) : (
      argReactNodes[1]
    );

    return <BinaryOperatorView operator={operator} argLeft={argLeft} argRight={argRight} onNodeClick={onNodeClick} />;
  }
  // TODO: check handling of unset function id

  const functionNameGlyph =
    ((node.funcSpec?.id && getValueFunctionOrUndefinedNVTV(vtvCtx, node.funcSpec.id)) || null)?.glyph || '';
  const groupPrefix =
    node.funcSpec?.id === 'fg.noop' ? (
      ''
    ) : node.funcSpec?.id === 'fg.stub' ? (
      <DraftView draft={node.draft} position={nodeDraftPosition} onClick={() => onNodeClick(node.key)} />
    ) : (
      <GlyphView glyph={functionNameGlyph} cursor={undefined} onClick={() => onNodeClick(node.key)} />
    );
  const groupViewCursor = getSymbolValueCursorAsNode(vtvCtx, node);
  return (
    <GroupView prefix={groupPrefix} cursor={groupViewCursor}>
      {node.funcArgs
        .map((k) => getNodeByKeyNVTV(vtvCtx, k.key))
        .map((n) => (
          <SymbolValueNodeCompositeView
            key={`${n.key}`}
            node={n}
            viewComponents={viewComponents}
            viewOptions={viewOptions}
            onNodeClick={onNodeClick}
            onHintClick={onHintClick}
          />
        ))}
    </GroupView>
  );
};

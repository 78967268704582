import React from 'react';
import { Spinner } from 'src/shadcn';

interface IProps {
  name?: string;
}

export const LoadingPlaceholder: React.FC<IProps> = ({ name }) => (
  <div className="flex size-full items-center justify-center gap-3">
    <Spinner />
    <h4 className="text-gray-500">{name ? `${name} is loading...` : 'Loading...'}</h4>
  </div>
);

import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserButton } from '@clerk/clerk-react';
import { useWorkspacesListContext } from '../../libenjc/enjc-react/enjc-react-context';
import { WorkspacesListView } from '../../components/views/workspaces-list-view';
import { EnjcWorkspaceItemVisibility, TWorkspaceId } from '../../generated/graphql/types';
import { useEnjcRawWorkspaceMutations } from '../../libenjc/enjc-react/enjc-react-client';
import { SearchWorkspaces } from '../../components/search-workspaces/SearchWorkspaces';
import { EnjicalcWorkspaceUpdateMessage, WorkspaceEditHistoryEntry } from '../../libenjc/enjc-workspace-editing';
import { EnjicalcWorkspaceItemsRefs } from '../../libenjc/enjc-workspace';
import { convertWorkspaceEditHistoryEntryToDiffInput } from '../../libenjc/enjc-client';
import { Switch } from '../../shadcn/components/Switch';
import { Button, Label } from '../../shadcn';
import { useLocalStorage } from 'usehooks-ts';
import { QUICK_START_TOUR_STEP_NAMES, QUICK_START_TOUR_STEP_COUNT, useQuickStartTour } from 'src/hooks';
import { extractUpdateCreatedWorkspace } from '../../libenjc/enjc-client/utils/extractUpdateCreatedWorkspace';

const AppDashboardF = (): ReactElement => {
  const { setCurrentStep } = useQuickStartTour();
  const [showHidden, setShowHidden] = useLocalStorage('archivedWorkspaces', false);

  const navigate = useNavigate();
  const { createWorkspace, updateWorkspace, forkWorkspace } = useEnjcRawWorkspaceMutations();
  const { workspaces, refetch } = useWorkspacesListContext();

  const performWorkspaceEdit = React.useCallback(
    (
      workspace: EnjicalcWorkspaceItemsRefs,
      editEntry: WorkspaceEditHistoryEntry,
    ): Promise<EnjicalcWorkspaceUpdateMessage> => {
      return updateWorkspace(workspace.id, -1, { items: [convertWorkspaceEditHistoryEntryToDiffInput(editEntry)] });
    },
    [updateWorkspace],
  );

  // FIXME
  // const clonedWorkspaces = React.useMemo(
  //   () =>
  //     workspaces.filter((w) => w.flagCloned && (showHidden || w.visibility === EnjcWorkspaceItemVisibility.Visible)),
  //   [showHidden, workspaces],
  // );

  // const filteredWorkspaces = React.useMemo(
  //   () =>
  //     workspaces.filter((w) => !w.flagCloned && (showHidden || w.visibility === EnjcWorkspaceItemVisibility.Visible)),
  //   [showHidden, workspaces],
  // );
  const filteredWorkspaces = React.useMemo(
    () =>
      workspaces
        .filter(
          (w) =>
            w.visibility === EnjcWorkspaceItemVisibility.Visible ||
            (showHidden && w.visibility === EnjcWorkspaceItemVisibility.Hidden),
        )
        .sort((a, b) => b.itemsVersion.timestamp - a.itemsVersion.timestamp),
    [showHidden, workspaces],
  );

  React.useEffect(() => {
    setCurrentStep(QUICK_START_TOUR_STEP_COUNT.OpenWorkspace);
  }, [filteredWorkspaces, setCurrentStep]);

  const handleCreateWorkspace = React.useCallback(() => {
    createWorkspace().then((updateResult) => {
      const createdWorkspace = extractUpdateCreatedWorkspace(updateResult);
      false ? navigate(`/workspace/${createdWorkspace.id}`) : refetch();
    });
  }, [createWorkspace, navigate, refetch]);

  const handleForkExampleWorkspace = React.useCallback(() => {
    forkWorkspace('wswf149b16e', -1, undefined).then((updateResult) => {
      const createdWorkspace = extractUpdateCreatedWorkspace(updateResult);
      false ? navigate(`/workspace/${createdWorkspace.id}`) : refetch();
    });
  }, [forkWorkspace, navigate, refetch]);

  const handleWorkspaceFork = React.useCallback(
    (workspaceId: TWorkspaceId) => {
      forkWorkspace(workspaceId, -1, undefined).then((updateResult) => {
        const createdWorkspace = extractUpdateCreatedWorkspace(updateResult);
        false ? navigate(`/workspace/${createdWorkspace.id}`) : refetch();
      });
    },
    [forkWorkspace, navigate, refetch],
  );

  return (
    <div className="mt-[75px] flex h-screen w-full flex-col items-start gap-[50px] overflow-y-scroll py-[45px] pl-[60px]">
      <div className="absolute right-[26px] top-[15px] flex items-center gap-2">
        <SearchWorkspaces workspaces={filteredWorkspaces} />
        <div className="flex items-center">
          <UserButton />
        </div>
      </div>

      <h2 className="text-xl font-bold">Get started</h2>
      <div className="flex flex-wrap gap-[50px]">
        <Button
          className={`h-[50px] w-[300px] text-[15px] ${QUICK_START_TOUR_STEP_NAMES.CreateNewWorkspace}`}
          onClick={handleCreateWorkspace}
        >
          Create New Workspace
        </Button>
        <Button className="h-[50px] w-[300px] text-[15px]" variant="outline" onClick={handleForkExampleWorkspace}>
          Create Example Workspace
        </Button>
      </div>

      <div className="flex items-center">
        <h2 className="text-xl font-bold">Recent workspaces</h2>
        <div className="flex items-center">
          <Switch
            checked={showHidden}
            onCheckedChange={(checked: boolean) => setShowHidden(checked)}
            style={{ scale: '80%' }}
          />
          <Label>Show archived</Label>
        </div>
      </div>
      <WorkspacesListView
        workspaces={filteredWorkspaces}
        performWorkspaceEdit={performWorkspaceEdit}
        onWorkspaceFork={handleWorkspaceFork}
      />
    </div>
  );
};

export const AppDashboard = React.memo(AppDashboardF);

import React, { ReactElement } from 'react';
import { IoCloseSharp, IoCreateOutline } from 'react-icons/io5';
import { AiOutlineInsertRowAbove, AiOutlineInsertRowBelow } from 'react-icons/ai';
import { RiMore2Line, RiDraggable } from 'react-icons/ri';
import { isTreeNodeLiteral, TValueTreeNodeKey } from '../../../libenjc/enjc-symbol-value-tree/tree-node';
import { isLiteralVoid, mkLiteralNumber } from '../../../libenjc/enjc-literal';
import {
  EnjcWorkspaceItemVisibility,
  EnjicalcSymbol,
  getEnjcSymbolOrUndefined,
  getSymbolUnit,
  getSymbolUnitDescriptionComment,
  EnjcWorkspaceDTO,
} from '../../../libenjc/enjc-workspace';
import { IValueTreeViewContext } from '../../../libenjc/enjc-value-view-ctx';
import { mkTreeCursor } from '../../../libenjc/enjc-symbol-value-tree/tree-cursor';
import { getTreeNodeByKey } from '../../../libenjc/enjc-symbol-value-tree/tree-methods';
import { ValueTreeViewContext } from '../../../libenjc/enjc-react/enjc-react-ui';
import {
  hCreateSymbolBeforeAfter,
  editDeleteSymbol,
  editHideSymbol,
  editMoveSymbolVertical,
  editSetSymbolValueToLiteral,
  hCreateSheetSectionFlatBeforeAfter,
} from '../../../libenjc/enjc-workspace-editing';
import { DocumentItemIndexView, TextPopover } from '../../misc';
import { EnjcGlyphMathBlockView, EnjcUnitMathBlockView } from '../symbol-math-view';
import { SymbolValueTreeView } from './SymbolValueTreeView';
import { SymbolResultView } from './SymbolResultView';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { SymbolResultViewDraftEditable } from './SymbolResultViewDraftEditable';
import { EnjcValueTreeNodeMode } from '../../../generated/graphql/types';
import {
  useCtxEnjicalcSheet,
  useCtxEnjicalcWorkspaceEditHistory,
  useCtxEnjicalcWorkspaceWithMath,
} from '../../../libenjc/enjc-react/enjc-react-context';
import { calculateStringDeltaEntry, EnjcStringDeltaEntry } from '../../../libenjc/enjc-delta';
import { ESections } from 'src/components/editors/symbol-editor/SymbolEditor';
import { QUICK_START_TOUR_STEP_COUNT, QUICK_START_TOUR_STEP_NAMES, useQuickStartTour } from 'src/hooks';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from 'src/shadcn';
import { IoIosWarning } from 'react-icons/io';
import { PiWarningCircleFill, PiWarningDiamondFill } from 'react-icons/pi';

export interface ISymbolDocumentViewProps {
  readonly workspace: EnjcWorkspaceDTO;
  readonly symbol: EnjicalcSymbol;
  readonly documentItemIndex: number;
  readonly isSymbolModalOpen: boolean;

  readonly setSymbolOpen: (state: boolean, section?: ESections) => void;
  readonly dndListeners: SyntheticListenerMap | undefined;
}

export const SymbolDocumentView = ({
  workspace,
  symbol,
  documentItemIndex,
  setSymbolOpen,
  dndListeners,
}: ISymbolDocumentViewProps): ReactElement => {
  const { setCurrentStep, isAlpha, isBeta } = useQuickStartTour();

  const {
    workspaceWithMath: { functions: workspaceFunctions },
  } = useCtxEnjicalcWorkspaceWithMath();
  const { sheet } = useCtxEnjicalcSheet();
  const workspaceTracker = useCtxEnjicalcWorkspaceEditHistory();
  const { performEdit, performEditAction } = workspaceTracker;
  const [isOpenMenu, setIsOpenMenu] = React.useState(false);

  const { glyph: symbolGlyph } = symbol;
  const {
    unit: symbolUnit,
    description: symbolDescription,
    comment: symbolComment,
  } = getSymbolUnitDescriptionComment(symbol);

  const rootNode =
    (symbol.valueTree.rootNode?.key && getTreeNodeByKey(symbol.valueTree, symbol.valueTree.rootNode.key)) || undefined;
  const symbolIsSolidLiteral = rootNode && isTreeNodeLiteral(rootNode);

  const [showOverlayButtons, setShowOverlayButtons] = React.useState(false);
  const [highlightSymbolRow, setHighlightSymbolRow] = React.useState(false);

  const handleValueTreeNodeSelected = React.useCallback((nodeKey: TValueTreeNodeKey) => {
    // FIXME: implement
  }, []);

  const sheetSymbolIndex = sheet.symbols.findIndex((sy) => sy.id === symbol.id);
  const usedSymbols = symbol.valueTree.nodes
    .filter((n) => n.mode === EnjcValueTreeNodeMode.Symbol)
    .map((node) => node.symbol?.id ?? '')
    .filter((syId) => syId.length > 0);

  const errorOrder = usedSymbols.find((uSy) => {
    return sheet.symbols.findIndex((sSy) => sSy.id === uSy) >= sheetSymbolIndex;
  });

  const errorOrderName = errorOrder ? sheet.symbols.find((sSy) => sSy.id === errorOrder)?.glyph : null;

  const symbolOccurrences = sheet.symbols.reduce<Record<string, number>>((acc, s) => {
    if (s.glyph !== '') {
      acc[s.glyph] = (acc[s.glyph] || 0) + 1;
    }
    return acc;
  }, {});
  const errorDuplicate = symbolOccurrences[symbol.glyph] > 1 ? symbol.glyph : null;

  // FIXME: review (looks like archived nodes are converted to literals)
  const symbolValueNodeArchivedSymbols =
    symbol.valueTree.nodes.find(
      (n) =>
        n.symbol?.id !== undefined &&
        n.mode === EnjcValueTreeNodeMode.Symbol &&
        getEnjcSymbolOrUndefined(workspace, n.symbol?.id)?.visibility !== EnjcWorkspaceItemVisibility.Visible,
    ) !== undefined;
  const isLiteralVoidSymbols = isLiteralVoid(symbol.valueTree.result);

  const handleArchiveSymbol = React.useCallback(() => {
    const hEntry = editHideSymbol(workspace, symbol.id);
    performEdit(hEntry);
  }, [performEdit, symbol.id, workspace]);

  const handleDeleteSymbol = React.useCallback(() => {
    const hEntry = editDeleteSymbol(workspace, symbol.id);
    performEdit(hEntry);
  }, [performEdit, symbol.id, workspace]);

  const moveSymbolUp = React.useCallback(() => {
    const hEntry = editMoveSymbolVertical(workspace, symbol.id, true);
    performEdit(hEntry);
  }, [performEdit, symbol.id, workspace]);

  const moveSymbolDown = React.useCallback(() => {
    const hEntry = editMoveSymbolVertical(workspace, symbol.id, false);
    performEdit(hEntry);
  }, [performEdit, symbol.id, workspace]);

  const addSymbolAboveBelow = React.useCallback(
    (addBelow: boolean) => {
      const workspaceDelta = hCreateSymbolBeforeAfter(workspaceTracker, symbol.id, addBelow).historyEntry;
      performEdit(workspaceDelta);
    },
    [workspaceTracker, symbol.id, performEdit],
  );

  const addSectionAboveBelow = React.useCallback(
    (addBelow: boolean) => {
      const workspaceDelta = hCreateSheetSectionFlatBeforeAfter(
        workspaceTracker,
        sheet.id,
        symbol.id,
        addBelow,
      ).historyEntry;
      performEdit(workspaceDelta);
    },
    [performEdit, sheet.id, symbol.id, workspaceTracker],
  );

  const handleLiteralChange = React.useCallback(
    (n: number) => {
      const hEntry = editSetSymbolValueToLiteral(symbol, mkLiteralNumber(n), undefined);
      performEdit(hEntry);
    },
    [performEdit, symbol],
  );

  const handleLiteralSave = React.useCallback(
    (draft: string) => {
      const vFloat = Number(draft);
      const literalNumber = mkLiteralNumber(vFloat);
      const hEntry = editSetSymbolValueToLiteral(symbol, literalNumber, draft);
      performEdit(hEntry);
    },
    [performEdit, symbol],
  );

  // TODO: review and discuss pre 0822
  const handleSaveSymbolGlyph = React.useCallback(
    (nextSymbolGlyph: string) => {
      const delta: EnjcStringDeltaEntry | undefined = calculateStringDeltaEntry(symbol.glyph, nextSymbolGlyph);
      delta && performEditAction.updateSymbolGlyph(symbol.id, delta, { title: 'Edit Symbol Glyph' });
    },
    [symbol, performEditAction],
  );

  const handleSaveUnit = React.useCallback(
    (nextSymbolUnit: string) => {
      const delta: EnjcStringDeltaEntry | undefined = calculateStringDeltaEntry(getSymbolUnit(symbol), nextSymbolUnit);
      delta && performEditAction.updateSymbolUnit(symbol.id, delta, { title: 'Edit Symbol Unit' });
    },
    [symbol, performEditAction],
  );

  const valueTreeViewCtx: IValueTreeViewContext = React.useMemo(
    () => ({
      workspace: { ...workspace, functions: workspaceFunctions },
      symbol,
      valueTreeCursor: mkTreeCursor(undefined),
      valueHintItem: undefined,
      hasFocus: false,
      showValueHints: false,
    }),
    [symbol, workspace, workspaceFunctions],
  );

  return (
    <div
      className={`flex flex-col ${highlightSymbolRow ? 'bg-[#f4f8fa]' : ''} ${
        isAlpha
          ? QUICK_START_TOUR_STEP_NAMES.OpenSymbolMenu
          : documentItemIndex === 1
            ? QUICK_START_TOUR_STEP_NAMES.OpenSymbolMenuBetta
            : QUICK_START_TOUR_STEP_NAMES.OpenSymbolMenuGamma
      }`}
      onMouseOver={() => {
        setShowOverlayButtons(true);
        setHighlightSymbolRow(true);
      }}
      onMouseLeave={() => {
        setShowOverlayButtons(false);
        setHighlightSymbolRow(false);
      }}
      onClick={() => {
        setSymbolOpen(true);

        if (isAlpha) {
          setCurrentStep(QUICK_START_TOUR_STEP_COUNT.WriteSymbolName);
        } else if (documentItemIndex === 1) {
          setCurrentStep(QUICK_START_TOUR_STEP_COUNT.WriteSymbolNameBetta);
        } else {
          setCurrentStep(QUICK_START_TOUR_STEP_COUNT.WriteSymbolNameGamma);
        }
      }}
    >
      <DocumentItemIndexView index={documentItemIndex} />
      {showOverlayButtons && (
        <RiDraggable {...dndListeners} size={17} color="black" style={{ position: 'absolute', top: 5, left: -75 }} />
      )}
      <span className="noPrint relative left-[780px] flex size-0">
        <div className="absolute left-0 flex items-center">
          <DropdownMenu modal={false} open={isOpenMenu} onOpenChange={(open) => setIsOpenMenu(open)}>
            <DropdownMenuTrigger>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  if (isAlpha) {
                    setCurrentStep(QUICK_START_TOUR_STEP_COUNT.ModifySymbol);
                  } else {
                    setCurrentStep(QUICK_START_TOUR_STEP_COUNT.ModifySymbolBetta);
                  }
                }}
                size={'sm'}
                aria-label={'Symbol Options'}
                variant="ghost"
                // className={
                //   documentItemIndex === 0
                //     ? QUICK_START_TOUR_STEP_NAMES.OpenSymbolMenu
                //     : documentItemIndex === 1
                //       ? QUICK_START_TOUR_STEP_NAMES.OpenSymbolMenuBetta
                //       : QUICK_START_TOUR_STEP_NAMES.OpenSymbolMenuGamma
                // }
              >
                <RiMore2Line />
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent
              onClick={(e) => {
                e.stopPropagation();
                setIsOpenMenu(false);
              }}
              className={`border border-transparent shadow-lg
             `}
            >
              <Button
                variant="ghost"
                onClick={() => {
                  setSymbolOpen(true);
                  // if (isAlpha) {
                  //   setCurrentStep(QUICK_START_TOUR_STEP_COUNT.WriteSymbolName);
                  // } else if (documentItemIndex === 1) {
                  //   setCurrentStep(QUICK_START_TOUR_STEP_COUNT.WriteSymbolNameBetta);
                  // } else {
                  //   setCurrentStep(QUICK_START_TOUR_STEP_COUNT.WriteSymbolNameGamma);
                  // }
                }}
                className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
              >
                <IoCreateOutline size={17} className="mr-2 text-black" />
                {'Modify Symbol'}
              </Button>

              <Button
                variant="ghost"
                onClick={handleDeleteSymbol}
                className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
              >
                <IoCloseSharp size={17} className="mr-2 text-red-600" />
                {'Delete Symbol'}
              </Button>

              <DropdownMenuSeparator />

              <Button
                variant="ghost"
                onClick={() => addSymbolAboveBelow(false)}
                className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
              >
                <AiOutlineInsertRowAbove size={17} className="mr-2 text-black" />
                {'Create Symbol Above'}
              </Button>

              <Button
                variant="ghost"
                onClick={() => addSymbolAboveBelow(true)}
                className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
              >
                <AiOutlineInsertRowBelow size={17} className="mr-2 text-black" />
                {'Create Symbol Below'}
              </Button>

              <Button
                variant="ghost"
                onClick={() => addSectionAboveBelow(false)}
                className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
              >
                <AiOutlineInsertRowAbove size={17} className="mr-2 text-black" />
                {'Create Section Above'}
              </Button>
            </DropdownMenuContent>
          </DropdownMenu>
          <div className="flex gap-3">
            {errorOrder && (
              <Tooltip delayDuration={100}>
                <TooltipTrigger>
                  <IoIosWarning size={20} className="symbolError scroll-m-10" />
                </TooltipTrigger>
                <TooltipContent className="max-w-[150px]">
                  {`Move symbol "${errorOrderName}" before the current symbol`}
                </TooltipContent>
              </Tooltip>
            )}
            {(symbolValueNodeArchivedSymbols || isLiteralVoidSymbols) && (
              <Tooltip delayDuration={100}>
                <TooltipTrigger>
                  <PiWarningCircleFill color="rgba(255, 132, 0)" size={20} className="symbolError scroll-m-10" />
                </TooltipTrigger>
                <TooltipContent className="max-w-[150px]">Check if symbol inside is archived</TooltipContent>
              </Tooltip>
            )}
            {errorDuplicate && (
              <Tooltip delayDuration={100}>
                <TooltipTrigger>
                  <PiWarningDiamondFill size={20} className="symbolError scroll-m-10" />
                </TooltipTrigger>
                <TooltipContent className="max-w-[150px]">{`Symbol "${errorDuplicate}" is duplicated`}</TooltipContent>
              </Tooltip>
            )}
          </div>
        </div>
      </span>

      <span>
        <div className="tableRow flex">
          <p className="showIndex hideIndex">{documentItemIndex}.</p>
          <div className="emptyCell noPrint" />
          {/*<div className="description editableDescription" onClick={() => setSymbolOpen(true, ESections.comment)}>*/}
          <div className="description editableDescription">{symbolDescription}</div>

          {/*<TextPopover initialValue={symbolGlyph} onSave={handleSaveSymbolGlyph}>*/}
          <div className="symbol editableSymbolName">
            <EnjcGlyphMathBlockView glyph={symbolGlyph} />
          </div>
          {/*</TextPopover>*/}

          {symbolIsSolidLiteral ? (
            <SymbolResultViewDraftEditable
              draft={rootNode?.draft ?? ''}
              literal={symbol.valueTree.result}
              onValueSave={handleLiteralSave}
            />
          ) : (
            <SymbolResultView literal={symbol.valueTree.result} />
          )}

          {/*<TextPopover initialValue={symbolUnit} onSave={handleSaveUnit}>*/}
          <div className="unit">
            <EnjcUnitMathBlockView unit={symbolUnit} optional={true} />
          </div>
          {/*</TextPopover>*/}

          {/*<div className="comment editableComment" onClick={() => setSymbolOpen(true, ESections.comment)}>*/}
          <div className="comment editableComment">{symbolComment || ' '}</div>
        </div>

        {!symbolIsSolidLiteral && (
          // <div className="flex" onClick={() => setSymbolOpen(true)}>
          <div className="flex">
            <div className="formula editableFormula">
              <div className="m-4">
                <ValueTreeViewContext.Provider value={valueTreeViewCtx}>
                  <SymbolValueTreeView symbol={symbol} onValueTreeNodeSelected={handleValueTreeNodeSelected} />
                </ValueTreeViewContext.Provider>
              </div>
            </div>
          </div>
        )}
      </span>
    </div>
  );
};

import React from 'react';

interface IHook {
  cb: (event: KeyboardEvent) => void;
}

export const useShortcuts = ({ cb }: IHook) => {
  React.useEffect(() => {
    document.addEventListener('keydown', cb);

    return () => {
      document.removeEventListener('keydown', cb);
    };
  }, [cb]);
};

import React, { ReactElement } from 'react';
import { ISymbolValueLiteralViewProps } from '../../enjc-symbol-value-composite-view/props';
import { assertNever, formatNumerical } from '../../../../../utils';
import { MathMLGlobalAttributes } from '../../../../enjc-react-mathml/attributes';
import { isCursorNodeShadow } from '../../../../enjc-symbol-value-tree/tree-cursor';

export const SymbolValueLiteralMathmlView = ({
  literal,
  cursor,
  onClick,
}: ISymbolValueLiteralViewProps): ReactElement => {
  const mathProps: MathMLGlobalAttributes = {
    mathbackground: isCursorNodeShadow(cursor) ? 'lightgray' : undefined,
    onClick,
  };
  switch (literal?.__typename) {
    case undefined:
    case 'EnjcValueLiteralVoid':
      return <mi {...mathProps}>{'▢'}</mi>; // UTF8 '\u25A1' \u25A1 '▢'
    case 'EnjcValueLiteralBoolean':
      return <mi {...mathProps}>{literal.bool ? 'true' : 'false'}</mi>;
    case 'EnjcValueLiteralNumber':
      return <mn {...mathProps}>{formatNumerical(literal.numb)}</mn>;
    case 'EnjcValueLiteralString':
      return <mtext {...mathProps}>{literal.str}</mtext>;
    default:
      return assertNever(literal);
  }
};

import { StepType, useTour } from '@reactour/tour';
import React from 'react';

export const ALPHA = 'alpha';
export const BETA = 'beta';
export const GAMMA = 'gamma';
export const DELTA_BETA_C = 'Delta beta (t)';
export const XI_PH_THETA = 'xi_{ph Theta}';

export const QUICK_START_TOUR_STYLES = {
  popover: (base: any) => ({
    ...base,
    borderRadius: 10,
  }),
  maskArea: (base: any) => ({ ...base, rx: 10 }),
  maskWrapper: (base: any) => ({ ...base, color: '#E4E4E4' }),
  close: (base: any) => ({ ...base, left: 'auto', right: 15, top: 15 }),
};

export const PADDING = {
  mask: 5,
};

export enum QUICK_START_TOUR_STEP_NAMES {
  CreateNewWorkspace = 'createNewWorkspace',
  OpenWorkspace = 'openWorkspace',
  CreateSheet = 'createSheet',
  OpenSheet = 'openSheet',

  AddSymbol = 'addSymbol',
  OpenSymbolMenu = 'openSymbolMenu',
  ModifySymbol = 'modifySymbol',
  WriteSymbolName = 'writeSymbolName',
  AddValueForSymbol = 'addValueForSymbol',
  SaveSymbol = 'saveSymbol',

  AddSymbolBetta = 'addSymbolBetta',
  OpenSymbolMenuBetta = 'openSymbolMenuBetta',
  ModifySymbolBetta = 'modifySymbolBetta',
  WriteSymbolNameBetta = 'writeSymbolNameBetta',
  AddValueForSymbolBetta = 'addValueForSymbolBetta',
  SaveSymbolBetta = 'saveSymbolBetta',

  AddSymbolGamma = 'addSymbolGamma',
  OpenSymbolMenuGamma = 'openSymbolMenuGamma',
  ModifySymbolGamma = 'modifySymbolGamma',
  WriteSymbolNameGamma = 'writeSymbolNameGamma',
  AddValueForSymbolGamma = 'addValueForSymbolGamma',
  SaveSymbolGamma = 'saveSymbolGamma',

  Print = 'print',
}

export enum QUICK_START_TOUR_STEP_COUNT {
  CreateNewWorkspace,
  OpenWorkspace,
  CreateSheet,
  OpenSheet,
  AddSymbol,
  OpenSymbolMenu,
  ModifySymbol,
  WriteSymbolName,
  AddValueForSymbol,
  SaveSymbol,
  AddSymbolBetta,
  OpenSymbolMenuBetta,
  ModifySymbolBetta,
  WriteSymbolNameBetta,
  AddValueForSymbolBetta,
  SaveSymbolBetta,
  AddSymbolGamma,
  OpenSymbolMenuGamma,
  ModifySymbolGamma,
  WriteSymbolNameGamma,
  AddValueForSymbolGamma,
  SaveSymbolGamma,
  Print,
}

export const CONFETTI_DURATION = 10000;

const makeSelector = (step: QUICK_START_TOUR_STEP_NAMES) => `.${step}`;

export const useQuickStartTour = () => {
  const { setCurrentStep, currentStep, setIsOpen, isOpen } = useTour();

  const [isFinished, setIsFinished] = React.useState<boolean>(false);

  const isAlpha = currentStep <= QUICK_START_TOUR_STEP_COUNT.SaveSymbol;
  const isBeta =
    currentStep >= QUICK_START_TOUR_STEP_COUNT.SaveSymbol && currentStep < QUICK_START_TOUR_STEP_COUNT.AddSymbolGamma;

  const handleStart = React.useCallback(() => {
    setIsOpen(true);
    setCurrentStep(QUICK_START_TOUR_STEP_COUNT.CreateNewWorkspace);
  }, [setIsOpen, setCurrentStep]);

  const handleFinish = React.useCallback(() => {
    setIsFinished(true);
    setTimeout(() => {
      setIsFinished(false);
    }, CONFETTI_DURATION);
  }, []);

  const QUICK_START_TOUR_STEPS: StepType[] = [
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.CreateNewWorkspace),
      content:
        'Start by creating your first workspace to begin organising your projects. Press this button to get started!',
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.OpenWorkspace),
      content: 'Click on the newly created workspace to open it.',
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.CreateSheet),
      content: "Click on 'Create Sheet' to add a new sheet to your workspace.",
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.OpenSheet),
      content: 'Press this button to open your first sheet.',
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.AddSymbol),
      content:
        "Click on 'Add Symbol' to insert a new symbol into your calculations. This will allow you to define and use variables within your sheet!",
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.OpenSymbolMenu),
      content: 'Click on the symbol to customise and manage it!',
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.ModifySymbol),
      // padding: {
      //   mask: [15, 5, 55, 55],
      // },
      content: "Select 'Modify Symbol' to edit your symbol.",
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.WriteSymbolName),
      content: 'Create your first variable name by typing ==> Delta beta (t)',
      padding: {
        mask: [20, -10, -10, -10],
      },
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.AddValueForSymbol),
      content: 'Delete 0 and enter 12 for this variable',
      padding: {
        mask: 5,
      },
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.SaveSymbol),
      content: "Click 'Accept' to save the changes.",
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.AddSymbolBetta),
      content: "Click 'Add Symbol' to create your second symbol.",
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.OpenSymbolMenuBetta),
      content: 'Click on the symbol to customise and manage it!',
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.ModifySymbolBetta),
      // padding: {
      //   mask: [15, 5, 55, 55],
      // },
      content: "Select 'Modify Symbol' from the menu to edit the details of your symbol.",
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.WriteSymbolNameBetta),
      content: 'Create second variable name by entering ==> xi_{ph Theta}',
      padding: {
        mask: [20, -10, -10, -10],
      },
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.AddValueForSymbolBetta),
      content:
        'Delete 0, and using only the left/right and top/down arrows and return key (no mouse), write the following ==> Delta beta(t)+sin(pi)+100. (We also recommend reviewing the tips to the left from Symbol Name/Formula Editor/Units.)',
      padding: {
        mask: 5,
      },
      position: [0, 0],
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.SaveSymbolBetta),
      content: "Click 'Accept' to confirm your first symbol with a formula.",
      actionAfter: () => {
        handleFinish();
      },
    },
    {
      content:
        'Now, you can print the sheet below to PDF. We recommend going to the Starting Page in the top left corner to find messages from our founders. Also, go back to the dashboard to create an Example Workspace!',
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.Print),
      styles: {
        popover: (base: any) => ({
          ...base,
          borderRadius: 10,
          top: 50,
          left: 50,
        }),
      },
    },
  ];

  return {
    setCurrentStep,
    setIsOpen,
    isOpen,
    isAlpha,
    isBeta,
    currentStep,
    isFinished,
    handleStart,
    handleFinish,
    QUICK_START_TOUR_STEPS,
  };
};

import { TValueFunctionId } from '../../enjc-value-math/model/TValueFunctionId';
import { EnjcValueFunctionSpecRefFragment } from '../../../generated/graphql/operations';

export const mkValueFunctionRef = (
  functionId: TValueFunctionId | undefined,
): EnjcValueFunctionSpecRefFragment | undefined => {
  return functionId === undefined
    ? functionId
    : {
        __typename: 'EnjcValueFunctionSpec',
        id: functionId,
      };
};

import { TSymbolId } from '../types';
import { EnjcWorkspaceSymbolFragment } from '../../../libenjc/enjicalc-graphql';
import { EnjcWorkspaceDTO } from '../model';

export const getEnjcSymbolOrUndefined = (
  enjicalcWorkspace: EnjcWorkspaceDTO,
  symbolId: TSymbolId,
): EnjcWorkspaceSymbolFragment | undefined => {
  return enjicalcWorkspace.symbols.find((item) => item.id === symbolId);
};

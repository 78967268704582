import { EnjcAuxMetaInput } from '../../../generated/graphql/types';
import { EnjcAuxMetaEntryDelta, stringDeltaToInput } from '../../enjc-delta';

export const convertAuxMetaEntryDeltaToDiffInput = (
  auxMetaDelta: EnjcAuxMetaEntryDelta,
  toUndo: boolean,
): EnjcAuxMetaInput => {
  return {
    // TODO: check when this should be undefined
    name: auxMetaDelta.name,
    valueStr: auxMetaDelta.value.length > 0 ? auxMetaDelta.value.map((d) => stringDeltaToInput(d, false)) : undefined,
  };
};

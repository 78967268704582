import { EnjcWorkspaceItemVisibility, EnjicalcWorkspace, EnjcWorkspaceWithMath } from '../../../enjc-workspace';
import { enjcEmptyVersionMeta } from './enjcEmptyVersionMeta';
import { enjcEmptyAuxMeta } from './enjcEmptyAuxMeta';

export const enjcWorkspaceItemBase = {
  id: '',
  created: 0,
  version: enjcEmptyVersionMeta,
  visibility: EnjcWorkspaceItemVisibility.Visible,
  auxMeta: enjcEmptyAuxMeta,
};

export const enjcEmptyWorkspace: EnjicalcWorkspace = {
  __typename: 'EnjcWorkspace',
  ...enjcWorkspaceItemBase,
  itemsVersion: enjcEmptyVersionMeta,
  sheetBookmarks: [],
  owner: {
    __typename: 'EnjcUser',
    id: '',
  },
  // functions: [],
  sections: [],
  symbols: [],
  sheets: [],
  calcCache: new Map(),
};

export const enjcEmptyWorkspaceWithMath: EnjcWorkspaceWithMath = {
  __typename: 'EnjcWorkspace',
  ...enjcWorkspaceItemBase,
  itemsVersion: enjcEmptyVersionMeta,
  sheetBookmarks: [],
  owner: {
    __typename: 'EnjcUser',
    id: '',
  },
  functions: [],
  sections: [],
  symbols: [],
};

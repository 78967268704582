import React, { ReactElement } from 'react';
import { isLiteralVoid, UEnjcValueLiteral, valueLiteralToString } from '../../../libenjc/enjc-literal';

interface IProps {
  readonly literal: UEnjcValueLiteral;
}

const SymbolResultViewF = ({ literal }: IProps): ReactElement => {
  const formattedValue = valueLiteralToString(literal);

  return (
    <div className="value">
      {!isLiteralVoid(literal) ? (
        formattedValue
      ) : (
        <span className="rounded bg-orange-500 px-1.5 py-0 text-black opacity-70">NaN</span>
      )}
    </div>
  );
};

export const SymbolResultView = React.memo(SymbolResultViewF);
